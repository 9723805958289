import styled from "styled-components";
import { ClearButton, SubmitButton } from "./styles";

const Container = styled.div`
  display: block;

  @media only screen and (min-width: 950px) {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
`;

const Header = styled.h1`
  font-size: 72px;
  line-height: 115%;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const StagedChangesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
  row-gap: 12px;

  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

const StagedChangesCount = styled.div`
  font-size: 22px;
  line-height: 115%;
  margin-right: 18px;
`;

const StakeAllButton = styled(SubmitButton).attrs(({ width }) => ({
  width: width || "180px",
}))`
  margin-right: 12px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const Heading = ({
  claimInProgress,
  handleClear,
  handleStakeAllClick,
  handleSumbitButtonClick,
  handleUnstakeAllClick,
  kidsDepositInProgress,
  kidsDeposits,
  kidsStagedForDeposit,
  kidsStagedForWithdrawal,
  kidsUnstaked,
  kidsWithdrawalInProgress,
  pupsDepositInProgress,
  pupsDeposits,
  pupsStagedForDeposit,
  pupsStagedForWithdrawal,
  pupsUnstaked,
  pupsWithdrawalInProgress,
  willApproveKidsForAll,
  willApprovePupsForAll,
  willUnapproveKidsForAll,
  willUnapprovePupsForAll,
}) => {
  const stagedChangesCount = [
    ...kidsStagedForDeposit,
    ...kidsStagedForWithdrawal,
    ...pupsStagedForDeposit,
    ...pupsStagedForWithdrawal,
  ].length;

  const showTransactionInProgress =
    [
      ...kidsDepositInProgress,
      ...kidsWithdrawalInProgress,
      ...pupsDepositInProgress,
      ...pupsWithdrawalInProgress,
    ].length > 0 ||
    claimInProgress ||
    willApproveKidsForAll ||
    willApprovePupsForAll ||
    willUnapproveKidsForAll ||
    willUnapprovePupsForAll;

  const showStakeAllButton =
    stagedChangesCount === 0 &&
    !showTransactionInProgress &&
    [...kidsUnstaked, ...pupsUnstaked].length > 0;

  const showUnstakeAllButton =
    stagedChangesCount === 0 &&
    !showTransactionInProgress &&
    [...kidsDeposits, ...pupsDeposits].length > 0;

  return (
    <Container>
      <Header>Staking</Header>
      <div style={{ flexGrow: 1 }} />
      {!showTransactionInProgress && stagedChangesCount > 0 && (
        <StagedChangesContainer>
          <StagedChangesCount>
            {stagedChangesCount} change
            {stagedChangesCount > 1 ? "s" : ""} ready to go!
          </StagedChangesCount>
          <ClearButton onClick={handleClear}>Clear</ClearButton>
          <SubmitButton id="submit-button" onClick={handleSumbitButtonClick}>
            Submit
          </SubmitButton>
        </StagedChangesContainer>
      )}
      {showTransactionInProgress && (
        <StagedChangesContainer>
          <StagedChangesCount>
            Transaction in progress! Check your wallet
          </StagedChangesCount>
          <img src="/loading.gif" alt="loading" width="24px" />
        </StagedChangesContainer>
      )}
      <div style={{ display: "flex", gap: "12px" }}>
        {showUnstakeAllButton && (
          <StagedChangesContainer>
            <StakeAllButton
              id="unstake-all-button"
              onClick={handleUnstakeAllClick}
            >
              Bring All Home
            </StakeAllButton>
          </StagedChangesContainer>
        )}
        {showStakeAllButton && (
          <StagedChangesContainer>
            <StakeAllButton
              id="stake-all-button"
              onClick={handleStakeAllClick}
              width="240px"
            >
              Send All to Camp
            </StakeAllButton>
          </StagedChangesContainer>
        )}
      </div>
    </Container>
  );
};

export default Heading;
