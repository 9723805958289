import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ClearButton, SubmitButton } from "./styles";

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
    width: 600px;
    left: calc(50% - 300px);
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 26px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;

  div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InnerButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: ${({ fullWidthButton }) =>
      fullWidthButton ? "100%" : `calc(50% - 12px)`};
  }
`;

const Checkbox = styled.div`
  cursor: pointer;
  margin-right: 10px;
  background: #fff;
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 40px;
  padding: 3px;

  div {
    font-size: 34px;
    margin: -14.5px 0 0;
    color: #e04778;
  }
`;

const CheckboxLabel = styled.label`
  color: #666;
`;

const ApproveAllModal = ({
  closeModal,
  kidsApprovedForAll,
  pupsApprovedForAll,
  setWillApproveKidsForAll,
  setWillApprovePupsForAll,
  persistHideApproveAllModal,
  setShowPromptAvatarModal,
  setWontApproveAll,
  willStakeAll,
  setWillStakeAll,
}) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleClose = useCallback(() => {
    closeModal();
    if (dontShowAgain && !willStakeAll) {
      persistHideApproveAllModal();
      setShowPromptAvatarModal(true);
    }
    if (willStakeAll) {
      setWillStakeAll(false);
    }
  }, [
    closeModal,
    dontShowAgain,
    persistHideApproveAllModal,
    setShowPromptAvatarModal,
    willStakeAll,
    setWillStakeAll,
  ]);

  const handleNext = useCallback(() => {
    closeModal();
    if (dontShowAgain && !willStakeAll) {
      persistHideApproveAllModal();
      setShowPromptAvatarModal(true);
    }
    if (willStakeAll) {
      setWontApproveAll(true);
    }
  }, [
    closeModal,
    dontShowAgain,
    persistHideApproveAllModal,
    setShowPromptAvatarModal,
    setWontApproveAll,
    willStakeAll,
  ]);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const shouldClose = ![
        "approve-modal",
        "approve-modal-container",
        "approve-modal-heading",
        "approve-modal-inner-container",
        "approve-modal-btns-container",
        "approve-modal-div-one",
        "approve-modal-div-two",
        "approve-modal-checkbox",
        "approve-modal-label",
        "approve-inner-btns-container",
        "approve-modal-checkbox-container",
        "approve-modal-div-three",
        "approve-modal-checkbox-content",
        "stake-all-button",
        "stake-all-kids-and-pups-button",
      ].includes(event.target.id);
      if (shouldClose) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleApproveKidsForAllClick = useCallback(() => {
    handleNext();
    setWillApproveKidsForAll(true);
  }, [handleNext, setWillApproveKidsForAll]);

  const handleApprovePupsForAllClick = useCallback(() => {
    handleNext();
    setWillApprovePupsForAll(true);
  }, [handleNext, setWillApprovePupsForAll]);

  const handleApproveBothForAllClick = useCallback(() => {
    handleNext();
    setWillApproveKidsForAll(true);
    setWillApprovePupsForAll(true);
  }, [handleNext, setWillApproveKidsForAll, setWillApprovePupsForAll]);

  if (kidsApprovedForAll && pupsApprovedForAll) {
    return null;
  }

  return (
    <OuterContainer id="approve-modal">
      <Container id="approve-modal-container">
        <CloseButtonContainer
          id="approve-modal-close-container"
          onClick={handleClose}
        >
          <CloseButton id="approve-modal-close">×</CloseButton>
        </CloseButtonContainer>
        <Heading id="approve-modal-heading">
          Approve All {!kidsApprovedForAll && "Kids"}
          {!kidsApprovedForAll && !pupsApprovedForAll && " & "}
          {!pupsApprovedForAll && "Puppies"}?
        </Heading>
        <InnerContainer id="approve-modal-inner-container">
          <div id="approve-modal-div-one">
            You can save time and gas by approving all{" "}
            {!kidsApprovedForAll && "Kids"}
            {!kidsApprovedForAll && !pupsApprovedForAll && " or "}
            {!pupsApprovedForAll && "Puppies"} for deposit to the staking
            contract.
          </div>
          <div id="approve-modal-div-two">
            On the other hand, you might prefer to approve each NFT individually
            to minimize your exposure to risk.
          </div>
          <InnerButtonsContainer
            id="approve-inner-btns-container"
            fullWidthButton={kidsApprovedForAll || pupsApprovedForAll}
          >
            {!kidsApprovedForAll && (
              <SubmitButton onClick={handleApproveKidsForAllClick}>
                Approve All Kids
              </SubmitButton>
            )}
            {!pupsApprovedForAll && (
              <SubmitButton onClick={handleApprovePupsForAllClick}>
                Approve All Puppies
              </SubmitButton>
            )}
          </InnerButtonsContainer>
          {!kidsApprovedForAll && !pupsApprovedForAll && (
            <SubmitButton width="100%" onClick={handleApproveBothForAllClick}>
              Approve All Kids & Puppies
            </SubmitButton>
          )}
        </InnerContainer>
        <ButtonsContainer id="approve-modal-btns-container">
          {!willStakeAll ? (
            <div
              style={{
                display: "flex",
              }}
              id="approve-modal-div-three"
            >
              <Checkbox
                onClick={() => setDontShowAgain(!dontShowAgain)}
                id="approve-modal-checkbox"
              >
                {dontShowAgain && (
                  <div id="approve-modal-checkbox-content">•</div>
                )}
              </Checkbox>

              <CheckboxLabel id="approve-modal-label">
                Don't show this message again
              </CheckboxLabel>
            </div>
          ) : (
            <div />
          )}
          <ClearButton id="approve-modal-no-thanks" onClick={handleNext}>
            No Thanks
          </ClearButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default ApproveAllModal;
