import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { DISCORD_URL, MARKETPLACE_TYPE_CONFIG } from "./config";
import CartCounter from "./CartCounter";
import PriceTag from "./PriceTag";
import {
  getIsAtGumLimit,
  getIsAtPerAddressLimit,
  getIsAtTotalRemainingLimit,
} from "./utils";
import moment from "moment";

const Container = styled.div`
  line-height: 100%;
  font-size: 18px;

  @media only screen and (min-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  gap: 14px;
  margin-bottom: 22px;
  padding: 6px;
  border: solid 2px transparent;
  border-radius: 6px;
  align-items: center;

  &:hover {
    ${({ handleCartUpdate }) =>
      handleCartUpdate &&
      `
    border-color: #e04778;
    cursor: pointer;
  `}
  }
`;

const Image = styled.img`
  flex-shrink: 1;
  font-size: 8px;
  border-radius: 3px;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
  flex-wrap: wrap;
`;

const ItemName = styled.div`
  color: #e04778;
  font-size: 20px;
`;

const ItemType = styled.div`
  color: #999;
  font-size: 13px;
  text-transform: uppercase;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ItemSubtotal = styled.div``;

const Remove = styled.div`
  align-items: center;
  justify-content: end;
  flex-shrink: 1;
  padding-right: 8px;
`;

const XButton = styled.div`
  color: #999;
  padding: 2px;

  &:hover {
    color: #666;
  }
`;

const ItemCount = styled.div`
  color: #999;
  font-size: 13px;
  text-transform: uppercase;
`;

const Warning = styled.a`
  font-size: 11px;
  color: #fff;
  background-color: #e04778;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  text-decoration: none;
`;

const Size = styled.div`
  font-size: 11px;
  background-color: #fff;
  border: 2px solid #e04778;
  color: #e04778;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
`;

const Cart = ({
  accountPreviousOrderCounts,
  cart,
  gumBalance,
  gumCartTotal,
  handleCartUpdate,
  handleCartItemClick,
  items,
  previousOrder,
  previousOrderCounts,
}) => {
  const detailedCart = useMemo(() => {
    return previousOrder?.length > 0
      ? previousOrder.map((order) => {
          const { item = {}, count, size } = order;
          const { id } = item;
          const detailedItem = items.find(({ _id }) => id === _id) || {};
          const { imageUrl = "" } = detailedItem;
          return {
            item: { ...item, imageUrl },
            count,
            size,
          };
        })
      : cart.map(({ id, count, size }) => {
          const detailedItem = items.find(({ _id }) => id === _id) || {};
          return { item: detailedItem, count, size };
        });
  }, [cart, previousOrder, items]);

  const handleRemoveButtonClick = useCallback(
    (e, id) => {
      e.stopPropagation();
      handleCartUpdate({ id, count: 0, size: null });
    },
    [handleCartUpdate]
  );

  const isPreviousOrder = (previousOrder?.length ?? 0) > 0;

  const now = useMemo(() => moment(), []);

  return (
    <Container>
      {detailedCart.map(({ item, count, size }, index) => {
        const {
          _id,
          _type,
          confirmed,
          name,
          gumPrice,
          imageUrl = undefined,
          limitPerAddress,
          totalQuantity,
          expirationDate: _expirationDate,
          sizes = [],
        } = item || {};
        const expirationDate = moment(_expirationDate);
        const isExpired = now ? now.isAfter(expirationDate) : false;
        if (isExpired && !isPreviousOrder) return null;

        const type = MARKETPLACE_TYPE_CONFIG[_type];
        const isAtGumLimit = getIsAtGumLimit({
          count,
          gumBalance,
          gumPrice,
          gumCartTotal,
        });
        const isAtPerAddressLimit = getIsAtPerAddressLimit({
          accountPreviousOrderCounts,
          count,
          id: _id,
          limitPerAddress,
        });
        const isAtTotalRemainingLimit = getIsAtTotalRemainingLimit({
          count,
          id: _id,
          previousOrderCounts,
          totalQuantity,
        });

        return (
          <ItemContainer
            onClick={() => handleCartItemClick(_id)}
            handleCartUpdate={handleCartUpdate}
            id="cart-modal-item"
            key={`${_id}-${index}`}
          >
            <ImageContainer>
              <Image src={imageUrl} alt={name} width="80px" />
            </ImageContainer>
            <ItemInfo>
              <Row>
                <ItemName>{name}</ItemName>
                {type && <ItemType>{type}</ItemType>}
              </Row>
              <Row>
                <PriceTag price={gumPrice} />
                {handleCartUpdate ? (
                  <CartCounter
                    count={count}
                    isAtGumLimit={isAtGumLimit}
                    isAtPerAddressLimit={isAtPerAddressLimit}
                    isAtTotalRemainingLimit={isAtTotalRemainingLimit}
                    setCount={(count) =>
                      handleCartUpdate({ id: _id, count, size })
                    }
                    small={true}
                  />
                ) : (
                  <ItemCount>
                    {isPreviousOrder
                      ? `Quantity: ${count}`
                      : `${count} in Cart`}
                  </ItemCount>
                )}
                <ItemSubtotal>{count * gumPrice} $GUM</ItemSubtotal>
              </Row>
            </ItemInfo>
            <>
              <div style={{ flexGrow: 1 }} />
              {sizes.length === 0 ? null : size ? (
                <Size>{size}</Size>
              ) : (
                <Warning>SELECT SIZE</Warning>
              )}
            </>
            <div style={{ flexGrow: 1 }} />
            {handleCartUpdate && (
              <Remove>
                <XButton onClick={(e) => handleRemoveButtonClick(e, _id)}>
                  ×
                </XButton>
              </Remove>
            )}
            {confirmed === false && (
              <Warning
                href={DISCORD_URL}
                target="_blank"
                rel="noreferrer noopener"
              >
                UNCONFIRMED - CONTACT TEAM
              </Warning>
            )}
          </ItemContainer>
        );
      })}
    </Container>
  );
};

export default Cart;
