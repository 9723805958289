import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, ClearButton, SubmitButton } from "./styles";
import { MARKETPLACE_TYPE_CONFIG } from "./config";
import CartCounter from "./CartCounter";
import PriceTag from "./PriceTag";
import {
  checkForParentElementById,
  getIsAtGumLimit,
  getIsAtPerAddressLimit,
  getIsAtTotalRemainingLimit,
  getIsOverGumLimit,
  getQuantityRemaining,
} from "./utils";
import PreviousOrders from "./PreviousOrders";

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;
  display: flex;
  gap: 18px;
  flex-direction: column;
`;

const ExtremelyInnerContainer = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;

  @media only screen and (min-width: 975px) {
    flex-direction: row;
  }
`;

const SubmitWarning = styled.div`
  color: #e04778;
  width: 100%;
  text-align: center;
  margin-top: 8px;
`;

const ItemType = styled.div`
  text-transform: uppercase;
  color: #666;
  font-size: 14px;
`;

const PrizeName = styled.div``;

const ImageContainer = styled.div`
  width: 100%;
  text-align: center;

  img {
    border-radius: 4px;
    width: 240px;
  }

  @media only screen and (min-width: 975px) {
    &,
    img {
      width: 240px;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ItemDescription = styled.div`
  color: #666;
  font-size: 16px;
  margin-bottom: 12px;
`;

const ItemExpiration = styled.div``;

const ItemName = styled.div`
  color: #e04778;
  font-size: 20px;
  margin: 0 0 4px;
`;

const ItemLimitPerAddress = styled.div``;

const ItemQuantityRemaining = styled.div`
  ${({ quantityRemaining }) => quantityRemaining < 10 && "color: #e04778;"}
`;

const SizeContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  height: 36px;
  margin-top: 4px;
`;

const SizeButton = styled(Button)`
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  display: flex;
  padding: 2px 8px;
  width: max-content;
  font-size: 14px;
  border-width: 2px;
  height: 36px;

  ${({ selected }) =>
    selected &&
    `
    &, &:hover {
      background-color: #e04778;
      color: #fff;
    }
  `}
`;

const Sizes = styled.div`
  display: flex;
  gap: 3px;
  flex-wrap: nowrap;
  overflow-x: scroll;
`;

const PreviousOrdersHed = styled.h2`
  color: #777;
  margin: 0;
  text-align: center;
`;

const getSubmitButtonText = (preexistingCount, count) => {
  if (preexistingCount > 0) {
    if (count === 0) {
      return "Remove from Cart";
    }
    return `Update Cart (x${count})`;
  }
  return `Add to Cart (x${count})`;
};

const formatDescription = (description) => {
  const splitDescription = (description || "").split("\n");
  return (
    <div>
      {splitDescription.map((line, index) => (
        <div key={index} style={{ marginBottom: "4px" }}>
          {line}
        </div>
      ))}
    </div>
  );
};

const MarketplaceDetailModal = ({
  accountPreviousOrderCounts,
  cart,
  cartModalInBackground,
  closeModal,
  gumBalance,
  gumCartTotal,
  handleBackToCartClick,
  handleCartUpdate,
  id,
  items,
  previousOrderCounts,
  previousOrders,
}) => {
  const item = useMemo(() => items.find(({ _id }) => id === _id), [id, items]);
  const {
    _type,
    description,
    expirationDate,
    gumPrice,
    imageUrl: _imageUrl,
    limitPerAddress,
    name,
    totalQuantity,
    sizes = [],
  } = item;

  const previousItemOrders = previousOrders.filter(
    ({ item_id }) => id === item_id
  );

  const preexistingItem = useMemo(() => {
    const { count: _count, size: _size } =
      cart.find(({ id: _id }) => id === _id) || {};
    return { count: _count ?? 0, size: _size ?? null };
  }, [cart, id]);

  const preexistingCount = useMemo(
    () => preexistingItem.count,
    [preexistingItem.count]
  );

  const preexistingSize = useMemo(
    () => preexistingItem.size,
    [preexistingItem.size]
  );

  const [count, setCount] = useState(preexistingCount);
  const [size, setSize] = useState(preexistingSize);
  const [sizesOpen, setSizesOpen] = useState(false);

  const isAtPerAddressLimit = useMemo(
    () =>
      getIsAtPerAddressLimit({
        accountPreviousOrderCounts,
        count,
        id,
        limitPerAddress,
      }),
    [accountPreviousOrderCounts, count, id, limitPerAddress]
  );

  const quantityRemaining = useMemo(
    () => getQuantityRemaining({ id, previousOrderCounts, totalQuantity }),
    [id, previousOrderCounts, totalQuantity]
  );

  const isAtTotalRemainingLimit = useMemo(
    () =>
      getIsAtTotalRemainingLimit({
        count,
        id,
        previousOrderCounts,
        totalQuantity,
      }),
    [count, id, previousOrderCounts, totalQuantity]
  );

  const type = MARKETPLACE_TYPE_CONFIG[_type];
  const imageUrl =
    _imageUrl ||
    (_type === "offChainRaffle" && item.prize?.imageUrl) ||
    undefined;

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const targetIsModalChild = checkForParentElementById(
        event.target,
        "marketplace-detail-modal"
      );
      const targetIsMarketplaceItemChild = checkForParentElementById(
        event.target,
        "marketplace-item"
      );
      const targetIsCartModalItem = checkForParentElementById(
        event.target,
        "cart-modal-item"
      );
      const targetIsSizePickerChild = checkForParentElementById(
        event.target,
        "size-picker"
      );
      const shouldClose = !(
        targetIsModalChild ||
        targetIsMarketplaceItemChild ||
        targetIsCartModalItem ||
        targetIsSizePickerChild
      );
      if (shouldClose) {
        closeModal();
      }
    },
    [closeModal]
  );

  const isAtGumLimit = useMemo(
    () =>
      getIsAtGumLimit({
        count,
        gumBalance,
        gumPrice,
        gumCartTotal,
        preexistingCount,
      }),
    [count, gumBalance, gumPrice, gumCartTotal, preexistingCount]
  );

  const isOverGumLimit = useMemo(
    () =>
      getIsOverGumLimit({
        count,
        gumBalance,
        gumCartTotal,
        gumPrice,
        preexistingCount,
      }),
    [count, gumBalance, gumCartTotal, gumPrice, preexistingCount]
  );

  const sizeUnselected = useMemo(() => {
    if (sizes.length > 1 && !size) return true;
    return false;
  }, [size, sizes]);

  const handleSubmit = useCallback(() => {
    handleCartUpdate({ id, count, size });
    if (cartModalInBackground) {
      handleBackToCartClick();
    } else {
      closeModal();
    }
  }, [
    cartModalInBackground,
    closeModal,
    count,
    handleBackToCartClick,
    handleCartUpdate,
    id,
    size,
  ]);

  const toggleSizeDropdown = useCallback(() => {
    setSizesOpen(!sizesOpen);
  }, [sizesOpen, setSizesOpen]);

  const handleSizeClick = useCallback(
    (_size) => {
      setSize(_size);
      setSizesOpen(false);
    },
    [setSize]
  );

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <OuterContainer id="marketplace-detail-modal">
      <Container>
        <CloseButtonContainer onClick={closeModal}>
          <CloseButton>×</CloseButton>
        </CloseButtonContainer>
        <Heading>{name}</Heading>
        <InnerContainer>
          <ExtremelyInnerContainer>
            <ImageContainer>
              <img src={imageUrl} alt={name} />
            </ImageContainer>
            <InfoContainer>
              <ItemType>{type}</ItemType>
              <ItemName>{name}</ItemName>
              <ItemDescription>
                {formatDescription(description)}
              </ItemDescription>
              <div style={{ marginBottom: "6px" }}>
                <PriceTag price={gumPrice} />
              </div>
              {_type === "offChainRaffle" && (
                <>
                  <PrizeName>Prize: {item.prize?.name}</PrizeName>
                </>
              )}
              {limitPerAddress && (
                <ItemLimitPerAddress>
                  Limit per address: {limitPerAddress}
                </ItemLimitPerAddress>
              )}
              {expirationDate && (
                <ItemExpiration>
                  Available until:{" "}
                  {new Date(expirationDate).toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hourCycle: "h24",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </ItemExpiration>
              )}
              {(quantityRemaining || quantityRemaining === 0) && (
                <ItemQuantityRemaining quantityRemaining={quantityRemaining}>
                  Total supply remaining: {quantityRemaining}
                </ItemQuantityRemaining>
              )}
              {sizes.length > 0 && (
                <SizeContainer>
                  <SizeButton onClick={toggleSizeDropdown}>
                    <span>{size || "Select Size"}</span>
                  </SizeButton>
                  {sizesOpen && (
                    <Sizes id="size-picker">
                      {sizes.map((_size) => (
                        <SizeButton
                          selected={size === _size}
                          onClick={() => handleSizeClick(_size)}
                          key={_size}
                        >
                          {_size}
                        </SizeButton>
                      ))}
                    </Sizes>
                  )}
                </SizeContainer>
              )}
            </InfoContainer>
          </ExtremelyInnerContainer>
          {previousItemOrders.length > 0 && (
            <>
              <PreviousOrdersHed>Previous Orders</PreviousOrdersHed>
              <PreviousOrders
                detailedItems={items}
                previousOrders={previousItemOrders}
              />
            </>
          )}
        </InnerContainer>
        {isAtGumLimit ? (
          <SubmitWarning>
            You don't have enough GUM to add this item :(
          </SubmitWarning>
        ) : isAtPerAddressLimit ? (
          <SubmitWarning>
            You've reached the per-person limit for this item
          </SubmitWarning>
        ) : isAtTotalRemainingLimit ? (
          <SubmitWarning>
            You've reached the total supply limit for this item
          </SubmitWarning>
        ) : preexistingCount < 1 && count < 1 ? (
          <SubmitWarning>
            You need to add at least one item to update your cart
          </SubmitWarning>
        ) : sizeUnselected ? (
          <SubmitWarning>
            You need to select a size before updating your cart
          </SubmitWarning>
        ) : null}
        <ButtonsContainer>
          <CartCounter
            count={count}
            isAtGumLimit={isAtGumLimit}
            isAtPerAddressLimit={isAtPerAddressLimit}
            isAtTotalRemainingLimit={isAtTotalRemainingLimit}
            setCount={setCount}
            showRemoveButton={true}
          />
          <div id="detailed-item-modal-button">
            <ClearButton
              onClick={
                cartModalInBackground ? handleBackToCartClick : closeModal
              }
              width="140px"
            >
              {cartModalInBackground ? "Back" : "Cancel"}
            </ClearButton>
            <SubmitButton
              onClick={handleSubmit}
              disabled={
                (preexistingCount < 1 && count < 1) ||
                isOverGumLimit ||
                sizeUnselected
              }
              width="210px"
            >
              {getSubmitButtonText(preexistingCount, count)}
            </SubmitButton>
          </div>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default MarketplaceDetailModal;
