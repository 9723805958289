import styled from "styled-components";
import { CONTRACT_ADDRESSES } from "./config";

const { pupsContractAddress } = CONTRACT_ADDRESSES;

const Container = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: auto auto;
  margin-bottom: 24px;
  position: relative;
  width: 100%;
`;

const Count = styled.div`
  font-size: 18px;
  text-align: right;
`;

const Counter = styled.div`
  background-color: #fff;
  border-radius: 30px;
  height: 30px;
  margin-bottom: 12px;
  width: 100%;
`;

const CounterBorder = styled.div`
  background-color: transparent;
  border: solid 3px #e04778;
  border-radius: 30px;
  height: 100%;
  margin-top: -3px;
  position: relative;
  width: calc(100% - 3px);
  z-index: 2;
`;

const Logo = styled.img`
  height: 36px;
`;

const ProgressBar = styled.div`
  background-color: #f2a7bf;
  border-radius: 30px;
  height: calc(100% - 3px);
  margin-left: -3px;
  margin-top: 3px;
  width: calc(100% - 3px);
`;

const ProgressContainer = styled.div`
  border: solid 3px #f2a7bf;
  border-radius: 30px;
  height: 100%;
  margin-top: -36px;
  overflow-x: hidden;
  position: relative;
  z-index: 1;

  ${({ percentage }) => `width: ${percentage}%;`}
`;

const StakingCounter = ({ collectionAddress, numberStaked }) => {
  const logoSrc =
    collectionAddress === pupsContractAddress
      ? "/bgp-logo.png"
      : "/bgk-logo.png";
  const logoAlt =
    collectionAddress === pupsContractAddress
      ? "bubblegum puppies logo"
      : "bubblegum kids logo";

  return (
    <Container>
      <Logo src={logoSrc} alt={logoAlt} />
      <Counter>
        <CounterBorder />
        {numberStaked > 0 && (
          <ProgressContainer percentage={(numberStaked / 10_000) * 100}>
            <ProgressBar />
          </ProgressContainer>
        )}
      </Counter>
      <div />
      <Count>{numberStaked} / 10,000</Count>
    </Container>
  );
};

export default StakingCounter;
