import styled from "styled-components";
import { Button } from "./styles";
import { useCallback, useEffect, useState } from "react";

const Toggle = styled.div`
  align-items: center;
  background-color: #f2a7bf;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: ${({ collection }) =>
    collection === "bgk" ? "start" : "end"};
  margin: 0 auto;
  width: 42px;

  div {
    background-color: #e04778;
    border-radius: 100%;
    height: 22px;
    width: 22px;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  height: 54px;
  justify-content: space-between;
  margin-bottom: 18px;
  text-align: center;
  width: 100%;
`;

const Container = styled.div`
  align-items: center;
  display: flex;

  ${({ position }) => position && `position: ${position};`}
  ${({ hideMobile }) =>
    hideMobile &&
    `
  display: none;

  @media only screen and (min-width: 975px) {
    display: inherit;
  }
  `}
`;

const ToggleContainer = styled(Container)`
  width: 280px;
  margin: ${({ center }) => (center ? "0 auto" : "0")};

  @media only screen and (min-width: 975px) {
    margin: 0;
  }
`;

const Caret = styled.div`
  font-size: 14px;
  margin-top: 1px;
  ${({ filterDropdownOpen }) =>
    !filterDropdownOpen && "transform: rotate(180deg);"}
`;

const CarouselButton = styled(Button)`
  border-radius: 12px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: 59px;
  padding-left: 12px;

  ${({ selected }) =>
    selected &&
    `
    & div, & div div {
      border-color: #fff;
    }
    &, &:hover {
      background-color: #e04778;
    }
  `}
`;

const FilterDropdown = styled.div`
  position: absolute;
  width: 180px;
  top: 50px;
  text-align: center;
`;

const FilterDropdownButton = styled(Button)`
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  display: flex;
  padding: 0 8px 0 12px;
  width: 180px;
`;

const GridButton = styled(Button)`
  border-left: none;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 59px;
  padding-left: 12px;

  ${({ selected }) =>
    selected &&
    `
    & div, & div div {
      border-color: #fff;
    }
    &, &:hover {
      background-color: #e04778;
    }
  `}
`;

const GridImg = styled.div`
  border: solid 1.5px #e04778;
  height: 26px;
  width: 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  div {
    border: solid 1.5px #e04778;
  }
`;

const CarouselImg = styled.div`
  border: solid 1.5px #e04778;
  height: 26px;
  width: 26px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  div {
    border: solid 1.5px #e04778;
  }
`;

const FilterDropdownPlaceholder = styled.div`
  width: 0;
  display: none;

  @media only screen and (min-width: 975px) {
    width: 180px;
    display: inherit;
  }
`;

const Option = styled.div`
  font-size: 26px;
  height: 32px;

  text-align: center;
  color: inherit;
  text-decoration: none;
  padding: 4px 0;
  cursor: pointer;
  background-color: #eee;
  border-bottom: solid 1px #fff;

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: none;
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:hover {
    background-color: #f2a7bf;
    color: #fff;

    div {
      border-color: #fff;
    }
  }

  ${({ selected }) =>
    selected &&
    `
  &, &:hover {
    background-color: #e04778;
    color: #fff;
  }
`}
`;

const SettingsBar = ({
  collection,
  filter,
  kidsDeposits,
  kidsUnstaked,
  pupsDeposits,
  pupsUnstaked,
  setCollection,
  setFilter,
  setViewType,
  toggleCollection,
  viewType,
}) => {
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const shouldClose = ![
        "dropdown-button",
        "dropdown-button-text",
        "dropdown-button-caret",
        "dropdown",
        "dropdown-option-all",
        "dropdown-option-camp",
        "dropdown-option-home",
      ].includes(event.target.id);
      if (shouldClose) {
        setFilterDropdownOpen(false);
      }
    },
    [setFilterDropdownOpen]
  );

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const setCarousel = useCallback(() => {
    setViewType("carousel");
  }, [setViewType]);

  const setGrid = useCallback(() => {
    setViewType("grid");
  }, [setViewType]);

  const toggleFilterDropdown = useCallback(() => {
    setFilterDropdownOpen(!filterDropdownOpen);
  }, [filterDropdownOpen, setFilterDropdownOpen]);

  const closeFilterDropdown = useCallback(() => {
    setFilterDropdownOpen(false);
  }, [setFilterDropdownOpen]);

  const handleAllClick = useCallback(() => {
    closeFilterDropdown();
    if (filter === "All") return;
    setFilter("All");
  }, [closeFilterDropdown, filter, setFilter]);

  const handleAtCampClick = useCallback(() => {
    closeFilterDropdown();
    if (filter === "At Camp") return;
    setFilter("At Camp");
  }, [closeFilterDropdown, filter, setFilter]);

  const handleAtHomeClick = useCallback(() => {
    closeFilterDropdown();
    if (filter === "At Home") return;
    setFilter("At Home");
  }, [closeFilterDropdown, filter, setFilter]);

  const showFilterDropdown =
    collection === "bgk"
      ? kidsDeposits.length > 0 && kidsUnstaked.length > 0
      : pupsDeposits.length > 0 && pupsUnstaked.length > 0;

  useEffect(() => {
    if (!showFilterDropdown) setFilter("All");
  }, [filter, setFilter, showFilterDropdown]);

  return (
    <OuterContainer>
      {showFilterDropdown ? (
        <Container position="relative">
          <FilterDropdownButton
            onClick={toggleFilterDropdown}
            id="dropdown-button"
          >
            <span id="dropdown-button-text">{filter}</span>
            <Caret
              id="dropdown-button-caret"
              filterDropdownOpen={filterDropdownOpen}
            >
              ▲
            </Caret>
          </FilterDropdownButton>
          {filterDropdownOpen && (
            <FilterDropdown id="dropdown">
              <Option
                selected={filter === "All"}
                onClick={handleAllClick}
                id="dropdown-option-all"
              >
                All
              </Option>
              <Option
                selected={filter === "At Camp"}
                onClick={handleAtCampClick}
                id="dropdown-option-camp"
              >
                At Camp
              </Option>
              <Option
                selected={filter === "At Home"}
                onClick={handleAtHomeClick}
                id="dropdown-option-home"
              >
                At Home
              </Option>
            </FilterDropdown>
          )}
        </Container>
      ) : (
        <FilterDropdownPlaceholder />
      )}
      <ToggleContainer center={!showFilterDropdown}>
        <img
          src="/bgk-logo.png"
          alt="kids logo"
          height="36px"
          onClick={() => setCollection("bgk")}
          style={{ cursor: "pointer" }}
        />
        <Toggle collection={collection} onClick={toggleCollection}>
          <div />
        </Toggle>
        <img
          src="/bgp-logo.png"
          alt="kids logo"
          height="36px"
          onClick={() => setCollection("bgp")}
          style={{ cursor: "pointer" }}
        />
      </ToggleContainer>
      <Container hideMobile={true}>
        <CarouselButton
          selected={viewType === "carousel"}
          onClick={setCarousel}
          title="Carousel view"
        >
          <CarouselImg>
            <div />
            <div />
            <div />
          </CarouselImg>
        </CarouselButton>
        <GridButton
          selected={viewType === "grid"}
          onClick={setGrid}
          title="Grid view"
        >
          <GridImg>
            <div />
            <div />
            <div />
            <div />
          </GridImg>
        </GridButton>
      </Container>
    </OuterContainer>
  );
};

export default SettingsBar;
