import styled from "styled-components";

const Button = styled.button`
  background-color: #fff;
  border: solid 3px #e04778;
  border-radius: 30px;
  color: #e04778;
  font-size: 26px;
  height: 42px;

  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ width }) => `width: ${width ? width : "240px"};`}
  ${({ disabled }) =>
    disabled
      ? `
    color: #999;
    border-color: #999;
  `
      : "cursor: pointer;"}

  max-width: 100%;

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      `
    background-color: #f2a7bf;
    color: #fff;
  `};
  }
`;

const StagedButton = styled(Button)`
  background-color: #e04778;
  color: #fff;
`;

const ClearButton = styled(Button).attrs(({ width }) => ({
  width: width || "180px",
}))`
  font-size: 20px;
  height: 36px;
  ${({ noMargin }) => !noMargin && `margin-right: 12px;`}
`;

const SubmitButton = styled(StagedButton).attrs(({ width }) => ({
  width: width || "180px",
}))`
  font-size: 20px;
  height: 36px;

  ${({ disabled }) => disabled && "background-color: #999;"}
`;

const LinkButton = styled(Button).attrs(() => ({
  width: "340px",
}))`
  border-radius: 12px;
  color: #f2a7bf;
  font-size: 36px;
  height: 60px;

  &,
  &:hover {
    background-color: #e04778;
  }

  &:hover {
    color: #fff;
  }
`;

export { Button, ClearButton, LinkButton, SubmitButton, StagedButton };
