import styled from "styled-components";
import { Button } from "./styles";
import { Link, useLocation } from "react-router-dom";

const Container = styled.nav`
  grid-column: 1/-1;
  height: 76px;
  background-color: #fff;
  font-size: 14px;
  padding: 0 34px;
  width: calc(100% - 68px);

  display: none;

  @media only screen and (min-width: 975px) {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    align-items: center;
  }
`;

const Heading = styled.a`
  cursor: pointer;
  height: 22px;
  line-height: 20px;
  text-align: center;
  padding: 9px 18px;
  width: min-content;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    background-color: #e04778;
    border-radius: 30px;
    color: #fff;
  }
`;

const HeadingInternal = styled(Link)`
  cursor: pointer;
  height: 22px;
  line-height: 20px;
  text-align: center;
  padding: 9px 18px;
  width: min-content;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    background-color: #e04778;
    border-radius: 30px;
    color: #fff;
  }
`;

const Headings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Logo = styled.img`
  height: 42px;
  margin: 4px 0;
`;

const ConnectButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  padding: 0 18px;
  width: 140px;

  &:hover {
    background-color: #e04778;
    color: #fff;
  }
`;

const TopBar = ({ account, handleConnectButtonClick }) => {
  const { pathname } = useLocation();
  const atFAQ = pathname === "/faq";
  const atHowTo = pathname === "/how-to";

  return (
    <Container>
      <a
        href="https://www.bubblegumkids.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Logo src="/bgk-logo.png" alt="bubblegum kids logo" />
      </a>
      <Headings>
        <HeadingInternal to="/marketplace">Marketplace</HeadingInternal>
        {atFAQ || atHowTo ? (
          <HeadingInternal to="/stake">Staking</HeadingInternal>
        ) : (
          <HeadingInternal to="/faq">FAQ</HeadingInternal>
        )}
        <Heading
          href="https://www.bubblegumkids.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Collections
        </Heading>
        <Heading
          href="https://www.bubblegumkids.com/roadmap"
          rel="noopener noreferrer"
          target="_blank"
        >
          Roadmap
        </Heading>
        <Heading
          href="https://www.bubblegumkids.com/about"
          rel="noopener noreferrer"
          target="_blank"
        >
          About
        </Heading>
        <Heading
          href="https://twitter.com/bubblegumkids_"
          rel="noopener noreferrer"
          target="_blank"
        >
          Follow Us
        </Heading>
        <Heading
          href="https://www.bubblegumkids.com/contact-us"
          rel="noopener noreferrer"
          target="_blank"
        >
          Contact
        </Heading>
      </Headings>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {!account && handleConnectButtonClick && (
          <ConnectButton
            id="top-bar-connect-btn"
            onClick={handleConnectButtonClick}
          >
            Connect
          </ConnectButton>
        )}
      </div>
    </Container>
  );
};

export default TopBar;
