import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { ClearButton } from "./styles";
import { checkForParentElementById } from "./utils";
import PreviousOrders from "./PreviousOrders";

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 26px;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;
`;

const PreviousOrdersModal = ({
  closeModal,
  items: detailedItems,
  previousOrders,
}) => {
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const targetIsModalChild = checkForParentElementById(
        event.target,
        "previous-orders-modal"
      );
      const targetIsAvatarChild = checkForParentElementById(
        event.target,
        "user-avatar"
      );
      const shouldClose = !(targetIsModalChild || targetIsAvatarChild);
      if (shouldClose) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <OuterContainer id="previous-orders-modal">
      <Container>
        <CloseButtonContainer onClick={closeModal}>
          <CloseButton>×</CloseButton>
        </CloseButtonContainer>
        <Heading>Previous Orders</Heading>
        <InnerContainer>
          <PreviousOrders
            detailedItems={detailedItems}
            previousOrders={previousOrders}
          />
        </InnerContainer>
        <ButtonsContainer>
          <ClearButton onClick={closeModal}>OK</ClearButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default PreviousOrdersModal;
