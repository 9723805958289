import styled from "styled-components";
import { useCallback } from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-top: 5px;
`;

const Count = styled.div`
  color: #e04778;
  font-size: ${({ small }) => (small ? "18px" : "24px")};
  background-color: #fff;
  padding: 0 ${({ small }) => (small ? "12px" : "6px")};
  border-radius: 32px;
`;

const CountButton = styled.button`
  font-size: ${({ small }) => (small ? "16px" : "20px")};
  color: #fff;
  border: none;
  background-color: #e04778;
  width: ${({ small }) => (small ? "22px" : "28px")};
  height: ${({ small }) => (small ? "22px" : "28px")};
  border-radius: 32px;

  ${({ disabled }) =>
    disabled
      ? `
    background-color: #999;
  `
      : "cursor: pointer;"}
`;

const PlusButton = styled(CountButton).attrs(({ small }) => ({
  small,
}))``;

const MinusButton = styled(CountButton).attrs(({ small }) => ({
  small,
}))``;

const RemoveButton = styled.button`
  color: #e04778;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  margin-left: 4px;
`;

const CartCounter = ({
  count,
  isAtGumLimit,
  isAtPerAddressLimit,
  isAtTotalRemainingLimit,
  setCount,
  showRemoveButton = false,
  small = false,
}) => {
  const handlePlusButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (!isAtGumLimit && !isAtPerAddressLimit && !isAtTotalRemainingLimit)
        setCount(count + 1);
    },
    [
      count,
      setCount,
      isAtGumLimit,
      isAtPerAddressLimit,
      isAtTotalRemainingLimit,
    ]
  );

  const handleMinusButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (count > 0) setCount(count - 1);
    },
    [count, setCount]
  );

  const handleRemoveButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      setCount(0);
    },
    [setCount]
  );

  return (
    <Container>
      {count > 0 ? (
        <>
          <MinusButton
            onClick={handleMinusButtonClick}
            disabled={count === 0}
            small={small}
          >
            -
          </MinusButton>
          <Count small={small}>{count}</Count>
          <PlusButton
            onClick={handlePlusButtonClick}
            disabled={
              isAtGumLimit || isAtPerAddressLimit || isAtTotalRemainingLimit
            }
            small={small}
          >
            +
          </PlusButton>
        </>
      ) : (
        <>
          <div style={{ width: small ? "22px" : "28px" }} />
          <PlusButton
            onClick={handlePlusButtonClick}
            disabled={
              isAtGumLimit || isAtPerAddressLimit || isAtTotalRemainingLimit
            }
            small={small}
          >
            +
          </PlusButton>
          <div style={{ width: small ? "22px" : "28px" }} />
        </>
      )}

      {count > 0 && showRemoveButton && (
        <RemoveButton onClick={handleRemoveButtonClick}>×</RemoveButton>
      )}
    </Container>
  );
};

export default CartCounter;
