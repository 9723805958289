import { useCallback, useEffect, useState } from "react";
import uniq from "lodash/uniq";
import uniqWith from "lodash/uniqWith";
import StakeLanding from "./StakeLanding";
import Stake from "./Stake";
import VxKidsMint from "./VxKidsMint";
import {
  API_URL,
  CONTRACT_ADDRESSES,
  KIDS_OFFSET,
  PUPS_OFFSET,
} from "./config";
import {
  _connectCoinbaseWallet,
  _connectMetaMask,
  _connectWalletConnect,
  _getGumBalance,
} from "./utils";
import FAQ from "./FAQ";
import HowTo from "./HowTo";
import Marketplace from "./Marketplace";

const { REACT_APP_ETHEREUM_ENVIRONMENT } = process.env;

const {
  kidsContractAddress,
  pupsContractAddress,
  stakingContractAddress,
  gumContractAddress,
  vxKidsContractAddress,
} = CONTRACT_ADDRESSES;

const App = ({ isFAQ, isHowTo, isMarketPlace, isStake, isVxKidsMint }) => {
  const [account, setAccount] = useState("");
  const [gumBalance, setGumBalance] = useState(0);
  const [gumContract, setGumContract] = useState(null);
  const [imgSrcs, setImgSrcs] = useState(null);
  const [kidsContract, setKidsContract] = useState(null);
  const [kidsDeposits, setKidsDeposits] = useState([]);
  const [kidsIds, setKidsIds] = useState([]);
  const [kidsUnstaked, setKidsUnstaked] = useState([]);
  const [pupsContract, setPupsContract] = useState(null);
  const [pupsDeposits, setPupsDeposits] = useState([]);
  const [pupsIds, setPupsIds] = useState([]);
  const [pupsUnstaked, setPupsUnstaked] = useState([]);
  const [stakingContract, setStakingContract] = useState(null);
  const [vxKidsContract, setVxKidsContract] = useState(null);
  const [web3, setWeb3] = useState(null);

  const connectMetaMask = useCallback(
    () =>
      _connectMetaMask({
        setAccount,
        setKidsContract,
        setPupsContract,
        setStakingContract,
        setGumContract,
        kidsContractAddress,
        pupsContractAddress,
        stakingContractAddress,
        gumContractAddress,
        setWeb3,
        vxKidsContractAddress,
        setVxKidsContract,
      }),
    [
      setAccount,
      setKidsContract,
      setPupsContract,
      setStakingContract,
      setGumContract,
      setWeb3,
      setVxKidsContract,
    ]
  );

  const connectWalletConnect = useCallback(
    () =>
      _connectWalletConnect({
        setAccount,
        setKidsContract,
        setPupsContract,
        setStakingContract,
        setGumContract,
        kidsContractAddress,
        pupsContractAddress,
        stakingContractAddress,
        gumContractAddress,
        setWeb3,
        vxKidsContractAddress,
        setVxKidsContract,
      }),
    [
      setAccount,
      setKidsContract,
      setPupsContract,
      setStakingContract,
      setGumContract,
      setWeb3,
      setVxKidsContract,
    ]
  );

  const connectCoinbaseWallet = useCallback(
    () =>
      _connectCoinbaseWallet({
        setAccount,
        setKidsContract,
        setPupsContract,
        setStakingContract,
        setGumContract,
        kidsContractAddress,
        pupsContractAddress,
        stakingContractAddress,
        gumContractAddress,
        setWeb3,
        vxKidsContractAddress,
        setVxKidsContract,
      }),
    [
      setAccount,
      setKidsContract,
      setPupsContract,
      setStakingContract,
      setGumContract,
      setWeb3,
      setVxKidsContract,
    ]
  );

  const getGumBalance = useCallback(
    () => _getGumBalance({ account, gumContract, setGumBalance }),
    [account, gumContract, setGumBalance]
  );

  const getDepositsOf = useCallback(async () => {
    try {
      const depositsOfRes = await stakingContract.methods
        .depositsOf(account)
        .call();
      const [_kidsDeposits = [], _pupsDeposits = []] = depositsOfRes || [];
      setKidsDeposits(_kidsDeposits.map((id) => Number(id)));
      setPupsDeposits(_pupsDeposits.map((id) => Number(id)));
    } catch (err) {
      console.error(err);
    }
  }, [account, stakingContract, setKidsDeposits, setPupsDeposits]);

  const getImages = useCallback(() => {
    const _imgSrcs = {
      bgk: {},
      bgp: {},
    };
    const kidsIdsWithCollection = kidsIds.map((id) => ({
      id,
      collection: "bgk",
    }));
    const pupsIdsWithCollection = pupsIds.map((id) => ({
      id,
      collection: "bgp",
    }));
    const items = [...kidsIdsWithCollection, ...pupsIdsWithCollection];
    Promise.all(
      items.map(({ id, collection }) => {
        const offset = collection === "bgk" ? KIDS_OFFSET : PUPS_OFFSET;
        const offsetId = (id + offset) % 10_000;
        return fetch(`${API_URL}/images/${collection}/${offsetId}`)
          .then((res) => {
            if (res.status > 201) {
              throw new Error(`${res.status}: could not get image`);
            }
            return res.json();
          })
          .then((data) => data)
          .catch(console.error);
      })
    ).then((data) => {
      for (const item of data) {
        const { src, collection, id } = item?.data || {};
        if (collection && id && src) {
          _imgSrcs[collection][id] = src;
        }
      }
      setImgSrcs(_imgSrcs);
    });
  }, [kidsIds, pupsIds, setImgSrcs]);

  const fetchUnstaked = useCallback(
    async ({ address, cursor, unstaked = [] }) => {
      fetch(`${API_URL}/unstaked`, {
        method: "POST",
        body: JSON.stringify({
          address,
          cursor,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status > 201) {
            throw new Error(`${res.status}: could not get unstaked nfts`);
          }
          return res.json();
        })
        .then(async (data) => {
          console.log({ data });
          const {
            cursor: newCursor,
            page,
            page_size,
            result = [],
            total,
          } = data || {};
          const newUnstaked = uniqWith(
            [
              ...unstaked,
              ...result.map(
                ({ token_address: collectionAddress, token_id }) => ({
                  collectionAddress,
                  id: parseInt(token_id),
                })
              ),
            ],
            (a, b) =>
              a.collectionAddress === b.collectionAddress && a.id === b.id
          );
          if (total > page * page_size) {
            await fetchUnstaked({
              address,
              cursor: newCursor,
              unstaked: newUnstaked,
            });
          } else {
            const _kidsUnstaked = newUnstaked
              .filter(
                ({ collectionAddress }) =>
                  collectionAddress.toLowerCase() ===
                  kidsContractAddress.toLowerCase()
              )
              .map(({ id }) => id);
            const _pupsUnstaked = newUnstaked
              .filter(
                ({ collectionAddress }) =>
                  collectionAddress.toLowerCase() ===
                  pupsContractAddress.toLowerCase()
              )
              .map(({ id }) => id);
            setKidsUnstaked(_kidsUnstaked);
            setPupsUnstaked(_pupsUnstaked);
          }
        })
        .catch(console.error);
    },
    [setKidsUnstaked, setPupsUnstaked]
  );

  const getUnstakedNfts = useCallback(() => {
    fetchUnstaked({
      address: account,
    });
  }, [account, fetchUnstaked]);

  useEffect(() => {
    if (!account) return;
    getUnstakedNfts();
  }, [account]);

  useEffect(() => {
    if (!account || !stakingContract || isMarketPlace) return;
    getDepositsOf();
  }, [account, getDepositsOf, isMarketPlace, stakingContract]);

  useEffect(() => {
    if (REACT_APP_ETHEREUM_ENVIRONMENT === "goerli") {
      setKidsUnstaked(
        [0, 1, 2, 3, 4, 5, 6, 7].filter((id) => !kidsDeposits.includes(id))
      );
      setPupsUnstaked(
        [0, 1, 2, 3, 4, 5, 6, 7].filter((id) => !pupsDeposits.includes(id))
      );
    }
  }, [kidsDeposits, pupsDeposits, setKidsUnstaked, setPupsUnstaked]);

  useEffect(() => {
    const _kidsIds = uniq([...kidsUnstaked, ...kidsDeposits]);
    const _pupsIds = uniq([...pupsUnstaked, ...pupsDeposits]);
    setKidsIds(_kidsIds);
    setPupsIds(_pupsIds);
  }, [kidsUnstaked, pupsUnstaked, kidsDeposits, pupsDeposits]);

  useEffect(() => {
    if (!account || !gumContract) return;
    getGumBalance();
  }, [account, getGumBalance, gumContract]);

  useEffect(() => {
    getImages();
  }, [getImages, kidsIds.length, pupsIds.length]);

  if (isHowTo) {
    return <HowTo account={account} />;
  }

  if (isMarketPlace) {
    return (
      <Marketplace
        account={account}
        connectMetaMask={connectMetaMask}
        connectWalletConnect={connectWalletConnect}
        connectCoinbaseWallet={connectCoinbaseWallet}
        getGumBalance={getGumBalance}
        gumBalance={gumBalance}
        gumContract={gumContract}
      />
    );
  }

  if (isVxKidsMint) {
    return (
      <VxKidsMint
        account={account}
        connectMetaMask={connectMetaMask}
        connectWalletConnect={connectWalletConnect}
        connectCoinbaseWallet={connectCoinbaseWallet}
        gumBalance={gumBalance}
        gumContract={gumContract}
        imgSrcs={imgSrcs}
        kidsIds={kidsIds}
        vxKidsContract={vxKidsContract}
      />
    );
  }

  if (isStake) {
    return (
      <Stake
        account={account}
        getGumBalance={getGumBalance}
        gumBalance={gumBalance}
        imgSrcs={imgSrcs}
        kidsContract={kidsContract}
        kidsDeposits={kidsDeposits}
        kidsIds={kidsIds}
        kidsUnstaked={kidsUnstaked}
        pupsContract={pupsContract}
        pupsDeposits={pupsDeposits}
        pupsIds={pupsIds}
        pupsUnstaked={pupsUnstaked}
        setKidsDeposits={setKidsDeposits}
        setKidsUnstaked={setKidsUnstaked}
        setPupsDeposits={setPupsDeposits}
        setPupsUnstaked={setPupsUnstaked}
        stakingContract={stakingContract}
        web3={web3}
      />
    );
  }

  if (isFAQ) {
    return <FAQ account={account} />;
  }

  return (
    <StakeLanding
      account={account}
      connectMetaMask={connectMetaMask}
      connectWalletConnect={connectWalletConnect}
      connectCoinbaseWallet={connectCoinbaseWallet}
    />
  );
};

export default App;
