import sanity from "./sanity";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ConnectWalletModal from "./ConnectWalletModal";
import TopBar from "./TopBar";
import TopBarMobile from "./TopBarMobile";
import { Button } from "./styles";
import ErrorModal from "./ErrorModal";
import MarketplaceUserPanel from "./MarketplaceUserPanel";
import MarketplaceDetailModal from "./MarketplaceDetailModal";
import CartModal from "./CartModal";
import { API_URL, CONTRACT_ADDRESSES } from "./config";
import ProcessOrderModal from "./ProcessOrderModal";
import ConfirmOrderModal from "./ConfirmOrderModal";
import { v4 as uuid } from "uuid";
import PreviousOrdersModal from "./PreviousOrdersModal";
import MarketplaceSuccessModal from "./MarketplaceSuccessModal";
import MarketplaceHeading from "./MarketplaceHeading";
import PriceTag from "./PriceTag";
import CartCounter from "./CartCounter";
import {
  getIsAtGumLimit,
  getIsAtPerAddressLimit,
  getIsAtTotalRemainingLimit,
  getQuantityRemaining,
} from "./utils";
import moment from "moment";

const shouldShowSanityTestingItems = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const sanityTesting = queryParams.get("sanityTesting");
  return (
    sanityTesting ===
    "7496a9c1c38086a2a1100b126aa4eb6468158d22b3c99a34f63752734abed927"
  );
};

const getLocalStorageCartKey = (account) => `bgk_marketplace_cart.${account}`;

const PROCESSED_NOT_CONFIRMED_ERROR =
  "We're sorry, your GUM payment has processed successfully, and the team has received you order, but something went wrong at the final confirmation step. Please reach out to the team on Discord and let them know your order and payment have been submitted but not confirmed.";

const ITEMS_QUERY_LIMIT = 50;

const merchQuery = `
    *[_type == "merch"] {
        _id,
        _type,
        productId,
        name,
        gumPrice,
        description,
        "imageUrl": image.asset->url,
        expirationDate,
        totalQuantity,
        limitPerAddress,
        sizes,
        variantIds
    }[0...${ITEMS_QUERY_LIMIT}]
`;

// const intangibleItemQuery = `
//     *[_type == "intangibleItem"] {
//         _id,
//         _type,
//         name,
//         gumPrice,
//         description,
//         "imageUrl": image.asset->url,
//         expirationDate,
//         totalQuantity,
//         limitPerAddress
//     }[0...${ITEMS_QUERY_LIMIT}]
// `;

// const offChainRaffleQuery = `
//     *[_type == "offChainRaffle"] {
//         _id,
//         _type,
//         name,
//         gumPrice,
//         description,
//         prize->{_id, name, "imageUrl": image.asset->url},
//         "imageUrl": image.asset->url,
//         expirationDate
//     }[0...${ITEMS_QUERY_LIMIT}]
// `;

const TYPE_TO_QUERY = {
  merch: merchQuery,
  // intangibleItem: intangibleItemQuery,
  // offChainRaffle: offChainRaffleQuery,
};

const { gumContractAddress, gumSinkAddress } = CONTRACT_ADDRESSES;

const Container = styled.div`
  grid-template-rows: 1fr auto;
  margin: 0 auto;
  padding-bottom: 52px;
  text-align: left;
  width: 90%;
`;

const ImageContainer = styled.div`
  border-radius: 6px;
  cursor: pointer;
  margin: 0 auto;
  max-width: 450px;
  text-align: left;
  width: calc(100% - 26px);
  border: 3px solid #e04778;
  border-radius: 12px;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-grow: 1;
  z-index: 2;
  overflow: hidden;
`;

const InnerImageContainer = styled.div`
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 10px auto;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    z-index: 1;
    transition: transform 100ms ease-in-out, border-radius 100ms ease-in-out;
  }

  &:hover img {
    transform: scale(1.06);
    border-radius: 8px;
  }
`;

const ItemsContainer = styled.div`
  display: grid;
  overflow-y: scroll;
  max-height: 100%;

  @media only screen and (min-width: 975px) {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 30% 30% 30%;
  }

  @media only screen and (min-width: 1250px) {
    display: grid;
    grid-column-gap: 4%;
    grid-template-columns: 22% 22% 22% 22%;
  }
`;

const ItemHeader = styled.h2`
  color: #e04778;
  font-size: 24px;
  margin: 6px 0 12px;
`;

const ItemInfo = styled.div`
  height: 136px;
  width: 100%;
`;

const OuterItemContainer = styled.div`
  width: calc(100% - 26px);
  text-align: center;
  margin: 0 10px 72px;
  display: flex;
  flex-direction: column;
`;

const QuantityRemaining = styled.div`
  position: absolute;
  right: 12px;
  color: #fff;
  background-color: #e04778;
  padding: 6px 8px 8px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const Subtotal = styled.div``;

/*
  TODO:
  - validate order (per account limit, total quantity, expiration) in backend
  - mobile

  DEPOLOY:
  - clear prod spreadsheet and database
  - change url to "/marketplace"
*/

const Marketplace = ({
  account,
  connectMetaMask,
  connectWalletConnect,
  connectCoinbaseWallet,
  getGumBalance,
  gumBalance,
  gumContract,
}) => {
  const [accountPreviousOrderCounts, setAccountPreviousOrderCounts] = useState(
    {}
  );
  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [cart, setCart] = useState([]); // { id: string; count: number }[]
  const [cartModalInBackground, setCartModalInBackground] = useState(false);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [deliveryAccount, setDeliveryAccount] = useState(undefined); // intentional: hack to provide fallback
  const [deliveryAccountRequired, setDeliveryAccountRequired] = useState(false);
  const [detailedItemId, setDetailedItemId] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [fullName, setFullName] = useState("");
  const [items, setItems] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [physicalAddressRequired, setPhysicalAddressRequired] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [previousOrderCounts, setPreviousOrderCounts] = useState({});
  const [previousOrders, setPreviousOrders] = useState([]);
  const [province, setProvince] = useState("");
  const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const [showPreviousOrdersModal, setShowPreviousOrdersModal] = useState(false);
  const [showProcessOrderModal, setShowProcessOrderModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const now = useMemo(() => moment(), []);

  const getPreviousOrderCounts = useCallback(() => {
    const itemIds = items.map(({ _id }) => _id);
    fetch(`${API_URL}/order-count-by-item`, {
      method: "POST",
      body: JSON.stringify({ itemIds }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status > 201) {
          throw new Error(`${res.status}: could not get previous order counts`);
        }
        return res.json();
      })
      .then(({ previousOrderCounts }) => {
        setPreviousOrderCounts(previousOrderCounts || {});
      })
      .catch(console.error);
  }, [items]);

  const getItems = useCallback(
    async (types) => {
      const allItems = [];
      for (const type of types) {
        const query = TYPE_TO_QUERY[type];
        const _items = await sanity.fetch(query);
        const environmentItems = shouldShowSanityTestingItems()
          ? _items.filter(({ name }) => name.includes("@TESTING@"))
          : _items.filter(({ name }) => !name.includes("@TESTING@"));
        if (_items?.length > 0) allItems.push(...environmentItems);
      }
      setItems(allItems);
    },
    [account]
  );

  const getAccountUnconfirmedPayments = useCallback(async () => {
    fetch(`${API_URL}/unconfirmed-payments`, {
      method: "POST",
      body: JSON.stringify({ account }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status > 201 && res.status !== 404) {
          throw new Error(
            `${res.status}: could not get account unconfirmed payments`
          );
        }
        return res.json();
      })
      .then(console.log)
      .catch(console.error);
  }, [account]);

  const getAccountPreviousOrderCounts = useCallback(() => {
    const itemIds = items.map(({ _id }) => _id);
    fetch(`${API_URL}/account-order-count-by-item`, {
      method: "POST",
      body: JSON.stringify({ account, itemIds }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status > 201) {
          throw new Error(
            `${res.status}: could not get account previous order counts`
          );
        }
        return res.json();
      })
      .then(({ previousOrderCounts }) => {
        setAccountPreviousOrderCounts(previousOrderCounts || {});
      })
      .catch(console.error);
  }, [account, items, setAccountPreviousOrderCounts]);

  const handleConnectButtonClick = useCallback(() => {
    setShowConnectWalletModal(true);
  }, [setShowConnectWalletModal]);

  const handleAvatarClick = useCallback(() => {
    setShowPreviousOrdersModal(true);
  }, [setShowPreviousOrdersModal]);

  const handleCartClick = useCallback(() => {
    if (purchaseInProgress) return;
    setShowCartModal(true);
  }, [purchaseInProgress, setShowCartModal]);

  const finalDeliveryAccount = useMemo(
    () => (deliveryAccount === undefined ? account : deliveryAccount),
    [account, deliveryAccount]
  );

  const handleCartItemClick = useCallback(
    (id) => {
      setDetailedItemId(id);
      setCartModalInBackground(true);
      setShowCartModal(false);
    },
    [setCartModalInBackground, setDetailedItemId, setShowCartModal]
  );

  const handleBackToCartClick = useCallback(() => {
    setDetailedItemId("");
    setShowCartModal(true);
  }, [setDetailedItemId, setShowCartModal]);

  const handleCartUpdate = useCallback(
    ({ id, count, size = null }) => {
      const existingItemIndex = cart.findIndex(({ id: _id }) => id === _id);
      // clone cart and remove expired items
      let _cart = [...cart].filter(({ id }) => {
        const { expirationDate: _expirationDate } =
          items.find(({ _id }) => id === _id) || {};
        const expirationDate = moment(_expirationDate);
        const isExpired = now ? now.isAfter(expirationDate) : false;
        return !isExpired;
      });
      if (existingItemIndex > -1) {
        if (count === 0) {
          _cart = [
            ..._cart.slice(0, existingItemIndex),
            ..._cart.slice(existingItemIndex + 1),
          ];
        } else {
          _cart = [
            ..._cart.slice(0, existingItemIndex),
            { id, count, size },
            ..._cart.slice(existingItemIndex + 1),
          ];
        }
      } else {
        _cart = [..._cart, { id, count, size }];
      }
      setCart(_cart);
      try {
        const localStorageCart = JSON.stringify(_cart);
        const localStorageCartKey = getLocalStorageCartKey(account);
        localStorage.setItem(localStorageCartKey, localStorageCart);
      } catch {}
    },
    [account, cart, items, now, setCart]
  );

  useEffect(() => {
    if (!cart?.length || !items?.length) return;
    for (const { id } of cart) {
      const { _type } = items.find(({ _id }) => id === _id) || {};
      // TODO: what does offchain raffle require???
      if (_type === "intangibleItem") setDeliveryAccountRequired(true);
      if (_type === "merch") setPhysicalAddressRequired(true);
    }
  }, [
    cart.length,
    items,
    setDeliveryAccountRequired,
    setPhysicalAddressRequired,
  ]);

  const handleCartSubmit = useCallback(() => {
    setShowProcessOrderModal(true);
  }, [setShowProcessOrderModal]);

  const handleProcessOrderSubmit = useCallback(() => {
    setShowConfirmOrderModal(true);
  }, [setShowConfirmOrderModal]);

  const gumCartTotal = useMemo(() => {
    return cart.reduce((acc, { count = 0, id }) => {
      const item = items.find(({ _id }) => id === _id) || {};
      const { gumPrice = 0 } = item;
      return acc + gumPrice * count;
    }, 0);
  }, [account, cart, items]);

  const handleConfirmOrderSubmit = useCallback(() => {
    const orderItems = cart
      .map(({ id, count, size }) => {
        const detailedItem = items.find(({ _id }) => id === _id);
        const maybeDetailedItem = detailedItem || {};
        return {
          quantity: count,
          ...maybeDetailedItem,
          shouldRemove: !detailedItem,
          size,
        };
      })
      .filter(({ shouldRemove }) => !shouldRemove)
      .map((item) => {
        const { size, sizes, variantIds } = item;
        const index = size && sizes ? sizes.indexOf(size) : 0;
        const variantId = variantIds[index];
        return {
          ...item,
          variantId,
        };
      });
    const _orderId = uuid();
    setOrderId(_orderId);
    const order = {
      id: _orderId,
      date: new Date(),
      items: orderItems,
      recipient: {
        fullName,
        addressLineOne,
        addressLineTwo,
        province,
        city,
        country,
        postalCode,
        phoneNumber,
        email,
        deliveryEthAddress: finalDeliveryAccount,
        paymentEthAddress: account,
      },
    };
    fetch(`${API_URL}/process-order`, {
      method: "POST",
      body: JSON.stringify(order),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status > 201) {
          throw new Error(`${res.status}: could not process order`);
        }
        return res.json();
      })
      .then(async (data) => {
        const { updatedRange = "" } = data;
        console.log({ updatedRange });
        // eg, "'2023_01'!A10:U10"
        const tab = updatedRange.match(/'?(\d+_\d+)'?/)?.[1] || "";
        const receivedRange =
          updatedRange.match(/[A-Z]+\d+:[A-Z]+\d+/)?.[0] || "";
        const values = receivedRange.split(":") || ["", ""];
        const [startValue, endValue] = values;
        const _startRow = startValue.match(/\d+/)?.[0] || "";
        // don't delete or update the header row if it's a new tab
        const startRow = _startRow === "1" ? "2" : _startRow;
        const endRow = endValue.match(/\d+/)?.[0] || "";
        if (!startRow || !endRow) {
          setError("We're sorry, we weren't able to process your order.");
          return;
        }
        try {
          setPurchaseInProgress(true);
          const transferRes = await gumContract.methods
            .transfer(gumSinkAddress, `${gumCartTotal}000000000000000000`)
            .send({
              from: account,
              to: gumContractAddress,
            });
          console.log({ transferRes });
          const { transactionHash } = transferRes || {};
          if (transactionHash) {
            setPurchaseInProgress(false);
            fetch(
              `${API_URL}/confirm-order?tabName=${tab}&start=${startRow}&end=${endRow}`,
              {
                method: "POST",
                body: JSON.stringify({
                  email,
                  orderId: _orderId,
                  paymentTx: transactionHash,
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => {
                if (res.status > 201 && res.status !== 569) {
                  throw new Error(`${res.status}: could not confirm order`);
                }
                setCart([]);
                const localStorageCartKey = getLocalStorageCartKey(account);
                localStorage.setItem(localStorageCartKey, JSON.stringify([]));
                setShowSuccessModal(true);
                getGumBalance();
                getAccountPreviousOrderCounts();
                getPreviousOrders();
                getPreviousOrderCounts();
              })
              .catch((err) => {
                console.error(err);
                setError(PROCESSED_NOT_CONFIRMED_ERROR);
              });
          }
        } catch (err) {
          console.log({ err });
          if (
            err?.message &&
            err.message.includes("denied transaction signature")
          ) {
            fetch(
              `${API_URL}/cancel-unpaid-order?tabName=${tab}&start=${startRow}&end=${endRow}&orderId=${_orderId}`,
              {
                method: "POST",
              }
            )
              .then((res) => {
                if (res.status > 201) {
                  throw new Error(
                    `${res.status}: could not cancel unpaid order`
                  );
                }
                return res.json();
              })
              .then(console.log)
              .catch(console.error);
          } else {
            setError("We're sorry, your GUM payment failed to process.");
          }
        } finally {
          setPurchaseInProgress(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(
          "We're sorry, but something went wrong while processing your order."
        );
      });
  }, [
    account,
    addressLineOne,
    addressLineTwo,
    cart,
    city,
    country,
    email,
    finalDeliveryAccount,
    fullName,
    getGumBalance,
    gumCartTotal,
    items,
    phoneNumber,
    postalCode,
    province,
    setCart,
    setOrderId,
    setPurchaseInProgress,
    setShowSuccessModal,
  ]);

  const closeMarketplaceDetailModal = useCallback(() => {
    setCartModalInBackground(false);
    setDetailedItemId("");
  }, [setCartModalInBackground, setDetailedItemId]);

  const closeCartModal = useCallback(() => {
    setCartModalInBackground(false);
    setShowCartModal(false);
  }, [setCartModalInBackground, setShowCartModal]);

  const getPreviousOrders = useCallback(() => {
    fetch(`${API_URL}/previous-orders`, {
      method: "POST",
      body: JSON.stringify({ account }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status > 201) {
          throw new Error(`${res.status}: could not get previous orders`);
        }
        return res.json();
      })
      .then(async (data) => {
        const { previousOrders: _previousOrders } = data;
        setPreviousOrders(_previousOrders);
      })
      .catch(console.error);
  }, [account, setPreviousOrders]);

  const confirmModalGoBack = useCallback(() => {
    setShowConfirmOrderModal(false);
    setShowProcessOrderModal(true);
  }, [setShowConfirmOrderModal, setShowProcessOrderModal]);

  useEffect(() => {
    getItems([
      "merch",
      // "offChainRaffle",
      // "intangibleItem",
    ]);
  }, []);

  useEffect(() => {
    if (!items?.length) return;
    getPreviousOrderCounts();
  }, [items]);

  useEffect(() => {
    if (!account) return;
    getPreviousOrders();
    setCart([]);
    const localStorageCartKey = getLocalStorageCartKey(account);
    const _localStorageCart = localStorage.getItem(localStorageCartKey);
    if (_localStorageCart) {
      try {
        const localStorageCart = JSON.parse(_localStorageCart);
        if (localStorageCart?.length) {
          const _cart = [...localStorageCart].filter(({ id }) => {
            const { expirationDate: _expirationDate } =
              items.find(({ _id }) => id === _id) || {};
            const expirationDate = moment(_expirationDate);
            const isExpired = now ? now.isAfter(expirationDate) : false;
            return !isExpired;
          });
          setCart(_cart);
        }
      } catch {}
    }
  }, [account, items, now, setCart]);

  useEffect(() => {
    if (!account || !items.length) return;
    getAccountPreviousOrderCounts();
  }, [account, items]);

  useEffect(() => {
    if (!account) return;
    getAccountUnconfirmedPayments();
  }, [account]);

  return (
    <>
      {error && <ErrorModal error={error} setError={setError} />}
      {showCartModal && (
        <CartModal
          accountPreviousOrderCounts={accountPreviousOrderCounts}
          cart={cart}
          closeModal={closeCartModal}
          gumBalance={gumBalance}
          gumCartTotal={gumCartTotal}
          handleCartItemClick={handleCartItemClick}
          handleCartSubmit={handleCartSubmit}
          handleCartUpdate={handleCartUpdate}
          items={items}
          previousOrderCounts={previousOrderCounts}
        />
      )}
      {showConnectWalletModal && (
        <ConnectWalletModal
          closeModal={() => setShowConnectWalletModal(false)}
          connectMetaMask={connectMetaMask}
          connectWalletConnect={connectWalletConnect}
          connectCoinbaseWallet={connectCoinbaseWallet}
        />
      )}
      {detailedItemId && (
        <MarketplaceDetailModal
          accountPreviousOrderCounts={accountPreviousOrderCounts}
          cart={cart}
          cartModalInBackground={cartModalInBackground}
          closeModal={closeMarketplaceDetailModal}
          gumBalance={gumBalance}
          gumCartTotal={gumCartTotal}
          handleBackToCartClick={handleBackToCartClick}
          handleCartUpdate={handleCartUpdate}
          id={detailedItemId}
          items={items}
          previousOrderCounts={previousOrderCounts}
          previousOrders={previousOrders}
        />
      )}
      {showProcessOrderModal && (
        <ProcessOrderModal
          account={account}
          addressLineOne={addressLineOne}
          addressLineTwo={addressLineTwo}
          city={city}
          closeModal={() => setShowProcessOrderModal(false)}
          country={country}
          deliveryAccount={finalDeliveryAccount}
          deliveryAccountRequired={deliveryAccountRequired}
          email={email}
          fullName={fullName}
          handleProcessOrderSubmit={handleProcessOrderSubmit}
          phoneNumber={phoneNumber}
          physicalAddressRequired={physicalAddressRequired}
          postalCode={postalCode}
          province={province}
          setAddressLineOne={setAddressLineOne}
          setAddressLineTwo={setAddressLineTwo}
          setCity={setCity}
          setCountry={setCountry}
          setDeliveryAccount={setDeliveryAccount}
          setEmail={setEmail}
          setFullName={setFullName}
          setPhoneNumber={setPhoneNumber}
          setPostalCode={setPostalCode}
          setProvince={setProvince}
        />
      )}
      {showConfirmOrderModal && (
        <ConfirmOrderModal
          account={account}
          addressLineOne={addressLineOne}
          addressLineTwo={addressLineTwo}
          cart={cart}
          city={city}
          closeModal={() => setShowConfirmOrderModal(false)}
          country={country}
          deliveryAccount={finalDeliveryAccount}
          email={email}
          fullName={fullName}
          goBack={confirmModalGoBack}
          gumCartTotal={gumCartTotal}
          handleConfirmOrderSubmit={handleConfirmOrderSubmit}
          items={items}
          phoneNumber={phoneNumber}
          postalCode={postalCode}
          province={province}
        />
      )}
      {showPreviousOrdersModal && (
        <PreviousOrdersModal
          closeModal={() => setShowPreviousOrdersModal(false)}
          items={items}
          previousOrders={previousOrders}
        />
      )}
      {showSuccessModal && (
        <MarketplaceSuccessModal
          closeModal={() => setShowSuccessModal(false)}
          orderId={orderId}
        />
      )}
      <TopBar />
      <TopBarMobile />
      <Container>
        <MarketplaceHeading purchaseInProgress={purchaseInProgress} />
        {!account && (
          <Button
            onClick={handleConnectButtonClick}
            id="connect-button"
            width="300px"
          >
            Connect
          </Button>
        )}
        {account && (
          <MarketplaceUserPanel
            account={account}
            gumBalance={gumBalance}
            gumCartTotal={gumCartTotal}
            handleAvatarClick={handleAvatarClick}
            handleCartClick={handleCartClick}
            purchaseInProgress={purchaseInProgress}
          />
        )}
        {account && items?.length > 0 && (
          <ItemsContainer>
            {items.map((item) => {
              const {
                _id: id,
                _type,
                imageUrl: _imageUrl,
                gumPrice,
                limitPerAddress,
                name,
                totalQuantity,
                expirationDate: _expirationDate,
              } = item;
              const expirationDate = moment(_expirationDate);
              const isExpired = now ? now.isAfter(expirationDate) : false;
              if (isExpired) return null;

              const imageUrl =
                _imageUrl ||
                (_type === "offChainRaffle" && item.prize?.imageUrl) ||
                undefined;
              const count =
                cart.find(({ id: cartItemId }) => cartItemId === id)?.count ||
                0;
              const isAtGumLimit = getIsAtGumLimit({
                gumBalance,
                gumPrice,
                gumCartTotal,
              });
              const quantityRemaining = getQuantityRemaining({
                id,
                previousOrderCounts,
                totalQuantity,
              });
              const isAtPerAddressLimit = getIsAtPerAddressLimit({
                accountPreviousOrderCounts,
                count,
                id,
                limitPerAddress,
              });
              const isAtTotalRemainingLimit = getIsAtTotalRemainingLimit({
                count,
                id,
                previousOrderCounts,
                totalQuantity,
              });

              return (
                <OuterItemContainer key={id}>
                  <ImageContainer
                    onClick={() => setDetailedItemId(id)}
                    id="marketplace-item"
                  >
                    <InnerImageContainer>
                      <img src={imageUrl} alt={name} />
                    </InnerImageContainer>
                  </ImageContainer>
                  <ItemInfo>
                    {isFinite(quantityRemaining) ? (
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <QuantityRemaining>
                          {quantityRemaining} Left
                        </QuantityRemaining>
                      </div>
                    ) : null}
                    <CartCounter
                      count={count}
                      isAtGumLimit={isAtGumLimit}
                      isAtPerAddressLimit={isAtPerAddressLimit}
                      isAtTotalRemainingLimit={isAtTotalRemainingLimit}
                      setCount={(count) => handleCartUpdate({ id, count })}
                      showRemoveButton={true}
                    />
                    <ItemHeader>{name}</ItemHeader>
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <PriceTag large={true} price={gumPrice} />
                    </div>
                    {count > 0 && (
                      <Subtotal>Subtotal: {count * gumPrice} $GUM</Subtotal>
                    )}
                  </ItemInfo>
                </OuterItemContainer>
              );
            })}
          </ItemsContainer>
        )}
      </Container>
    </>
  );
};

export default Marketplace;
