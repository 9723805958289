import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Cart from "./Cart";

const OrderDate = styled.div`
  color: #e04778;
  text-align: center;
`;

const OrderId = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
`;

const OrderContainer = styled.div`
  margin-bottom: 16px;
`;

const PreviousOrders = ({ detailedItems, previousOrders }) => {
  const [copyOrderIdSuccess, setCopyOrderIdSuccess] = useState("");

  const copyOrderId = useCallback(
    async (orderId) => {
      setTimeout(() => {
        setCopyOrderIdSuccess("");
      }, 3000);
      try {
        await navigator.clipboard.writeText(orderId);
        setCopyOrderIdSuccess(orderId);
      } catch {}
    },
    [setCopyOrderIdSuccess]
  );

  const formattedPreviousOrders = useMemo(() => {
    return previousOrders.reduce((acc, order) => {
      const {
        confirmed,
        date_created: date,
        gum_price: gumPrice,
        item_id: itemId,
        item_name: name,
        order_id: orderId,
        quantity: count,
        size,
      } = order;
      if (acc[orderId]) {
        acc[orderId].items.push({
          item: {
            id: itemId,
            confirmed,
            name,
            gumPrice,
          },
          count,
          size,
        });
      } else {
        acc[orderId] = {
          date,
          items: [
            {
              item: {
                id: itemId,
                confirmed,
                name,
                gumPrice,
              },
              count,
              size,
            },
          ],
        };
      }
      return acc;
    }, {});
  }, [previousOrders]);

  return (
    <div>
      {Object.entries(formattedPreviousOrders).length ? (
        Object.entries(formattedPreviousOrders).map(
          ([orderId, { date, items }]) => {
            return (
              <OrderContainer key={orderId}>
                <OrderDate>
                  {new Date(date).toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hourCycle: "h24",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </OrderDate>
                <OrderId onClick={() => copyOrderId(orderId)}>
                  Order ID: {orderId}{" "}
                  {copyOrderIdSuccess === orderId ? (
                    <img
                      src="/checkmark.svg"
                      alt="copied to clipboard"
                      height="14px"
                    />
                  ) : (
                    <img
                      src="/clipboard-icon.svg"
                      height="14px"
                      alt="clipboard"
                    />
                  )}
                </OrderId>
                <Cart items={detailedItems} previousOrder={items} />
              </OrderContainer>
            );
          }
        )
      ) : (
        <div>Looks like there's nothing here :)</div>
      )}
    </div>
  );
};

export default PreviousOrders;
