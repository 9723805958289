import styled from "styled-components";
import TopBar from "./TopBar";
import TopBarMobile from "./TopBarMobile";
// import { DISCORD_URL } from "./config";

const Answer = styled.div`
  color: #666;
  margin-bottom: 36px;

  a {
    color: #e04778;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Container = styled.div`
  margin-bottom: 18px;
  padding: 18px 32px;
`;

const Question = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

const FAQ = ({ account }) => {
  return (
    <div>
      <TopBar account={account} />
      <TopBarMobile />
      <Container>
        <h1>$GUM FAQ</h1>
        {/* <Question>
          What do I do if I'm having trouble with the staking site?
        </Question>
        <Answer>
          Please check out this <a href="/how-to">guide</a> on using the staking
          site, and if that's not helpful, reach out in the{" "}
          <a
            id="error-link"
            href={DISCORD_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            Discord
          </a>
          .
        </Answer> */}
        <Question>
          Does my Bubblegum Kid or Bubblegum Puppy leave my wallet?
        </Question>
        <Answer>
          Yes, the NFT is sent to our{" "}
          <a
            href="https://etherscan.io/address/0xf48415039913dbdf17e337e681de922a9cb04010#code"
            rel="noopener noreferrer"
            target="_blank"
          >
            staking contract
          </a>{" "}
          where it is held until you decide to withdraw, which you can do at any
          time. While the NFT is staked you'll receive $GUM.
        </Answer>
        <Question>How much $GUM will I earn?</Question>
        <Answer>Each staked Kid or Puppy earns 1 $GUM per day.</Answer>
        <Question>What are the security protocols for staking?</Question>
        <Answer>
          We have had the contract audited by one of the most reputable
          developers in the space,{" "}
          <a
            href="https://twitter.com/0xQuit"
            rel="noopener noreferrer"
            target="_blank"
          >
            @0xQuit
          </a>
          . We are confident in this audit.
        </Answer>
        <Question>
          Will the $GUM token be soul-bound or can I transfer it to another
          wallet?
        </Question>
        <Answer>
          Since $GUM has no function outside of our ecosystem it can only be
          sent to the marketplace address to use.
        </Answer>
        <Question>Where do my $GUM tokens go?</Question>
        <Answer>
          When you claim $GUM tokens, they're sent to the wallet you deposited
          your BGK or BGP from.
        </Answer>
        <Question>
          How will the team update collection ownership to accurately display
          holders?
        </Question>
        <Answer>
          We'll update the OpenSea description to display an accurate ownership
          number on a weekly basis.
        </Answer>
        <Question>When can I claim $GUM?</Question>
        <Answer>
          You can claim gum whenever you want. There is no rush to claim.
        </Answer>
        <Question>What can I do with my $GUM?</Question>
        <Answer>
          $GUM can be used in exchange for items in our marketplace. These items
          will be on rotation. Some examples include VX characters, merch and
          special-edition items. More info on the marketplace will be coming
          soon.
        </Answer>
      </Container>
    </div>
  );
};

export default FAQ;
