import styled from "styled-components";
import TopBar from "./TopBar";
import TopBarMobile from "./TopBarMobile";
import { DISCORD_URL } from "./config";

const Text = styled.div`
  color: #666;
  line-height: 125%;
  margin-bottom: 36px;

  a {
    color: #e04778;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Container = styled.div`
  margin-bottom: 18px;
  padding: 18px 32px;

  img {
    margin-bottom: 36px;
  }
`;

const Heading = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

const HowTo = ({ account }) => {
  return (
    <div>
      <TopBar account={account} />
      <TopBarMobile />
      <Container>
        <h1>How to Stake</h1>
        <Text>
          There are two decisions you'll need to make when staking your
          Bubblegum Kids and Puppies: whether you want to stake them all, or
          just a few; and whether you want to approve the entire collections for
          use by the{" "}
          <a
            href="https://etherscan.io/address/0xf48415039913dbdf17e337e681de922a9cb04010#code"
            rel="noopener noreferrer"
            target="_blank"
          >
            staking contract
          </a>
          , or approve each NFT individually. Here we'll describe how to go
          about staking according to your preference:
        </Text>
        <Text>Skip to a section:</Text>
        <Text>
          <ul>
            <li>
              <a href="#approvals">Approvals</a>
            </li>
            <li>
              <a href="#stake-all">Stake All Kids and Puppies</a>
            </li>
            <li>
              <a href="#stake-individual">Stake Individual Kids and Puppies</a>
            </li>
            <li>
              <a href="#gone-wrong">Something's Gone Wrong!</a>
            </li>
            <li>
              <a href="#holdings-deposits">
                My Holdings or Deposits Look Wrong
              </a>
            </li>
          </ul>
        </Text>
        <Heading id="approvals">Approvals</Heading>
        <Text>
          If you decide to stake all your Kids and Puppies at once, you'll
          probably want to approve all NFTs in each collection. Otherwise you'll
          need to approve each NFT individually, which requires more
          transactions—and more gas.
        </Text>
        <Text>
          While approving entire collections is convenient, though, many
          collectors prefer to be more cautious and only approve individual
          tokens. Here's the difference: approving all Kids means that the{" "}
          <a
            href="https://etherscan.io/address/0xf48415039913dbdf17e337e681de922a9cb04010#code"
            rel="noopener noreferrer"
            target="_blank"
          >
            staking contract
          </a>{" "}
          has access to <em>all of your Kids.</em> Approving only Kid #205, for
          example, means the contract has access to <em>only that one Kid</em>.
          You can learn more about approvals{" "}
          <a
            href="https://support.opensea.io/hc/en-us/articles/4416083190291-How-can-I-revoke-token-approvals-and-permissions-on-Ethereum-"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{" "}
          and{" "}
          <a
            href="https://ethereum.stackexchange.com/questions/134994/what-is-approveforall-and-what-else-are-we-approving-exactly"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Text>
        <Text>
          You can adjust your approvals at any time by clicking the avatar icon
          near the top of the page, next to your ethereum address:
        </Text>
        <img
          src="avatar-settings.png"
          alt="icon to adjust approvals"
          width="500px"
        />
        <Text>
          Clicking that icon will bring up a message allowing you to approve
          (or, if you already have, unapprove) all tokens for each collection:
        </Text>
        <img
          src="settings-modal.png"
          alt="message to adjust approvals"
          width="500px"
        />
        <Text>
          When you click "Approve All" for one or both collections, your wallet
          will prompt you to sign a transaction like this, which will cost gas.
          (The screenshot is from Metamask; other wallets will show slightly
          different messages.) The angry-looking warning at the bottom is
          normal. It's also accurate, so make sure you're comfortable doing what
          it says. On the other hand, remember you can always unapprove later.
        </Text>
        <img
          src="metamask-approve-for-all.png"
          alt="metamask approval for all transaction"
          width="300px"
        />
        <Text>
          An approval transaction should cost a few dollars at most. If you're
          getting a high gas estimate, the site is probably asking for an
          unnecessary approval. Please{" "}
          <a
            id="error-link"
            href={DISCORD_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            send us a screenshot
          </a>{" "}
          so we can address the bug. But you can probably resolve the issue
          simply by refreshing the page.
        </Text>
        <Text>
          If you don't want to approve all your Kids or Puppies at once, that's
          fine! It just means that your wallet will prompt you to approve each
          individual NFT, which will take extra time and cost extra gas. Here's
          what a prompt to approve a single NFT will look like in your wallet
          (notice the NFT id, #0):
        </Text>
        <img
          src="approve-single-nft.png"
          alt="metamask approval for single token transaction"
          width="300px"
        />
        <Heading id="stake-all">Stake All Kids and Puppies</Heading>
        <Text>
          If you want to stake all your Kids and Puppies at once, hit the "Send
          All to Camp" button at the top-right of the page:
        </Text>
        <img
          src="send-all-to-camp.png"
          alt="send all to camp button"
          width="500px"
        />
        <Text>
          If there's a collection for which you haven't approved all tokens, a
          message will come up asking you if you'd like to do so. There's no
          need, but if you decline, you'll need to approve each individual NFT (
          <a href="#approvals">see "Approvals"</a>):
        </Text>
        <img
          src="stake-all-approve-prompt.png"
          alt="prompt to approve collection before staking all"
          width="500px"
        />
        <Text>
          When the staking transaction goes through, you'll notice that the
          buttons underneath your Kids and Puppies no longer say "Send to Camp."
          Now they say "Bring Home": congratulations, your kids are off at camp
          earning $GUM!
        </Text>
        <img
          src="staked-kids.png"
          alt="image showing kids that have been successfully staked"
          width="500px"
        />
        <Heading id="stake-individual">
          Stake Individual Kids and Puppies
        </Heading>
        <Text>
          Maybe you don't want to stake all your Kids and Puppies right now.
          That's okay, you can select just one or a couple to send to camp.
        </Text>
        <Text>
          Below each unstaked Kid and Puppy, you'll see a button that says "Send
          to Camp":
        </Text>
        <img src="send-to-camp.png" alt="send to camp buttons" width="500px" />
        <Text>
          Click that, and the Kids or Puppy will be added to the "batch" to be
          sent off. The button should now say "Ready for Camp" (clicking again
          will remove that item from the batch):
        </Text>
        <img
          src="ready-for-camp.png"
          alt="a kid is ready for camp"
          width="500px"
        />
        <Text>
          Add as many as you want, and when you're ready, click the "Submit"
          button in the top-right:
        </Text>
        <img src="changes-ready-to-go.png" alt="submit button" width="500px" />
        <Text>
          A message will pop up asking you to confirm your selections. If
          everything looks good, hit "Submit" to send the campers off:
        </Text>
        <img src="confirmation-modal.png" alt="submit button" width="500px" />
        <Text>
          If you haven't approved all tokens in the relevant collections, you'll
          be prompted to approve each token individually (
          <a href="#approvals">see "Approvals."</a>)
        </Text>
        <Text>
          When the staking transaction goes through, you'll notice that the
          buttons underneath your Kids and Puppies no longer say "Send to Camp."
          Now they say "Bring Home": congratulations, your kids are off at camp
          earning $GUM!
        </Text>
        <img
          src="staked-kids.png"
          alt="image showing kids that have been successfully staked"
          width="500px"
        />
        <Heading id="gone-wrong">Something's Gone Wrong!</Heading>
        <Text>
          We're so sorry if you're having trouble using the site. We'd ask that
          you please take a screenshot if something on the page looks strange.
          If a transaction has failed, it's very helpful if you share your
          address, or even better, the transaction hash. The more information
          you can give us, the easier it is to find and fix the issue. Then head
          to the{" "}
          <a
            id="error-link"
            href={DISCORD_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            Discord
          </a>{" "}
          so we can help you out!
        </Text>
        <Text>
          If your wallet is giving you an extremely high gas estimate,
          particularly for an approval transaction (which should almost never be
          more than a couple dollars), it probably means that the transaction
          will fail. You shouldn't send that transaction, but you should{" "}
          <em>definitely</em> take a screenshot of your wallet's interface and
          share it with us, if you're comfortable doing so.
        </Text>
        <Text>
          Often, refreshing the page and re-connecting your wallet will fix the
          issue. If you're seeing something strange, it's likely a bug, and we'd
          like to know about it so we can fix it. But we also want you to be
          able to use the site. So if something's wrong, try refreshing.
        </Text>
        <Heading id="holdings-deposits">
          My Holdings or Deposits Look Wrong
        </Heading>
        <Text>
          <a
            id="error-link"
            href={DISCORD_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            Let us know
          </a>{" "}
          if the site is displaying holdings or deposit data that looks
          incorrect to you: it's showing Kids or Puppies you haven't staked as
          being staked; it's not showing Kids or Puppies you own; things like
          that.
        </Text>
        <Text>
          To check that the information on the site is correct, you can use
          tools like OpenSea and Etherscan. Any Kid or Puppy shown on your
          profile at OpenSea should appear as an unstaked token at the BGK
          staking site. If not, your NFTs are safe, but{" "}
          <a
            id="error-link"
            href={DISCORD_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            let us know
          </a>{" "}
          so we can address it!
        </Text>
        <Text>
          If you think you've staked a Kid or Puppy but it's showing up as
          unstaked—or not showing up at all—you can find out whether it's
          deposited in the staking contract by going to OpenSea. First, check
          for the token in your wallet. If it's there, it hasn't been been
          staked successfully; staked NFTs are held by the{" "}
          <a
            href="https://etherscan.io/address/0xf48415039913dbdf17e337e681de922a9cb04010#code"
            rel="noopener noreferrer"
            target="_blank"
          >
            staking contract
          </a>
          .
        </Text>
        <Text>
          Assuming it's not in your wallet, first you'll need to find the
          token's id. This won't be the same as the number in the name of the
          token, eg "Bubblegum Kid #123." If you head to that Kid's OpenSea{" "}
          <a
            href="https://opensea.io/assets/ethereum/0xa5ae87b40076745895bb7387011ca8de5fde37e0/7665"
            target="_blank"
            rel="noopener noreferrer"
          >
            page
          </a>{" "}
          and look at the "Details" section below the image, that's where you'll
          find the token id, in this case 7665:
        </Text>
        <img
          src="check-id-on-opensea.png"
          alt="check nft id on opensea"
          width="500px"
        />
        <Text>
          Next head to the{" "}
          <a
            href="https://etherscan.io/address/0xf48415039913dbdf17e337e681de922a9cb04010#readContract"
            rel="noopener noreferrer"
            target="_blank"
          >
            staking contract's
          </a>{" "}
          page on Etherscan. Under "Read Contract" you'll see field #6,
          "depositsOf". Put your ethereum address in the "account" input and hit
          "Query". You should see a list of ids, which should include the token
          id you found on OpenSea (ie, 7665):
        </Text>
        <img
          src="check-deposits-etherscan.png"
          alt="check nft id on opensea"
          width="500px"
        />
        <Text>
          If you don't see it there, the NFT hasn't been staked. Please reach
          out to us if there's any uncertainty!
        </Text>
      </Container>
    </div>
  );
};

export default HowTo;
