import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import kidsAbi from "./abis/kidsAbi.json";
import pupsAbi from "./abis/pupsAbi.json";
import stakingAbi from "./abis/stakingAbi.json";
import gumAbi from "./abis/gumAbi.json";
import vxKidsAbi from "./abis/vxKidsAbi.json";
import { INFURA_ID } from "./config";

const _getGumBalance = async ({ account, gumContract, setGumBalance }) => {
  try {
    const _gumBalance = await gumContract.methods.balanceOf(account).call();
    setGumBalance(Number(_gumBalance) / 10 ** 18);
  } catch (err) {
    console.error(err);
  }
};

const getItems = ({
  filter,
  collection,
  kidsDeposits,
  kidsIds,
  kidsUnstaked,
  pupsDeposits,
  pupsIds,
  pupsUnstaked,
}) => {
  if (collection === "bgp" && filter === "At Home") {
    return pupsUnstaked.map((id) => ({ id, collection }));
  }
  if (collection === "bgk" && filter === "At Home") {
    return kidsUnstaked.map((id) => ({ id, collection }));
  }
  if (collection === "bgp" && filter === "At Camp") {
    return pupsDeposits.map((id) => ({ id, collection }));
  }
  if (collection === "bgk" && filter === "At Camp") {
    return kidsDeposits.map((id) => ({ id, collection }));
  }
  if (collection === "bgp" && filter === "All") {
    return pupsIds.map((id) => ({ id, collection }));
  }
  return kidsIds.map((id) => ({ id, collection: "bgk" }));
};

const _connectMetaMask = async ({
  setAccount,
  setKidsContract,
  setPupsContract,
  setStakingContract,
  setGumContract,
  kidsContractAddress,
  pupsContractAddress,
  stakingContractAddress,
  gumContractAddress,
  setWeb3,
  vxKidsContractAddress,
  setVxKidsContract,
}) => {
  const { ethereum } = window || {};
  if (!ethereum) {
    console.error("Ethereum is not defined.");
    // setError("Sorry, something went wrong. Please check your wallet.");
  }
  const metamaskIsInstalled = ethereum.isMetaMask;
  if (metamaskIsInstalled) {
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      ethereum.on("accountsChanged", (accounts) => {
        setAccount(accounts[0]);
      });
      const [_account = ""] = accounts || [];
      setAccount(_account);
      Web3EthContract.setProvider(ethereum);
      const _web3 = new Web3(ethereum);
      setWeb3(_web3);
      const _kidsContract = new Web3EthContract(kidsAbi, kidsContractAddress);
      const _pupsContract = new Web3EthContract(pupsAbi, pupsContractAddress);
      const _stakingContract = new Web3EthContract(
        stakingAbi,
        stakingContractAddress
      );
      const _gumContract = new Web3EthContract(gumAbi, gumContractAddress);
      const _vxKidsContract = new Web3EthContract(
        vxKidsAbi,
        vxKidsContractAddress
      );
      setKidsContract(_kidsContract);
      setPupsContract(_pupsContract);
      setStakingContract(_stakingContract);
      setGumContract(_gumContract);
      setVxKidsContract(_vxKidsContract);
    } catch (err) {
      console.error(err);
      // setError("Sorry, something went wrong. Please check your wallet.");
    }
  } else {
    // setError("Please install MetaMask.");
  }
};

const _connectWalletConnect = async ({
  setAccount,
  setKidsContract,
  setPupsContract,
  setStakingContract,
  setGumContract,
  kidsContractAddress,
  pupsContractAddress,
  stakingContractAddress,
  gumContractAddress,
  setWeb3,
  vxKidsContractAddress,
  setVxKidsContract,
}) => {
  let provider;
  try {
    provider = new WalletConnectProvider({ infuraId: INFURA_ID });
    await provider.enable();
  } catch (err) {
    console.error(err);
    // setError("Sorry, something went wrong.");
  }
  provider.on("accountsChanged", (accounts) => {
    // setError("");
    setAccount(accounts[0]);
  });
  Web3EthContract.setProvider(provider);
  const _web3 = new Web3(provider);
  setWeb3(_web3);
  const _kidsContract = new Web3EthContract(kidsAbi, kidsContractAddress);
  const _pupsContract = new Web3EthContract(pupsAbi, pupsContractAddress);
  const _stakingContract = new Web3EthContract(
    stakingAbi,
    stakingContractAddress
  );
  const _gumContract = new Web3EthContract(gumAbi, gumContractAddress);
  const _vxKidsContract = new Web3EthContract(vxKidsAbi, vxKidsContractAddress);
  setKidsContract(_kidsContract);
  setPupsContract(_pupsContract);
  setStakingContract(_stakingContract);
  setGumContract(_gumContract);
  setVxKidsContract(_vxKidsContract);
  try {
    const accounts = await _web3.eth.getAccounts();
    setAccount(accounts[0]);
  } catch (err) {
    console.error(err);
    // setError("Sorry, something went wrong. Please check your wallet.");
  }
};

const _connectCoinbaseWallet = async ({
  setAccount,
  setKidsContract,
  setPupsContract,
  setStakingContract,
  setGumContract,
  kidsContractAddress,
  pupsContractAddress,
  stakingContractAddress,
  gumContractAddress,
  setWeb3,
  vxKidsContractAddress,
  setVxKidsContract,
}) => {
  const coinbaseWallet = new CoinbaseWalletSDK({
    appName: "Bubblegum Kids Comic",
    appLogoUrl: "/bgk-logo.png",
    darkMode: false,
  });
  const ethereum = coinbaseWallet.makeWeb3Provider(
    `https://mainnet.infura.io/v3/${INFURA_ID}`,
    1
  );
  Web3EthContract.setProvider(ethereum);
  const _web3 = new Web3(ethereum);
  setWeb3(_web3);
  const _kidsContract = new Web3EthContract(kidsAbi, kidsContractAddress);
  const _pupsContract = new Web3EthContract(pupsAbi, pupsContractAddress);
  const _stakingContract = new Web3EthContract(
    stakingAbi,
    stakingContractAddress
  );
  const _gumContract = new Web3EthContract(gumAbi, gumContractAddress);
  const _vxKidsContract = new Web3EthContract(vxKidsAbi, vxKidsContractAddress);
  setKidsContract(_kidsContract);
  setPupsContract(_pupsContract);
  setStakingContract(_stakingContract);
  setGumContract(_gumContract);
  setVxKidsContract(_vxKidsContract);
  try {
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    setAccount(accounts[0]);
  } catch (err) {
    console.error(err);
    // setError("Sorry, something went wrong. Please check your wallet.");
  }
};

const checkForParentElementById = (element, parentId) => {
  if (element.id === parentId) return true;
  const { parentElement } = element || {};
  if (!parentElement || parentElement.id === "root") return false;
  if (parentElement.id === parentId) return true;
  return checkForParentElementById(parentElement, parentId);
};

const getQuantityRemaining = ({
  id,
  previousOrderCounts = {},
  totalQuantity,
}) => {
  return totalQuantity
    ? totalQuantity - (previousOrderCounts[id] || 0)
    : Infinity;
};

const getGumCartTotalExItem = ({
  gumCartTotal,
  gumPrice,
  preexistingCount,
}) => {
  return gumCartTotal - gumPrice * preexistingCount;
};

const getIsAtPerAddressLimit = ({
  accountPreviousOrderCounts = {},
  count,
  id,
  limitPerAddress,
}) => {
  return limitPerAddress
    ? limitPerAddress - count - (accountPreviousOrderCounts[id] || 0) < 1
    : false;
};

const getIsAtGumLimit = ({
  count,
  gumBalance,
  gumPrice,
  gumCartTotal,
  preexistingCount,
}) => {
  if (preexistingCount) {
    return (
      gumPrice * (count + 1) +
        getGumCartTotalExItem({
          gumCartTotal,
          gumPrice,
          preexistingCount,
        }) >
      gumBalance
    );
  }
  return gumPrice + gumCartTotal > gumBalance;
};

const getIsOverGumLimit = ({
  count,
  gumBalance,
  gumCartTotal,
  gumPrice,
  preexistingCount,
}) => {
  return (
    gumPrice * count +
      getGumCartTotalExItem({
        gumCartTotal,
        gumPrice,
        preexistingCount,
      }) >
      gumBalance ||
    (count === 0 && getIsAtGumLimit({ gumBalance, gumPrice, gumCartTotal }))
  );
};

const getIsAtTotalRemainingLimit = ({
  count,
  id,
  previousOrderCounts,
  totalQuantity,
}) => {
  return (
    getQuantityRemaining({ id, previousOrderCounts, totalQuantity }) <= count
  );
};

export {
  _connectMetaMask,
  _connectWalletConnect,
  _connectCoinbaseWallet,
  _getGumBalance,
  checkForParentElementById,
  getIsAtGumLimit,
  getIsAtPerAddressLimit,
  getIsAtTotalRemainingLimit,
  getIsOverGumLimit,
  getQuantityRemaining,
  getItems,
};
