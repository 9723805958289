import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { checkForParentElementById } from "./utils";
import Cart from "./Cart";
import { ClearButton, SubmitButton } from "./styles";

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 975px) {
    justify-content: end;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 12px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;
`;

const Total = styled.div`
  color: #fff;
  text-shadow: 2px 2px #e04778;
  display: flex;
  font-size: 24px;
  justify-content: end;
  margin-bottom: 12px;
`;

const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 160px auto;
  grid-column-gap: 10px;
  margin-bottom: 12px;
  align-items: center;
  line-height: 115%;

  div {
    color: #e04778;
  }
`;

const Warning = styled.div`
  background-color: #fff;
  border: 2px solid #e04778;
  border-radius: 8px;
  color: #e04778;
  font-size: 18px;
  margin: 0 auto 18px;
  padding: 4px 16px;
  text-align: center;
  width: fit-content;
`;

const ConfirmOrderModal = ({
  account,
  addressLineOne,
  addressLineTwo,
  cart,
  city,
  closeModal,
  country,
  deliveryAccount,
  email,
  fullName,
  goBack,
  gumCartTotal,
  handleConfirmOrderSubmit,
  items,
  phoneNumber,
  postalCode,
  province,
}) => {
  const [copyAccountSuccess, setCopyAccountSuccess] = useState(false);
  const [copyDeliveryAccountSuccess, setCopyDeliveryAccountSuccess] =
    useState(false);
  const [showFullAccount, setShowFullAccount] = useState(false);
  const [showFullDeliveryAccount, setShowFullDeliveryAccount] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", (event) => {
      event.stopPropagation();
      const targetIsModalChild = checkForParentElementById(
        event.target,
        "confirm-order-modal"
      );
      const targetIsProcessOrderModalChild = checkForParentElementById(
        event.target,
        "process-order-modal"
      );
      const shouldClose = !(
        targetIsModalChild || targetIsProcessOrderModalChild
      );
      if (shouldClose) {
        closeModal();
      }
    });
  }, []);

  const handleSubmit = useCallback(() => {
    handleConfirmOrderSubmit();
    closeModal();
  }, [closeModal, handleConfirmOrderSubmit]);

  const copyAccount = useCallback(async () => {
    setTimeout(() => {
      setCopyAccountSuccess(false);
    }, 3000);
    try {
      await navigator.clipboard.writeText(account);
      setCopyAccountSuccess(true);
    } catch {}
  }, [account, setCopyAccountSuccess]);

  const copyDeliveryAccount = useCallback(async () => {
    setTimeout(() => {
      setCopyDeliveryAccountSuccess(false);
    }, 3000);
    try {
      await navigator.clipboard.writeText(deliveryAccount);
      setCopyDeliveryAccountSuccess(true);
    } catch {}
  }, [deliveryAccount, setCopyDeliveryAccountSuccess]);

  return (
    <OuterContainer id="confirm-order-modal">
      <Container>
        <CloseButtonContainer onClick={closeModal}>
          <CloseButton>×</CloseButton>
        </CloseButtonContainer>
        <Heading>Confirm Order</Heading>
        <InnerContainer>
          <InfoItem>
            Full name: <div>{fullName}</div>
          </InfoItem>
          <InfoItem>
            Email: <div>{email}</div>
          </InfoItem>
          <InfoItem>
            Phone number: <div>{phoneNumber}</div>
          </InfoItem>
          {deliveryAccount && deliveryAccount !== account && (
            <InfoItem>
              Eth address (delivery):
              <div style={{ display: "flex", gap: "8px" }}>
                <div
                  onClick={() =>
                    showFullDeliveryAccount
                      ? {}
                      : setShowFullDeliveryAccount(true)
                  }
                  style={{
                    cursor: showFullDeliveryAccount ? "text" : "pointer",
                  }}
                >
                  {showFullDeliveryAccount
                    ? deliveryAccount
                    : `${deliveryAccount.slice(0, 5)}...${deliveryAccount.slice(
                        deliveryAccount.length - 4
                      )}`}
                </div>
                {copyDeliveryAccountSuccess ? (
                  <img src="/checkmark.svg" alt="copied to clipboard" />
                ) : (
                  <img
                    src="/clipboard-icon.svg"
                    alt="clipboard"
                    onClick={copyDeliveryAccount}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </InfoItem>
          )}
          <InfoItem>
            Eth address
            {deliveryAccount && deliveryAccount !== account ? " (payment)" : ""}
            :
            <div style={{ display: "flex", gap: "8px" }}>
              <div
                onClick={() =>
                  showFullAccount ? {} : setShowFullAccount(true)
                }
                style={{ cursor: showFullAccount ? "text" : "pointer" }}
              >
                {showFullAccount
                  ? account
                  : `${account.slice(0, 5)}...${account.slice(
                      account.length - 4
                    )}`}
              </div>
              {copyAccountSuccess ? (
                <img src="/checkmark.svg" alt="copied to clipboard" />
              ) : (
                <img
                  src="/clipboard-icon.svg"
                  alt="clipboard"
                  onClick={copyAccount}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
          </InfoItem>
          <InfoItem>
            Physical address (line 1):
            <div>{addressLineOne}</div>
          </InfoItem>
          <InfoItem>
            Physical address (line 2):
            <div>{addressLineTwo}</div>
          </InfoItem>
          <InfoItem>
            City:
            <div>{city}</div>
          </InfoItem>
          <InfoItem>
            Country:
            <div>{country}</div>
          </InfoItem>
          <InfoItem>
            Zip/postal code:
            <div>{postalCode}</div>
          </InfoItem>
          <InfoItem>
            State/province:
            <div>{province}</div>
          </InfoItem>
          <Cart cart={cart} handleCartItemClick={() => {}} items={items} />
        </InnerContainer>
        <Total>Total: {gumCartTotal} $GUM</Total>
        <Warning>
          We won't be able to cancel, update or refund orders once they're
          placed, so please make sure all your information is correct
        </Warning>
        <ButtonsContainer>
          <ClearButton onClick={goBack}>Back</ClearButton>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default ConfirmOrderModal;
