import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { ClearButton, SubmitButton } from "./styles";
import { checkForParentElementById } from "./utils";
import Cart from "./Cart";

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 975px) {
    justify-content: end;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 12px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;
`;

const Total = styled.div`
  color: #fff;
  text-shadow: 2px 2px #e04778;
  display: flex;
  font-size: 24px;
  justify-content: end;
  margin-bottom: 18px;
`;

const SubmitWarning = styled.div`
  color: #e04778;
  width: 100%;
  text-align: center;
  margin-top: 8px;
`;

const CartModal = ({
  accountPreviousOrderCounts,
  cart,
  closeModal,
  gumBalance,
  gumCartTotal,
  handleCartItemClick,
  handleCartSubmit,
  handleCartUpdate,
  items,
  previousOrderCounts,
}) => {
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const targetIsModalChild = checkForParentElementById(
        event.target,
        "cart-modal"
      );
      const targetIsCartSectionChild = checkForParentElementById(
        event.target,
        "cart-section"
      );
      const targetIsDetailedItemBackButtonChild = checkForParentElementById(
        event.target,
        "detailed-item-modal-button"
      );
      const shouldClose = !(
        targetIsModalChild ||
        targetIsCartSectionChild ||
        targetIsDetailedItemBackButtonChild
      );
      if (shouldClose) {
        closeModal();
      }
    },
    [closeModal]
  );

  const handleSubmit = useCallback(() => {
    handleCartSubmit();
    closeModal();
  }, [closeModal, handleCartSubmit]);

  const needsSizeSelection = useMemo(() => {
    for (const { id, size } of cart) {
      const { sizes = [] } = items.find(({ _id }) => id === _id) || {};
      if (sizes.length && !size) return true;
    }
    return false;
  }, [cart, items]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <OuterContainer id="cart-modal">
      <Container>
        <CloseButtonContainer onClick={closeModal}>
          <CloseButton>×</CloseButton>
        </CloseButtonContainer>
        <Heading>Cart</Heading>
        <InnerContainer>
          {cart?.length ? (
            <Cart
              accountPreviousOrderCounts={accountPreviousOrderCounts}
              cart={cart}
              gumBalance={gumBalance}
              gumCartTotal={gumCartTotal}
              handleCartUpdate={handleCartUpdate}
              handleCartItemClick={handleCartItemClick}
              items={items}
              previousOrderCounts={previousOrderCounts}
            />
          ) : (
            <div>Looks like there's nothing here :)</div>
          )}
        </InnerContainer>
        {needsSizeSelection && (
          <SubmitWarning>
            You need to select a size for an item above before placing your
            order
          </SubmitWarning>
        )}
        <Total>
          <div>Total: {gumCartTotal} $GUM</div>
        </Total>
        <ButtonsContainer>
          <ClearButton onClick={closeModal} width="190px">
            Keep Shopping
          </ClearButton>
          <SubmitButton
            disabled={
              !cart?.length || needsSizeSelection || gumCartTotal > gumBalance
            }
            onClick={handleSubmit}
          >
            Place Order
          </SubmitButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default CartModal;
