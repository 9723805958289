import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 18px;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
`;

const Header = styled.h1`
  font-size: 72px;
  line-height: 115%;
  margin-top: 12px;
  margin-bottom: 0;
`;

const TxInProgress = styled.div`
  display: flex;
  font-size: 12x;
  line-height: 115%;
  gap: 12px;
  align-items: center;
`;

const MarketplaceHeading = ({ purchaseInProgress }) => {
  return (
    <Container>
      <Header>Marketplace</Header>
      {purchaseInProgress && (
        <TxInProgress>
          <div>Transaction in progress! Check your wallet</div>
          <img src="/loading.gif" alt="loading" width="16px" height="16px" />
        </TxInProgress>
      )}
    </Container>
  );
};

export default MarketplaceHeading;
