import styled from "styled-components";
import { GUM_COST_PER_MINT } from "./config";

const Account = styled.a`
  font-size: 24px;
  text-align: left;
  text-shadow: 2px 2px #e04778;
  margin-left: 18px;
  color: #fff;
  text-decoration: none;
`;

const AccountContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 750px) {
    grid-column: 1/-1;
  }
`;

const Container = styled.div`
  border: 3px solid #e04778;
  border-radius: 20px;
  margin-bottom: 32px;
  padding: 4px;
  width: calc(100% - 8px);
`;

const StatsDivider = styled.div`
  background-color: #fff;
  border-radius: 3px;
  width: 100%;

  @media only screen and (max-width: 750px) {
    ${({ hideMobile }) => hideMobile && "display: none;"}
  }
`;

const StatsContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

const StatsHeader = styled.h3`
  color: #e04778;
  font-size: 24px;
  margin: 0;
  text-align: left;
`;

const StatsValue = styled.div`
  align-items: center;
  display: flex;
  font-size: 42px;
  text-align: left;
  text-shadow: 2px 2px #e04778;

  img {
    margin-left: 8px;
    margin-top: 3px;
  }
`;

const UserStatsInner = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  grid-template-rows: 1fr auto;
  grid-row-gap: 24px;
  padding: 18px 24px;
  width: calc(100% - 48px);

  @media only screen and (min-width: 750px) {
    grid-template-columns: 1fr 2px 1fr 2px 1fr;
    grid-template-rows: 1fr;
  }
`;

const VxUserPanel = ({ account, gumBalance, idsToMint }) => {
  return (
    <Container>
      <UserStatsInner>
        <StatsContainer>
          <StatsHeader>Gum Balance</StatsHeader>
          <StatsValue>{gumBalance}</StatsValue>
        </StatsContainer>
        <StatsDivider />
        <StatsContainer>
          <StatsHeader>Gum Cost to Mint</StatsHeader>
          <StatsValue>{idsToMint.length * GUM_COST_PER_MINT}</StatsValue>
        </StatsContainer>
        <StatsDivider hideMobile={true} />
        <AccountContainer>
          <Account
            href={`https://etherscan.io/address/${account}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {account.slice(0, 5)}...
            {account.slice(account.length - 4)}
          </Account>
        </AccountContainer>
      </UserStatsInner>
    </Container>
  );
};

export default VxUserPanel;
