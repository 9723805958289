import { KIDS_OFFSET, PUPS_OFFSET } from "./config";
import styled from "styled-components";
import { Button, StagedButton } from "./styles";
import { getItems } from "./utils";

const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ disabled }) => (disabled ? "#ccc" : "#f2a7bf")};
  ${({ disabled }) => !disabled && "cursor: pointer;"}
  font-size: 32px;
  font-weight: 600;
  height: 32px;

  &:hover {
    color: ${({ disabled }) => (disabled ? "#ccc" : "#e04778")};
  }
`;

const ArrowButtonContainer = styled.div`
  align-items: center;
  display: flex;
`;

const BreakpointCop = styled.div`
  display: none;

  @media only screen and (min-width: 975px) {
    display: inherit;
  }

  @media only screen and (min-width: 1250px) {
    display: none;
  }
`;

const Carousel = styled.div`
  display: grid;
  grid-column-gap: calc((100% - 3 * 250px) / 2);
  grid-template-columns: minmax(auto, 250px) minmax(auto, 250px) minmax(
      auto,
      250px
    );
  height: 401px;
`;

const CarouselContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto 1fr auto;
  height: auto;
  margin: 0 auto 32px;
`;

const Circle = styled.div`
  background-color: ${({ isSelected }) =>
    isSelected ? "#e04778;" : "#f2a7bf"};
  border-radius: 100px;
  cursor: pointer;
  height: 12px;
  width: 12px;
`;

const ItemContainer = styled.div`
  width: 100%;
  border-radius: 6px;
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
  }
`;

const ItemHeader = styled.h2`
  color: #e04778;
  font-size: 24px;
  margin-bottom: 12px;
`;

const DaysElapsed = styled.h2`
  color: #e04778;
  font-size: 24px;
  margin-bottom: 16px;
  margin-top: 0;
`;

const PageCounter = styled.div`
  display: flex;
  gap: 14px;
  margin: 0 auto 48px;
  width: min-content;
`;

const CarouselTablet = ({
  collection,
  daysStaked,
  disabledInteractions,
  filter,
  handleBackButtonClick,
  handleForwardButtonClick,
  handleStageForDepositClick,
  handleStageForWithdrawalClick,
  handleUnstageForDepositClick,
  handleUnstageForWithdrawalClick,
  imgSrcs,
  kidsContractAddress,
  kidsDepositInProgress,
  kidsDeposits,
  kidsIds,
  kidsStagedForDeposit,
  kidsStagedForWithdrawal,
  kidsUnstaked,
  kidsWithdrawalInProgress,
  page,
  pupsContractAddress,
  pupsDeposits,
  pupsDepositInProgress,
  pupsIds,
  pupsStagedForDeposit,
  pupsStagedForWithdrawal,
  pupsUnstaked,
  pupsWithdrawalInProgress,
  setPage,
  totalCount,
}) => {
  const items = getItems({
    filter,
    collection,
    kidsDeposits,
    kidsIds,
    kidsUnstaked,
    pupsDeposits,
    pupsIds,
    pupsUnstaked,
  }).slice(page * 3, page * 3 + 3);

  return (
    <BreakpointCop>
      <CarouselContainer>
        <ArrowButtonContainer>
          <ArrowButton disabled={page === 0} onClick={handleBackButtonClick}>
            {"<"}
          </ArrowButton>
        </ArrowButtonContainer>
        <Carousel>
          {items.map(({ id, collection }) => {
            const offset = collection === "bgk" ? KIDS_OFFSET : PUPS_OFFSET;
            const offsetId = (id + offset) % 10_000;
            const header = `${
              collection === "bgk" ? "Kid " : "Puppy "
            }#${offsetId}`;
            const collectionAddress =
              collection === "bgk" ? kidsContractAddress : pupsContractAddress;
            const imgSrc = imgSrcs?.[collection]?.[offsetId] || undefined;
            const isStaked =
              collection === "bgk"
                ? kidsDeposits.includes(id)
                : pupsDeposits.includes(id);
            const isStagedForDeposit =
              collection === "bgk"
                ? kidsStagedForDeposit.includes(id)
                : pupsStagedForDeposit.includes(id);
            const isStagedForWithdrawal =
              collection === "bgk"
                ? kidsStagedForWithdrawal.includes(id)
                : pupsStagedForWithdrawal.includes(id);
            const isDepositInProgress =
              collection === "bgk"
                ? kidsDepositInProgress.includes(id)
                : pupsDepositInProgress.includes(id);
            const isWithdrawalInProgress =
              collection === "bgk"
                ? kidsWithdrawalInProgress.includes(id)
                : pupsWithdrawalInProgress.includes(id);
            const stakedFor = daysStaked[collection]?.[id];
            return (
              <div key={`${id}-${collection}`}>
                {items.length === 1 && <div />}
                <ItemContainer key={`${collection}-${id}`}>
                  <ImageContainer>
                    {imgSrc && <img alt="nft" src={imgSrc} />}
                  </ImageContainer>
                  <ItemHeader>{header}</ItemHeader>
                  {stakedFor > -1 && (
                    <DaysElapsed>At camp for {stakedFor} days</DaysElapsed>
                  )}
                  {isStaked && !isStagedForWithdrawal ? (
                    <Button
                      title={
                        disabledInteractions ? "Transaction in progress!" : ""
                      }
                      disabled={disabledInteractions}
                      onClick={
                        disabledInteractions
                          ? () => {}
                          : () => {
                              handleStageForWithdrawalClick({
                                id,
                                collectionAddress,
                              });
                            }
                      }
                    >
                      Bring Home
                    </Button>
                  ) : isStaked &&
                    isStagedForWithdrawal &&
                    !isWithdrawalInProgress &&
                    !isWithdrawalInProgress ? (
                    <StagedButton
                      title={
                        disabledInteractions ? "Transaction in progress!" : ""
                      }
                      disabled={disabledInteractions}
                      onClick={
                        disabledInteractions
                          ? () => {}
                          : () => {
                              handleUnstageForWithdrawalClick({
                                id,
                                collectionAddress,
                              });
                            }
                      }
                    >
                      Ready for Home
                    </StagedButton>
                  ) : !isStaked &&
                    !isStagedForDeposit &&
                    !isDepositInProgress ? (
                    <Button
                      title={
                        disabledInteractions ? "Transaction in progress!" : ""
                      }
                      disabled={disabledInteractions}
                      onClick={
                        disabledInteractions
                          ? () => {}
                          : () => {
                              handleStageForDepositClick({
                                id,
                                collectionAddress,
                              });
                            }
                      }
                    >
                      Send to Camp
                    </Button>
                  ) : !isStaked &&
                    isStagedForDeposit &&
                    !isDepositInProgress ? (
                    <StagedButton
                      title={
                        disabledInteractions ? "Transaction in progress!" : ""
                      }
                      disabled={disabledInteractions}
                      onClick={
                        disabledInteractions
                          ? () => {}
                          : () => {
                              handleUnstageForDepositClick({
                                id,
                                collectionAddress,
                              });
                            }
                      }
                    >
                      Ready for Camp
                    </StagedButton>
                  ) : isDepositInProgress ? (
                    <Button
                      title="Transaction in progress!"
                      onClick={() => {}}
                      disabled
                    >
                      Headed to Camp
                    </Button>
                  ) : isWithdrawalInProgress ? (
                    <Button
                      title="Transaction in progress!"
                      onClick={() => {}}
                      disabled
                    >
                      Headed Home
                    </Button>
                  ) : null}
                </ItemContainer>
              </div>
            );
          })}
        </Carousel>
        <ArrowButtonContainer>
          <ArrowButton
            disabled={
              page ===
              Math.floor(totalCount / 3) - (totalCount % 3 === 0 ? 1 : 0)
            }
            onClick={handleForwardButtonClick}
          >
            {">"}
          </ArrowButton>
        </ArrowButtonContainer>
      </CarouselContainer>
      <PageCounter>
        {Array.apply(
          null,
          Array(Math.floor(totalCount / 3) + (totalCount % 3 > 0 ? 1 : 0))
        ).map((_, index) => (
          <Circle
            key={index}
            isSelected={page === index}
            onClick={() => setPage(index)}
          />
        ))}
      </PageCounter>
    </BreakpointCop>
  );
};

export default CarouselTablet;
