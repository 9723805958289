import React, { useCallback, useEffect, useState } from "react";
import TopBar from "./TopBar";
import TopBarMobile from "./TopBarMobile";
import styled from "styled-components";
import ConnectWalletModal from "./ConnectWalletModal";
import { Button, LinkButton } from "./styles";
import StakingCounter from "./StakingCounter";
import { CONTRACT_ADDRESSES, API_URL } from "./config";
import ErrorModal from "./ErrorModal";
import { Link, useNavigate } from "react-router-dom";

const { kidsContractAddress, pupsContractAddress } = CONTRACT_ADDRESSES;

const BCMessage = styled.div`
  font-size: 32px;
`;

const ConnectMessage = styled.div`
  font-size: 32px;
  padding: 18px;
`;

const InnerFullWidthBlurredContainer = styled.div`
  position: absolute;
  text-align: center;
  top: 20px;
  width: 100%;
  z-index: 1;
`;

const BlurredContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  margin: 0 auto;
  padding: 20px 32px;
  text-align: center;
  width: 85%;
  display: none;

  @media only screen and (min-width: 750px) {
    display: inherit;
  }
`;

const ButtonContainer = styled.div`
  background: rgba(255, 255, 255, 1);
  border-radius: 30px;
  margin: 0 auto;
  width: 300px;
`;

const Container = styled.div`
  margin: 0 auto;
  text-align: left;
  width: 90%;
`;

const FullWidthBlurredContainer = styled.div`
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0)
  );
  height: 180px;
  position: relative;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 975px) {
    ${({ hideDesktop }) => hideDesktop && "display: none;"}
  }
`;

const OuterContainer = styled.div`
  text-align: center;
  width: 100%;
  background-image: url("/bgk-staking-background.png");
  background-position: center;
  background-size: cover;
  min-height: calc(${document.documentElement.scrollHeight}px - 76px);
`;

const Heading = styled.h1`
  font-size: 72px;
  line-height: 115%;
  margin-bottom: 12px;
  margin-top: 0;
`;

const SubHeading = styled.h2`
  display: none;
  font-size: 24px;
  line-height: 115%;
  margin-top: 0;

  @media only screen and (min-width: 750px) {
    display: inherit;
  }
`;

const StakeLanding = ({
  account,
  connectMetaMask,
  connectWalletConnect,
  connectCoinbaseWallet,
}) => {
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const [error, setError] = useState("");
  const [redirectTo, setRedirectTo] = useState("");
  const [totalKidsStaked, setTotalKidsStaked] = useState(0);
  const [totalPupsStaked, setTotalPupsStaked] = useState(0);

  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    setShowConnectWalletModal(false);
  }, [setShowConnectWalletModal]);

  const getTotalStaked = useCallback(async () => {
    fetch(`${API_URL}/total-count`)
      .then((res) => {
        if (res.status > 201) {
          throw new Error(`${res.status}: could not get total staked`);
        }
        return res.json();
      })
      .then((json = {}) => {
        const { totalCountData = {} } = json;
        const { kidsCount = 0, pupsCount = 0 } = totalCountData;
        setTotalKidsStaked(kidsCount);
        setTotalPupsStaked(pupsCount);
      })
      .catch(console.error);
  }, [setTotalKidsStaked, setTotalPupsStaked]);

  const handleConnectButtonClick = useCallback(() => {
    setShowConnectWalletModal(true);
  }, [setShowConnectWalletModal]);

  const onConnectMetaMask = useCallback(() => {
    setRedirectTo("/stake");
    connectMetaMask();
  }, [connectMetaMask, setRedirectTo]);

  const onConnectWalletConnect = useCallback(() => {
    setRedirectTo("/stake");
    connectWalletConnect();
  }, [connectWalletConnect, setRedirectTo]);

  const onConnectCoinbaseWallet = useCallback(() => {
    setRedirectTo("/stake");
    connectCoinbaseWallet();
  }, [connectCoinbaseWallet, setRedirectTo]);

  useEffect(() => {
    getTotalStaked();
  }, [getTotalStaked]);

  useEffect(() => {
    if (account && redirectTo) {
      navigate(redirectTo);
    }
  }, [account, navigate, redirectTo]);

  return (
    <>
      {error && <ErrorModal error={error} setError={setError} />}
      {showConnectWalletModal && (
        <ConnectWalletModal
          closeModal={closeModal}
          connectMetaMask={onConnectMetaMask}
          connectWalletConnect={onConnectWalletConnect}
          connectCoinbaseWallet={onConnectCoinbaseWallet}
        />
      )}
      <TopBar
        account={account}
        handleConnectButtonClick={handleConnectButtonClick}
      />
      <TopBarMobile />
      <OuterContainer>
        <FullWidthBlurredContainer>
          <InnerFullWidthBlurredContainer>
            <Container>
              <Heading>Staking</Heading>
              <SubHeading>
                Join your Kids and Puppies in the Bubblegum Camp!
              </SubHeading>
            </Container>
          </InnerFullWidthBlurredContainer>
        </FullWidthBlurredContainer>
        <div style={{ height: "42px" }} />
        <Container>
          <BlurredContainer>
            <>
              <BCMessage>Total number of Kids and Puppies camping:</BCMessage>
              <div style={{ height: "18px" }} />
              <StakingCounter
                collectionAddress={kidsContractAddress}
                numberStaked={totalKidsStaked}
              />
              <StakingCounter
                collectionAddress={pupsContractAddress}
                numberStaked={totalPupsStaked}
              />
            </>
          </BlurredContainer>
        </Container>
        <div style={{ height: "62px" }} />
        {account ? (
          <FullWidthBlurredContainer>
            <InnerFullWidthBlurredContainer>
              <div style={{ height: "40px" }} />
              <ButtonContainer>
                <Link to="/stake">
                  <LinkButton width="300px">Go to My Camp</LinkButton>
                </Link>
              </ButtonContainer>
            </InnerFullWidthBlurredContainer>
          </FullWidthBlurredContainer>
        ) : (
          <FullWidthBlurredContainer hideDesktop={true}>
            <InnerFullWidthBlurredContainer>
              <ConnectMessage>
                Connect your wallet to stake Kids and Puppies!
              </ConnectMessage>
              <ButtonContainer>
                <Button
                  onClick={handleConnectButtonClick}
                  id="connect-button"
                  width="300px"
                >
                  Connect
                </Button>
              </ButtonContainer>
              <div style={{ height: "80px" }} />
            </InnerFullWidthBlurredContainer>
          </FullWidthBlurredContainer>
        )}
      </OuterContainer>
    </>
  );
};

export default StakeLanding;
