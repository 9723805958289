import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { ClearButton, SubmitButton } from "./styles";

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
    width: 660px;
    left: calc(50% - 300px);
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 975px) {
    justify-content: end;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 26px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;

  div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InnerButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: ${({ fullWidthButton }) =>
      fullWidthButton ? "100%" : `calc(50% - 12px)`};
  }

  @media only screen and (max-width: 750px) {
    flex-direction: column;

    & button {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
`;

const SettingsModal = ({
  closeModal,
  kidsApprovedForAll,
  pupsApprovedForAll,
  setWillApproveKidsForAll,
  setWillApprovePupsForAll,
  setWillUnapproveKidsForAll,
  setWillUnapprovePupsForAll,
}) => {
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const shouldClose = ![
        "user-avatar",
        "settings-modal",
        "settings-modal-container",
        "settings-modal-heading",
        "settings-modal-inner-container",
        "settings-modal-btns-container",
        "settings-modal-div-one",
        "settings-modal-div-two",
        "settings-modal-checkbox",
        "settings-modal-label",
        "settings-inner-btns-container",
        "settings-inner-btns-container-two",
      ].includes(event.target.id);
      if (shouldClose) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleApproveKidsForAllClick = useCallback(() => {
    closeModal();
    setWillApproveKidsForAll(true);
  }, [closeModal, setWillApproveKidsForAll]);

  const handleApprovePupsForAllClick = useCallback(() => {
    closeModal();
    setWillApprovePupsForAll(true);
  }, [closeModal, setWillApprovePupsForAll]);

  const handleApproveBothForAllClick = useCallback(() => {
    closeModal();
    setWillApproveKidsForAll(true);
    setWillApprovePupsForAll(true);
  }, [closeModal, setWillApproveKidsForAll, setWillApprovePupsForAll]);

  const handleUnapproveKidsForAllClick = useCallback(() => {
    closeModal();
    setWillUnapproveKidsForAll(true);
  }, [closeModal, setWillUnapproveKidsForAll]);

  const handleUnapprovePupsForAllClick = useCallback(() => {
    closeModal();
    setWillUnapprovePupsForAll(true);
  }, [closeModal, setWillUnapprovePupsForAll]);

  const handleUnapproveBothForAllClick = useCallback(() => {
    closeModal();
    setWillUnapproveKidsForAll(true);
    setWillUnapprovePupsForAll(true);
  }, [closeModal, setWillUnapproveKidsForAll, setWillUnapprovePupsForAll]);

  return (
    <OuterContainer id="settings-modal">
      <Container id="settings-modal-container">
        <CloseButtonContainer onClick={closeModal}>
          <CloseButton>×</CloseButton>
        </CloseButtonContainer>
        <Heading id="settings-modal-heading">Settings</Heading>
        <InnerContainer id="settings-modal-inner-container">
          {(!kidsApprovedForAll || !pupsApprovedForAll) && (
            <>
              <div id="settings-modal-div-one">
                Save time and gas by approving all{" "}
                {!kidsApprovedForAll && "Kids"}
                {!kidsApprovedForAll && !pupsApprovedForAll && " or "}
                {!pupsApprovedForAll && "Puppies"} for deposit to the staking
                contract:
              </div>
              <InnerButtonsContainer
                id="settings-inner-btns-container"
                fullWidthButton={kidsApprovedForAll || pupsApprovedForAll}
              >
                {!kidsApprovedForAll && (
                  <SubmitButton onClick={handleApproveKidsForAllClick}>
                    Approve All Kids
                  </SubmitButton>
                )}
                {!pupsApprovedForAll && (
                  <SubmitButton onClick={handleApprovePupsForAllClick}>
                    Approve All Puppies
                  </SubmitButton>
                )}
              </InnerButtonsContainer>
              {!kidsApprovedForAll && !pupsApprovedForAll && (
                <SubmitButton
                  width="100%"
                  onClick={handleApproveBothForAllClick}
                >
                  Approve All Kids & Puppies
                </SubmitButton>
              )}
            </>
          )}
          {(kidsApprovedForAll || pupsApprovedForAll) && (
            <>
              <div id="settings-modal-div-two">
                Minimize your exposure to risk by unapproving{" "}
                {kidsApprovedForAll && "Kids"}
                {kidsApprovedForAll && pupsApprovedForAll && " or "}
                {pupsApprovedForAll && "Puppies"} for deposit to the staking
                contract:
              </div>
              <InnerButtonsContainer
                id="settings-inner-btns-container-two"
                fullWidthButton={!kidsApprovedForAll || !pupsApprovedForAll}
              >
                {kidsApprovedForAll && (
                  <SubmitButton onClick={handleUnapproveKidsForAllClick}>
                    Unapprove All Kids
                  </SubmitButton>
                )}
                {pupsApprovedForAll && (
                  <SubmitButton onClick={handleUnapprovePupsForAllClick}>
                    Unapprove All Puppies
                  </SubmitButton>
                )}
              </InnerButtonsContainer>
              {kidsApprovedForAll && pupsApprovedForAll && (
                <SubmitButton
                  width="100%"
                  onClick={handleUnapproveBothForAllClick}
                >
                  Unapprove All Kids & Puppies
                </SubmitButton>
              )}
            </>
          )}
        </InnerContainer>
        <ButtonsContainer id="settings-modal-btns-container">
          <ClearButton onClick={closeModal}>Exit</ClearButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default SettingsModal;
