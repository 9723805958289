import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: (
      <div style={{ padding: "24px", textAlign: "center" }}>
        <h1>404 🫤</h1>
        <div style={{ marginBottom: "18px" }}>
          sorry, we couldn't find that page
        </div>
        <div>
          <a href="/">go back home</a>
        </div>
      </div>
    ),
  },
  {
    path: "/stake",
    element: <App isStake={true} />,
  },
  {
    path: "/faq",
    element: <App isFAQ={true} />,
  },
  {
    path: "/how-to",
    element: <App isHowTo={true} />,
  },
  {
    path: "/marketplace",
    element: <App isMarketPlace={true} />,
  },
  // SOOOOON
  // {
  //   path: "/vx",
  //   element: <App isVxKidsMint={true} />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
