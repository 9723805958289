import styled from "styled-components";

const Account = styled.a`
  font-size: 24px;
  text-align: left;
  text-shadow: 2px 2px #e04778;
  margin-left: 18px;
  color: #fff;
  text-decoration: none;
`;

const AccountContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 750px) {
    grid-column: 1/-1;
  }
`;

const Container = styled.div`
  border: 3px solid #e04778;
  border-radius: 20px;
  margin-bottom: 32px;
  padding: 4px;
  width: calc(100% - 8px);
`;

const StatsDivider = styled.div`
  background-color: #fff;
  border-radius: 3px;
  width: 100%;

  @media only screen and (max-width: 750px) {
    ${({ hideMobile }) => hideMobile && "display: none;"}
  }
`;

const StatsContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  ${({ disabled, hasCursor }) =>
    disabled
      ? "cursor: not-allowed;"
      : hasCursor
      ? "cursor: pointer;"
      : "cursor: auto;"}
`;

const StatsHeader = styled.h3`
  color: #e04778;
  font-size: 24px;
  margin: 0;
  text-align: left;
`;

const StatsValue = styled.div`
  align-items: center;
  display: flex;
  font-size: 42px;
  text-align: left;
  text-shadow: 2px 2px #e04778;

  img {
    margin-left: 8px;
    margin-top: 3px;
  }
`;

const UserStatsInner = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  grid-template-rows: 1fr auto;
  grid-row-gap: 24px;
  padding: 18px 24px;
  width: calc(100% - 48px);

  @media only screen and (min-width: 750px) {
    grid-template-columns: 1fr 2px 1fr 2px 1fr;
    grid-template-rows: 1fr;
  }
`;

const MarketplaceUserPanel = ({
  account,
  gumBalance,
  gumCartTotal,
  handleAvatarClick,
  handleCartClick,
  purchaseInProgress,
}) => {
  return (
    <Container>
      <UserStatsInner>
        <StatsContainer>
          <StatsHeader>Gum Balance</StatsHeader>
          <StatsValue>{gumBalance}</StatsValue>
        </StatsContainer>
        <StatsDivider />
        <StatsContainer
          hasCursor
          disabled={purchaseInProgress}
          onClick={handleCartClick}
          id="cart-section"
        >
          <StatsHeader>My Cart</StatsHeader>
          <div style={{ alignItems: "center", display: "flex", gap: "18px" }}>
            <img src="/cart.svg" width="36px" height="36px" alt="cart" />
            <StatsValue>{gumCartTotal} $GUM</StatsValue>
          </div>
        </StatsContainer>
        <StatsDivider hideMobile={true} />
        <AccountContainer>
          <img
            src="/user-icon.png"
            alt="user avatar"
            width="80px"
            id="user-avatar"
            onClick={handleAvatarClick}
            style={{ cursor: "pointer" }}
          />
          <Account
            href={`https://etherscan.io/address/${account}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {account.slice(0, 5)}...
            {account.slice(account.length - 4)}
          </Account>
        </AccountContainer>
      </UserStatsInner>
    </Container>
  );
};

export default MarketplaceUserPanel;
