import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TopBar from "./TopBar";
import TopBarMobile from "./TopBarMobile";
import { LinkButton } from "./styles";
import { API_URL, CONTRACT_ADDRESSES } from "./config";
import ConfirmationModal from "./ConfirmationModal";
import ClaimModal from "./ClaimModal";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import ApproveAllModal from "./ApproveAllModal";
import PromptAvatarModal from "./PromptAvatarModal";
import SettingsModal from "./SettingsModal";
import CarouselDesktop from "./CarouselDesktop";
import CarouselTablet from "./CarouselTablet";
import MobileView from "./MobileView";
import UserPanel from "./UserPanel";
import SettingsBar from "./SettingsBar";
import GridView from "./GridView";
import { DateTime, Interval } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import Heading from "./Heading";

const { kidsContractAddress, pupsContractAddress, stakingContractAddress } =
  CONTRACT_ADDRESSES;

const CenteredContainer = styled.div`
  text-align: center;
`;

const Container = styled.div`
  grid-template-rows: 1fr auto;
  margin: 0 auto;
  padding-bottom: 52px;
  text-align: left;
  width: 90%;
`;

const OuterContainer = styled.div`
  background: url("/bgk-staking-background-blur.png");
  width: 100%;
  min-height: calc(${document.documentElement.scrollHeight}px - 50px);
`;

const SubHeader = styled.div`
  font-size: 36px;
  line-height: 115%;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const Stake = ({
  account,
  getGumBalance,
  gumBalance,
  imgSrcs,
  kidsContract,
  kidsDeposits,
  kidsIds,
  kidsUnstaked,
  pupsContract,
  pupsDeposits,
  pupsIds,
  pupsUnstaked,
  setKidsDeposits,
  setKidsUnstaked,
  setPupsDeposits,
  setPupsUnstaked,
  stakingContract,
  web3,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showPromptAvatarModal, setShowPromptAvatarModal] = useState(false);
  const [disabledInteractions, setDisabledInteractions] = useState(false);
  const [claimInProgress, setClaimInProgress] = useState(false);
  const [moreTransactions, setMoreTransactions] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [viewType, setViewType] = useState("carousel");
  const [filter, setFilter] = useState("All");

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rewards, setRewards] = useState(0);
  const [collection, setCollection] = useState("bgk");
  const [daysStaked, setDaysStaked] = useState({});

  const [wontApproveAll, setWontApproveAll] = useState(false);
  const [showApproveAllModal, setShowApproveAllModal] = useState(false);
  const [kidsApprovedForAll, setKidsApprovedForAll] = useState(false);
  const [pupsApprovedForAll, setPupsApprovedForAll] = useState(false);
  const [willApproveKidsForAll, setWillApproveKidsForAll] = useState(false);
  const [willApprovePupsForAll, setWillApprovePupsForAll] = useState(false);
  const [willUnapproveKidsForAll, setWillUnapproveKidsForAll] = useState(false);
  const [willUnapprovePupsForAll, setWillUnapprovePupsForAll] = useState(false);

  const [kidsStagedForDeposit, setKidsStagedForDeposit] = useState([]);
  const [pupsStagedForDeposit, setPupsStagedForDeposit] = useState([]);
  const [kidsStagedForWithdrawal, setKidsStagedForWithdrawal] = useState([]);
  const [pupsStagedForWithdrawal, setPupsStagedForWithdrawal] = useState([]);
  const [willStakeAll, setWillStakeAll] = useState(false);
  const [kidsDepositInProgress, setKidsDepositInProgress] = useState([]);
  const [pupsDepositInProgress, setPupsDepositInProgress] = useState([]);
  const [kidsWithdrawalInProgress, setKidsWithdrawalInProgress] = useState([]);
  const [pupsWithdrawalInProgress, setPupsWithdrawalInProgress] = useState([]);
  const [
    kidsForAllApprovalChangeInProgress,
    setKidsForAllApprovalChangeInProgress,
  ] = useState(false);
  const [
    pupsForAllApprovalChangeInProgress,
    setPupsForAllApprovalChangeInProgress,
  ] = useState(false);

  const navigate = useNavigate();

  const handleError = useCallback(
    (err, uiMessage) => {
      if (err?.message && err.message.includes("might still be mined")) {
        setError(
          "Your transaction is taking a while to process, probably because it's priced below the current network gas price. Check your wallet to see the status of the transaction. You can either wait for it to go through or raise the gas price to speed it up."
        );
      } else if (
        err?.message &&
        err.message.includes("denied transaction signature")
      ) {
        return;
      } else {
        setError(uiMessage);
      }
    },
    [setError]
  );

  const updateTotalStaked = useCallback(async () => {
    if (
      process.env.REACT_APP_ETHEREUM_ENVIRONMENT?.length &&
      process.env.REACT_APP_ETHEREUM_ENVIRONMENT !== "mainnet"
    )
      return;
    const totalCount = {};
    try {
      const kidsCountRes = await kidsContract.methods
        .balanceOf(stakingContractAddress)
        .call();
      totalCount.kidsCount = Number(kidsCountRes);
    } catch (err) {
      console.error(err);
      return;
    }
    try {
      const pupsCountRes = await pupsContract.methods
        .balanceOf(stakingContractAddress)
        .call();
      totalCount.pupsCount = Number(pupsCountRes);
    } catch (err) {
      console.error(err);
      return;
    }
    fetch(`${API_URL}/total-count`, {
      method: "POST",
      body: JSON.stringify(totalCount),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("updated total count:", res.status);
      })
      .catch(console.error);
  }, [kidsContract, pupsContract]);

  const getRewards = useCallback(async () => {
    const bgContracts = [];
    for (let i = 0; i < kidsDeposits.length; i++) {
      bgContracts.push(0);
    }
    for (let i = 0; i < pupsDeposits.length; i++) {
      bgContracts.push(1);
    }
    try {
      const rawRewards = await stakingContract.methods
        .calculateRewards(
          account,
          [...kidsDeposits, ...pupsDeposits],
          bgContracts
        )
        .call();
      const _rewards = rawRewards.reduce((acc, cur) => {
        return Number(cur) / 10 ** 18 + acc;
      }, 0);
      setRewards(Number(_rewards));
    } catch (err) {
      console.error(err);
      return;
    }
  }, [account, stakingContract, kidsDeposits, pupsDeposits, setRewards]);

  const closeModal = useCallback(() => {
    setShowConfirmationModal(false);
    setShowClaimModal(false);
    setShowSuccessModal(false);
    setShowApproveAllModal(false);
    setShowSettingsModal(false);
    setShowPromptAvatarModal(false);
  }, [
    setShowClaimModal,
    setShowConfirmationModal,
    setShowSuccessModal,
    setShowApproveAllModal,
    setShowSettingsModal,
    setShowPromptAvatarModal,
  ]);

  const beforeSuccessModal = useCallback(
    ({ _transactionHash, _moreTransactions }) => {
      setTransactionHash(_transactionHash);
      setMoreTransactions(_moreTransactions);
    },
    [setTransactionHash, setMoreTransactions]
  );

  const beforeDeposit = useCallback(() => {
    setKidsDepositInProgress(kidsStagedForDeposit);
    setPupsDepositInProgress(pupsStagedForDeposit);
    setKidsStagedForDeposit([]);
    setPupsStagedForDeposit([]);
  }, [
    kidsStagedForDeposit,
    pupsStagedForDeposit,
    setKidsDepositInProgress,
    setPupsDepositInProgress,
    setKidsStagedForDeposit,
    setPupsStagedForDeposit,
  ]);

  const afterDeposit = useCallback(() => {
    const kidsJustDeposited = kidsDepositInProgress.length
      ? kidsDepositInProgress
      : kidsStagedForDeposit;
    const pupsJustDeposited = pupsDepositInProgress.length
      ? pupsDepositInProgress
      : pupsStagedForDeposit;
    setDisabledInteractions(false);
    setKidsDeposits([...kidsDeposits, ...kidsJustDeposited]);
    setPupsDeposits([...pupsDeposits, ...pupsJustDeposited]);
    setKidsUnstaked(
      kidsUnstaked.filter((kid) => !kidsJustDeposited.includes(kid))
    );
    setPupsUnstaked(
      pupsUnstaked.filter((pup) => !pupsJustDeposited.includes(pup))
    );
    setKidsDepositInProgress([]);
    setPupsDepositInProgress([]);
  }, [
    kidsDeposits,
    kidsDepositInProgress,
    kidsStagedForDeposit,
    kidsUnstaked,
    pupsDeposits,
    pupsDepositInProgress,
    pupsStagedForDeposit,
    pupsUnstaked,
    setDisabledInteractions,
    setKidsDepositInProgress,
    setKidsDeposits,
    setKidsUnstaked,
    setPupsDepositInProgress,
    setPupsDeposits,
    setPupsUnstaked,
  ]);

  const getApprovedForAll = useCallback(async () => {
    try {
      const _kidsApprovedForAll = await kidsContract.methods
        .isApprovedForAll(account, stakingContractAddress)
        .call();
      setKidsApprovedForAll(_kidsApprovedForAll);
    } catch (err) {
      console.error(err);
    }
    try {
      const _pupsApprovedForAll = await pupsContract.methods
        .isApprovedForAll(account, stakingContractAddress)
        .call();
      setPupsApprovedForAll(_pupsApprovedForAll);
    } catch (err) {
      console.error(err);
    }
  }, [
    account,
    setKidsApprovedForAll,
    setPupsApprovedForAll,
    kidsContract,
    pupsContract,
  ]);

  const handleDeposit = useCallback(async () => {
    const depositIds = [...kidsStagedForDeposit, ...pupsStagedForDeposit];
    const depositCollections = [
      ...kidsStagedForDeposit.map((_) => 0),
      ...pupsStagedForDeposit.map((_) => 1),
    ];
    if (!depositIds.length) return;
    beforeDeposit();
    let _kidsApprovedForAll = kidsApprovedForAll;
    if (!_kidsApprovedForAll && kidsStagedForDeposit.length) {
      try {
        _kidsApprovedForAll = await kidsContract.methods
          .isApprovedForAll(account, stakingContractAddress)
          .call();
        setKidsApprovedForAll(_kidsApprovedForAll);
      } catch (err) {
        console.error(err);
      }
      if (!_kidsApprovedForAll) {
        for (let i = 0; i < kidsStagedForDeposit.length; i++) {
          try {
            const isApprovedRes = await kidsContract.methods
              .getApproved(kidsStagedForDeposit[i])
              .call();
            if (isApprovedRes !== stakingContractAddress) {
              const approveRes = await kidsContract.methods
                .approve(stakingContractAddress, kidsStagedForDeposit[i])
                .send({
                  from: account,
                  to: stakingContractAddress,
                });
              console.log({ approveRes });
              const { transactionHash: _transactionHash } = approveRes;
              beforeSuccessModal({ _transactionHash, _moreTransactions: true });
              setShowSuccessModal(true);
            }
          } catch (err) {
            console.error(err);
            handleError(
              err,
              "We're sorry, something went wrong with your approval."
            );
            setDisabledInteractions(false);
            setKidsDepositInProgress([]);
            setPupsDepositInProgress([]);
            return;
          }
        }
      }
    }
    let _pupsApprovedForAll = pupsApprovedForAll;
    if (!_pupsApprovedForAll && pupsStagedForDeposit.length) {
      try {
        _pupsApprovedForAll = await pupsContract.methods
          .isApprovedForAll(account, stakingContractAddress)
          .call();
        setPupsApprovedForAll(_pupsApprovedForAll);
      } catch (err) {
        console.error(err);
      }
      if (!_pupsApprovedForAll) {
        for (let i = 0; i < pupsStagedForDeposit.length; i++) {
          try {
            const isApprovedRes = await pupsContract.methods
              .getApproved(pupsStagedForDeposit[i])
              .call();
            if (isApprovedRes !== stakingContractAddress) {
              const approveRes = await pupsContract.methods
                .approve(stakingContractAddress, pupsStagedForDeposit[i])
                .send({
                  from: account,
                  to: stakingContractAddress,
                });
              console.log({ approveRes });
              const { transactionHash: _transactionHash } = approveRes;
              beforeSuccessModal({ _transactionHash, _moreTransactions: true });
              setShowSuccessModal(true);
            }
          } catch (err) {
            console.error(err);
            handleError(
              err,
              "We're sorry, something went wrong with your approval."
            );
            setDisabledInteractions(false);
            setKidsDepositInProgress([]);
            setPupsDepositInProgress([]);
            return;
          }
        }
      }
    }
    try {
      const depositRes = await stakingContract.methods
        .deposit(depositIds, depositCollections)
        .send({
          from: account,
          to: stakingContractAddress,
        });
      console.log({ depositRes });
      const { transactionHash: _transactionHash } = depositRes;
      afterDeposit();
      const _moreTransactions =
        [...kidsStagedForWithdrawal, ...pupsStagedForWithdrawal].length > 0;
      beforeSuccessModal({
        _transactionHash,
        _moreTransactions,
      });
      setShowSuccessModal(true);
    } catch (err) {
      console.error(err);
      setDisabledInteractions(false);
      setKidsDepositInProgress([]);
      setPupsDepositInProgress([]);
      handleError(err, "We're sorry, something went wrong with your deposit.");
    }
  }, [
    account,
    afterDeposit,
    beforeDeposit,
    beforeSuccessModal,
    handleError,
    kidsApprovedForAll,
    pupsContract,
    kidsContract,
    kidsStagedForDeposit,
    kidsStagedForWithdrawal,
    pupsApprovedForAll,
    pupsStagedForDeposit,
    pupsStagedForWithdrawal,
    setDisabledInteractions,
    setKidsApprovedForAll,
    setPupsApprovedForAll,
    setShowSuccessModal,
    stakingContract,
  ]);

  const beforeWithdrawal = useCallback(() => {
    setKidsWithdrawalInProgress(kidsStagedForWithdrawal);
    setPupsWithdrawalInProgress(pupsStagedForWithdrawal);
    setKidsStagedForWithdrawal([]);
    setPupsStagedForWithdrawal([]);
  }, [
    kidsStagedForWithdrawal,
    pupsStagedForWithdrawal,
    setKidsWithdrawalInProgress,
    setPupsWithdrawalInProgress,
    setKidsStagedForWithdrawal,
    setPupsStagedForWithdrawal,
  ]);

  const afterWithdrawal = useCallback(() => {
    const kidsJustWithdrawn = kidsWithdrawalInProgress.length
      ? kidsWithdrawalInProgress
      : kidsStagedForWithdrawal;
    const pupsJustWithdrawn = pupsWithdrawalInProgress.length
      ? pupsWithdrawalInProgress
      : pupsStagedForWithdrawal;
    setDisabledInteractions(false);
    setKidsUnstaked([...kidsDeposits, ...kidsJustWithdrawn]);
    setPupsUnstaked([...pupsDeposits, ...pupsJustWithdrawn]);
    setKidsDeposits(
      kidsDeposits.filter((kid) => !kidsJustWithdrawn.includes(kid))
    );
    setPupsDeposits(
      pupsDeposits.filter((pup) => !pupsJustWithdrawn.includes(pup))
    );
    setKidsWithdrawalInProgress([]);
    setPupsWithdrawalInProgress([]);
  }, [
    kidsDeposits,
    kidsStagedForWithdrawal,
    kidsWithdrawalInProgress,
    pupsDeposits,
    pupsStagedForWithdrawal,
    pupsWithdrawalInProgress,
    setDisabledInteractions,
    setKidsDeposits,
    setKidsUnstaked,
    setKidsWithdrawalInProgress,
    setPupsDeposits,
    setPupsUnstaked,
    setPupsWithdrawalInProgress,
  ]);

  const handleUnstakeAllClick = useCallback(() => {
    setKidsStagedForWithdrawal(kidsDeposits);
    setPupsStagedForWithdrawal(pupsDeposits);
  }, [
    kidsDeposits,
    pupsDeposits,
    setKidsStagedForWithdrawal,
    setPupsStagedForWithdrawal,
  ]);

  const handleWithdrawal = useCallback(async () => {
    const withdrawalIds = [
      ...kidsStagedForWithdrawal,
      ...pupsStagedForWithdrawal,
    ];
    const withdrawalCollections = [
      ...kidsStagedForWithdrawal.map((_) => 0),
      ...pupsStagedForWithdrawal.map((_) => 1),
    ];
    if (!withdrawalIds.length) return;
    beforeWithdrawal();
    try {
      const withdrawRes = await stakingContract.methods
        .withdraw(withdrawalIds, withdrawalCollections)
        .send({
          from: account,
          to: stakingContractAddress,
        });
      console.log({ withdrawRes });
      const { transactionHash: _transactionHash } = withdrawRes;
      setDisabledInteractions(false);
      setKidsWithdrawalInProgress([]);
      setPupsWithdrawalInProgress([]);
      const _moreTransactions =
        [...kidsStagedForDeposit, ...pupsStagedForDeposit].length > 0;
      beforeSuccessModal({
        _transactionHash,
        _moreTransactions,
      });
      setShowSuccessModal(true);
      afterWithdrawal();
    } catch (err) {
      console.error(err);
      setDisabledInteractions(false);
      setKidsWithdrawalInProgress([]);
      setPupsWithdrawalInProgress([]);
      handleError(
        err,
        "We're sorry, something went wrong with your withdrawal."
      );
    }
  }, [
    account,
    afterWithdrawal,
    beforeSuccessModal,
    handleError,
    stakingContract,
    kidsStagedForWithdrawal,
    pupsStagedForWithdrawal,
    setKidsWithdrawalInProgress,
    setPupsWithdrawalInProgress,
    setDisabledInteractions,
    beforeWithdrawal,
    kidsStagedForDeposit,
    pupsStagedForDeposit,
    setShowSuccessModal,
  ]);

  const handleStageForDepositClick = useCallback(
    ({ collectionAddress, id }) => {
      if (collectionAddress === kidsContractAddress) {
        setKidsStagedForDeposit([...kidsStagedForDeposit, id]);
      } else if (collectionAddress === pupsContractAddress) {
        setPupsStagedForDeposit([...pupsStagedForDeposit, id]);
      }
    },
    [
      kidsStagedForDeposit,
      pupsStagedForDeposit,
      setKidsStagedForDeposit,
      setPupsStagedForDeposit,
    ]
  );

  const handleStageForWithdrawalClick = useCallback(
    ({ collectionAddress, id }) => {
      if (collectionAddress === kidsContractAddress) {
        setKidsStagedForWithdrawal([...kidsStagedForWithdrawal, id]);
      } else if (collectionAddress === pupsContractAddress) {
        setPupsStagedForWithdrawal([...pupsStagedForWithdrawal, id]);
      }
    },
    [
      kidsStagedForWithdrawal,
      pupsStagedForWithdrawal,
      setKidsStagedForWithdrawal,
      setPupsStagedForWithdrawal,
    ]
  );

  const handleUnstageForDepositClick = useCallback(
    ({ collectionAddress, id }) => {
      if (collectionAddress === kidsContractAddress) {
        setKidsStagedForDeposit(
          kidsStagedForDeposit.filter((_id) => id !== _id)
        );
      } else if (collectionAddress === pupsContractAddress) {
        setPupsStagedForDeposit(
          pupsStagedForDeposit.filter((_id) => id !== _id)
        );
      }
    },
    [
      kidsStagedForDeposit,
      pupsStagedForDeposit,
      setKidsStagedForDeposit,
      setPupsStagedForDeposit,
    ]
  );

  const handleUnstageForWithdrawalClick = useCallback(
    ({ collectionAddress, id }) => {
      if (collectionAddress === kidsContractAddress) {
        setKidsStagedForWithdrawal(
          kidsStagedForWithdrawal.filter((_id) => id !== _id)
        );
      } else if (collectionAddress === pupsContractAddress) {
        setPupsStagedForWithdrawal(
          pupsStagedForWithdrawal.filter((_id) => id !== _id)
        );
      }
    },
    [
      kidsStagedForWithdrawal,
      pupsStagedForWithdrawal,
      setKidsStagedForWithdrawal,
      setPupsStagedForWithdrawal,
    ]
  );

  const handleClear = useCallback(() => {
    setKidsStagedForDeposit([]);
    setKidsStagedForWithdrawal([]);
    setPupsStagedForDeposit([]);
    setPupsStagedForWithdrawal([]);
  }, [
    setKidsStagedForDeposit,
    setKidsStagedForWithdrawal,
    setPupsStagedForDeposit,
    setPupsStagedForWithdrawal,
  ]);

  const handleSumbitButtonClick = useCallback(() => {
    setShowConfirmationModal(true);
  }, [setShowConfirmationModal]);

  const handleSubmit = useCallback(() => {
    closeModal();
    setDisabledInteractions(true);
    handleWithdrawal();
    handleDeposit();
  }, [closeModal, handleDeposit, handleWithdrawal, setDisabledInteractions]);

  const handleBackButtonClick = useCallback(() => {
    setPage(page - 1);
  }, [page, setPage]);

  const handleForwardButtonClick = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const approveKidsForAll = useCallback(
    async (approve = true) => {
      setKidsForAllApprovalChangeInProgress(true);
      try {
        const approveKidsForAllRes = await kidsContract.methods
          .setApprovalForAll(stakingContractAddress, approve)
          .send({
            from: account,
            to: stakingContractAddress,
          });
        console.log({ approveKidsForAllRes });
        const { transactionHash: _transactionHash } = approveKidsForAllRes;
        beforeSuccessModal({
          _transactionHash,
          _moreTransactions: willApprovePupsForAll,
        });
        setWillApproveKidsForAll(false);
        setWillUnapproveKidsForAll(false);
        setKidsForAllApprovalChangeInProgress(false);
        setWontApproveAll(true);
        setShowSuccessModal(true);
        getApprovedForAll();
      } catch (err) {
        console.error(err);
        setWillApproveKidsForAll(false);
        setWillUnapproveKidsForAll(false);
        setKidsForAllApprovalChangeInProgress(false);
        handleError(
          err,
          "We're sorry, something went wrong with your approval."
        );
      }
    },
    [
      account,
      beforeSuccessModal,
      getApprovedForAll,
      handleError,
      kidsContract,
      setKidsForAllApprovalChangeInProgress,
      setWillApproveKidsForAll,
      setWillUnapproveKidsForAll,
      setWontApproveAll,
      willApprovePupsForAll,
    ]
  );

  const approvePupsForAll = useCallback(
    async (approve = true) => {
      setPupsForAllApprovalChangeInProgress(true);
      try {
        const approvePupsForAllRes = await pupsContract.methods
          .setApprovalForAll(stakingContractAddress, approve)
          .send({
            from: account,
            to: stakingContractAddress,
          });
        console.log({ approvePupsForAllRes });
        const { transactionHash: _transactionHash } = approvePupsForAllRes;
        beforeSuccessModal({
          _transactionHash,
          _moreTransactions: willApproveKidsForAll,
        });
        setWillApprovePupsForAll(false);
        setWillUnapprovePupsForAll(false);
        setPupsForAllApprovalChangeInProgress(false);
        setWontApproveAll(true);
        setShowSuccessModal(true);
        getApprovedForAll();
      } catch (err) {
        console.error(err);
        setWillApprovePupsForAll(false);
        setWillUnapprovePupsForAll(false);
        setPupsForAllApprovalChangeInProgress(false);
        handleError(
          err,
          "We're sorry, something went wrong with your approval."
        );
      }
    },
    [
      account,
      beforeSuccessModal,
      getApprovedForAll,
      handleError,
      pupsContract,
      setPupsForAllApprovalChangeInProgress,
      setShowSuccessModal,
      setWillApprovePupsForAll,
      setWillUnapprovePupsForAll,
      setWontApproveAll,
      willApproveKidsForAll,
    ]
  );

  const handleAvatarClick = useCallback(() => {
    setWontApproveAll(false);
    setShowSettingsModal(true);
  }, [setShowSettingsModal, setWontApproveAll]);

  const claimRewards = useCallback(async () => {
    try {
      const claimRes = await stakingContract.methods.claimRewards().send({
        from: account,
        to: stakingContractAddress,
      });
      console.log({ claimRes });
      const { transactionHash: _transactionHash } = claimRes;
      setClaimInProgress(false);
      beforeSuccessModal({ _transactionHash, _moreTransactions: false });
      setShowSuccessModal(true);
      getGumBalance();
      getRewards();
    } catch (err) {
      console.error(err);
      setClaimInProgress(false);
    }
  }, [
    account,
    stakingContract,
    setClaimInProgress,
    setShowSuccessModal,
    beforeSuccessModal,
    getGumBalance,
    getRewards,
  ]);

  const persistHideApproveAllModal = useCallback(() => {
    localStorage.setItem("hideApproveAllModalNew", "true");
  }, []);

  const handleClaimClick = useCallback(() => {
    setShowClaimModal(true);
  }, [setShowClaimModal]);

  const toggleCollection = useCallback(() => {
    setCollection(collection === "bgk" ? "bgp" : "bgk");
  }, [collection, setCollection]);

  const handleStakeAllClick = useCallback(() => {
    setWontApproveAll(false);
    setKidsStagedForDeposit(kidsUnstaked);
    setPupsStagedForDeposit(pupsUnstaked);
    setWillStakeAll(true);
    const shouldShowApproveAllModal =
      (kidsUnstaked.length && !kidsApprovedForAll) ||
      (pupsUnstaked.length && !pupsApprovedForAll);
    if (shouldShowApproveAllModal) setShowApproveAllModal(true);
  }, [
    kidsApprovedForAll,
    kidsUnstaked,
    pupsApprovedForAll,
    pupsUnstaked,
    setKidsStagedForDeposit,
    setPupsStagedForDeposit,
    setShowApproveAllModal,
    setWillStakeAll,
    setWontApproveAll,
  ]);

  useEffect(() => {
    if (!willApproveKidsForAll || kidsForAllApprovalChangeInProgress) return;
    if (willApprovePupsForAll) setMoreTransactions(true);
    approveKidsForAll();
  }, [
    approveKidsForAll,
    kidsForAllApprovalChangeInProgress,
    setMoreTransactions,
    willApproveKidsForAll,
    willApprovePupsForAll,
  ]);

  useEffect(() => {
    if (!willApprovePupsForAll || pupsForAllApprovalChangeInProgress) return;
    if (willApproveKidsForAll) setMoreTransactions(true);
    approvePupsForAll();
  }, [
    approvePupsForAll,
    pupsForAllApprovalChangeInProgress,
    setMoreTransactions,
    willApproveKidsForAll,
    willApprovePupsForAll,
  ]);

  useEffect(() => {
    if (!willUnapproveKidsForAll || kidsForAllApprovalChangeInProgress) return;
    if (willUnapprovePupsForAll) setMoreTransactions(true);
    approveKidsForAll(false);
  }, [
    approveKidsForAll,
    kidsForAllApprovalChangeInProgress,
    willUnapproveKidsForAll,
    willUnapprovePupsForAll,
    setMoreTransactions,
  ]);

  useEffect(() => {
    if (!willUnapprovePupsForAll || pupsForAllApprovalChangeInProgress) return;
    if (willUnapproveKidsForAll) setMoreTransactions(true);
    approvePupsForAll(false);
  }, [
    approvePupsForAll,
    pupsForAllApprovalChangeInProgress,
    willUnapproveKidsForAll,
    willUnapprovePupsForAll,
    setMoreTransactions,
  ]);

  useEffect(() => {
    const allTotalCount =
      collection === "bgk" ? kidsIds.length ?? 0 : pupsIds.length ?? 0;
    const atHomeTotalCount =
      collection === "bgk"
        ? kidsUnstaked.length ?? 0
        : pupsUnstaked.length ?? 0;
    const atCampTotalCount =
      collection === "bgk"
        ? kidsDeposits.length ?? 0
        : pupsDeposits.length ?? 0;

    let _totalCount = allTotalCount;
    if (filter === "At Home") {
      _totalCount = atHomeTotalCount;
    } else if (filter === "At Camp") {
      _totalCount = atCampTotalCount;
    }
    setTotalCount(_totalCount);
    setPage(0);
  }, [
    collection,
    filter,
    kidsDeposits.length,
    kidsIds.length,
    kidsUnstaked.length,
    pupsDeposits.length,
    pupsIds.length,
    pupsUnstaked.length,
    setPage,
    setTotalCount,
    setLoading,
  ]);

  useEffect(() => {
    if (
      !account ||
      (kidsDeposits.length === 0 && pupsDeposits.length === 0) ||
      !stakingContract
    ) {
      return;
    }
    getRewards();
  }, [account, stakingContract, kidsDeposits, pupsDeposits, getRewards]);

  useEffect(() => {
    if (!kidsContract || !pupsContract || !stakingContractAddress) {
      return;
    }
    updateTotalStaked();
  }, [kidsContract, pupsContract, updateTotalStaked]);

  const stopLoadingIfNoHoldings = useCallback(() => {
    if (!kidsIds.length && !pupsIds.length) {
      setLoading(false);
    }
  }, [kidsIds.length, pupsIds.length, setLoading]);

  const redirectIfNoAccount = useCallback(() => {
    if (!account) {
      navigate("/");
    }
  }, [account, navigate]);

  useEffect(() => {
    setLoading(true);
    setTimeout(redirectIfNoAccount, 0);
    setTimeout(stopLoadingIfNoHoldings, 2000);
  }, []);

  useEffect(() => {
    if (!kidsContract || !pupsContract) return;
    getApprovedForAll();
  }, [kidsContract, pupsContract, getApprovedForAll]);

  useEffect(() => {
    if (kidsApprovedForAll && pupsApprovedForAll) return;
    const hideApproveAllModal = localStorage.getItem("hideApproveAllModalNew");
    if (hideApproveAllModal) return;
    setShowApproveAllModal(true);
  }, [kidsApprovedForAll, pupsApprovedForAll, setShowApproveAllModal]);

  useEffect(() => {
    if (kidsIds.length === 0 && pupsIds.length > 0) setCollection("bgp");
  }, [kidsIds, pupsIds, setCollection]);

  useEffect(() => {
    const kidsReady = Object.values(imgSrcs?.bgk || {})?.[0]?.length > 0;
    const pupsReady = Object.values(imgSrcs?.bgk || {})?.[0]?.length > 0;
    if (kidsReady || pupsReady) setLoading(false);
  }, [imgSrcs?.bgk, imgSrcs?.bgp, setLoading]);

  useEffect(() => {
    if (!web3?.eth || (!kidsDeposits.length && !pupsDeposits.length)) return;
    let _daysStaked = {
      bgk: {},
      bgp: {},
    };
    const depositsWithCollections = [
      ...kidsDeposits.map((id) => ({ id, collection: "bgk" })),
      ...pupsDeposits.map((id) => ({ id, collection: "bgp" })),
    ];
    Promise.all(
      depositsWithCollections.map(({ id, collection }) => {
        return stakingContract.methods
          .depositBlocks(collection === "bgk" ? 0 : 1, id)
          .call()
          .then((block) => ({ block: Number(block), id, collection }))
          .catch(console.error);
      })
    )
      .then(async (data) => {
        for (const { block, id, collection } of data) {
          const blockInfo = await web3?.eth.getBlock(block);
          const { timestamp } = blockInfo || {};
          const now = DateTime.now();
          const dateStaked = DateTime.fromSeconds(timestamp);
          const daysElapsed = Math.floor(
            Interval.fromDateTimes(dateStaked, now).length("days")
          );
          _daysStaked[collection][id] = daysElapsed;
        }
        setDaysStaked(_daysStaked);
      })
      .catch(console.error);
  }, [kidsDeposits, pupsDeposits, setDaysStaked, stakingContract, web3?.eth]);

  if (loading) {
    return (
      <div style={{ paddingTop: "68px", textAlign: "center" }}>
        <img src="/loading.gif" alt="loading" />
      </div>
    );
  }

  const displaySectionProps = {
    collection,
    daysStaked,
    disabledInteractions,
    filter,
    handleStageForDepositClick,
    handleStageForWithdrawalClick,
    handleUnstageForDepositClick,
    handleUnstageForWithdrawalClick,
    imgSrcs,
    kidsContractAddress,
    kidsDepositInProgress,
    kidsDeposits,
    kidsIds,
    kidsStagedForDeposit,
    kidsStagedForWithdrawal,
    kidsUnstaked,
    kidsWithdrawalInProgress,
    pupsContractAddress,
    pupsDeposits,
    pupsDepositInProgress,
    pupsIds,
    pupsStagedForDeposit,
    pupsStagedForWithdrawal,
    pupsUnstaked,
    pupsWithdrawalInProgress,
  };

  const carouselProps = {
    handleBackButtonClick,
    handleForwardButtonClick,
    page,
    setPage,
    totalCount,
  };

  return (
    <div>
      {error && <ErrorModal error={error} setError={setError} />}
      {!wontApproveAll && showPromptAvatarModal && (
        <PromptAvatarModal closeModal={closeModal} />
      )}
      {!wontApproveAll && showSettingsModal && (
        <SettingsModal
          closeModal={closeModal}
          kidsApprovedForAll={kidsApprovedForAll}
          pupsApprovedForAll={pupsApprovedForAll}
          setWillApproveKidsForAll={setWillApproveKidsForAll}
          setWillApprovePupsForAll={setWillApprovePupsForAll}
          setWillUnapproveKidsForAll={setWillUnapproveKidsForAll}
          setWillUnapprovePupsForAll={setWillUnapprovePupsForAll}
        />
      )}
      {!wontApproveAll && showApproveAllModal && (
        <ApproveAllModal
          closeModal={closeModal}
          kidsApprovedForAll={kidsApprovedForAll}
          pupsApprovedForAll={pupsApprovedForAll}
          setWillApproveKidsForAll={setWillApproveKidsForAll}
          setWillApprovePupsForAll={setWillApprovePupsForAll}
          persistHideApproveAllModal={persistHideApproveAllModal}
          setShowPromptAvatarModal={setShowPromptAvatarModal}
          setWontApproveAll={setWontApproveAll}
          willStakeAll={willStakeAll}
          setWillStakeAll={setWillStakeAll}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          kidsStagedForDeposit={kidsStagedForDeposit}
          pupsStagedForDeposit={pupsStagedForDeposit}
          kidsStagedForWithdrawal={kidsStagedForWithdrawal}
          pupsStagedForWithdrawal={pupsStagedForWithdrawal}
        />
      )}
      {showClaimModal && (
        <ClaimModal
          closeModal={closeModal}
          setClaimInProgress={setClaimInProgress}
          claimRewards={claimRewards}
          rewards={rewards}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          closeModal={closeModal}
          transactionHash={transactionHash}
          moreTransactions={moreTransactions}
          setTransactionHash={setTransactionHash}
          setMoreTransactions={setMoreTransactions}
        />
      )}
      <div>
        <TopBar account={account} />
        <TopBarMobile />
        <OuterContainer>
          <Container>
            <Heading
              claimInProgress={claimInProgress}
              handleClear={handleClear}
              handleStakeAllClick={handleStakeAllClick}
              handleSumbitButtonClick={handleSumbitButtonClick}
              handleUnstakeAllClick={handleUnstakeAllClick}
              kidsDepositInProgress={kidsDepositInProgress}
              kidsDeposits={kidsDeposits}
              kidsStagedForDeposit={kidsStagedForDeposit}
              kidsStagedForWithdrawal={kidsStagedForWithdrawal}
              kidsUnstaked={kidsUnstaked}
              kidsWithdrawalInProgress={kidsWithdrawalInProgress}
              pupsDepositInProgress={pupsDepositInProgress}
              pupsDeposits={pupsDeposits}
              pupsStagedForDeposit={pupsStagedForDeposit}
              pupsStagedForWithdrawal={pupsStagedForWithdrawal}
              pupsUnstaked={pupsUnstaked}
              pupsWithdrawalInProgress={pupsWithdrawalInProgress}
              willApproveKidsForAll={willApproveKidsForAll}
              willApprovePupsForAll={willApprovePupsForAll}
              willUnapproveKidsForAll={willUnapproveKidsForAll}
              willUnapprovePupsForAll={willUnapprovePupsForAll}
            />
            <UserPanel
              account={account}
              gumBalance={gumBalance}
              handleAvatarClick={handleAvatarClick}
              handleClaimClick={handleClaimClick}
              rewards={rewards}
            />
            <SubHeader>My Bubblegum Campers:</SubHeader>
            {kidsIds.length > 0 && pupsIds.length > 0 && (
              <SettingsBar
                collection={collection}
                filter={filter}
                kidsDeposits={kidsDeposits}
                kidsUnstaked={kidsUnstaked}
                pupsDeposits={pupsDeposits}
                pupsUnstaked={pupsUnstaked}
                setCollection={setCollection}
                setFilter={setFilter}
                setViewType={setViewType}
                toggleCollection={toggleCollection}
                viewType={viewType}
              />
            )}
            <CenteredContainer>
              {[...kidsIds, ...pupsIds].length === 0 && (
                <div
                  style={{
                    paddingTop: "38px",
                    fontSize: "22px",
                    width: "80%",
                    margin: "0 auto",
                  }}
                >
                  <div>
                    Looks like there's nothing here, but we can fix that!
                  </div>
                  <div
                    style={{
                      paddingTop: "12px",
                    }}
                  >
                    Get yourself some{" "}
                    <a
                      href="https://market.bubblegumkids.com/collections/0xa5ae87b40076745895bb7387011ca8de5fde37e0"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ color: "#666" }}
                    >
                      Bubblegum Kids
                    </a>{" "}
                    or{" "}
                    <a
                      href="https://market.bubblegumkids.com/collections/0x86e9c5ad3d4b5519da2d2c19f5c71baa5ef40933"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ color: "#666" }}
                    >
                      Bubblegum Puppies
                    </a>
                  </div>
                </div>
              )}
              {[...kidsIds, ...pupsIds].length > 0 && (
                <>
                  <MobileView {...displaySectionProps} />
                  {viewType === "carousel" ? (
                    <>
                      <CarouselDesktop
                        {...displaySectionProps}
                        {...carouselProps}
                      />
                      <CarouselTablet
                        {...displaySectionProps}
                        {...carouselProps}
                      />
                    </>
                  ) : (
                    <GridView {...displaySectionProps} />
                  )}
                </>
              )}
              <div style={{ height: "24px" }} />
              <Link to="/">
                <LinkButton>Home</LinkButton>
              </Link>
            </CenteredContainer>
          </Container>
        </OuterContainer>
      </div>
    </div>
  );
};

export default Stake;
