import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, ClearButton, SubmitButton } from "./styles";
import { checkForParentElementById } from "./utils";
import { validate as validateEmail } from "email-validator";
import { isAddress, toChecksumAddress } from "web3-utils";
import { COUNTRIES } from "./config";

const getLocalStorageRecipientInfoKey = (account) =>
  `bgk_marketplace_recipient_info.${account}`;

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
    width: 600px;
    left: calc(50% - 300px);
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 975px) {
    justify-content: end;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const CountryDropdown = styled.div`
  position: absolute;
  width: 260px;
  top: 290px;
  text-align: center;
  overflow-y: scroll;
  max-height: 300px;
  border-radius: 4px;
  z-index: 4;

  @media only screen and (min-width: 975px) {
    top: 310px;
  }
`;

const CountryDropdownButton = styled.button`
  align-items: center;
  justify-content: space-between;
  font-family: "Blatant", sans-serif;
  font-size: 18px;
  border: 2px solid #666;
  line-height: 21px;
  margin-bottom: 26px;
  padding: 8px 12px;
  color: #444;
  resize: none;
  border-radius: 4px;
  width: 140px;
  outline: none;
  display: flex;
  background-color: #fff;
  margin-top: 6px;
  cursor: pointer;

  :focus {
    border: 2px solid #ff74b4;
  }
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 26px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;
`;

const Input = styled.input`
  margin-top: 6px;

  ${({ prompt }) =>
    prompt &&
    `
        border-color: red !important;
        ::placeholder { 
            color: red;
        }
    `}
`;

const InputsContainer = styled.div`
  input,
  textarea {
    font-family: "Blatant", sans-serif;
    font-size: 18px;
    border: 2px solid #666;
    line-height: 22px;
    margin-bottom: 26px;
    padding: 8px 12px;
    color: #444;
    resize: none;
    border-radius: 4px;
    width: calc(100% - 40px);
    outline: none;

    &#country-search-input {
      max-width: 112px;
    }

    :focus {
      border: 2px solid #ff74b4;
    }
  }
`;

const Label = styled.label`
  ${({ prompt }) =>
    prompt &&
    `
    color: red;
`}
`;

const Option = styled.div`
  text-align: center;
  color: inherit;
  text-decoration: none;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #eee;
  border-bottom: solid 1px #fff;
  font-family: "Blatant", sans-serif;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  text-align: left;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f2a7bf;
    color: #fff;
  }

  ${({ selected }) =>
    selected &&
    `
  &, &:hover {
    background-color: #e04778;
    color: #fff;
  }
`}
`;

const Checkbox = styled.div`
  cursor: pointer;
  margin-right: 10px;
  background: #fff;
  width: 12px;
  height: 12px;
  max-height: 12px;
  position: relative;
  border-radius: 40px;
  padding: 3px;

  div {
    font-size: 34px;
    margin: -14.5px 0 0;
    color: #e04778;
  }
`;

const CheckboxLabel = styled.label`
  color: #666;
`;

const Caret = styled.div`
  font-size: 14px;
  margin-top: 1px;
  color: #000;

  ${({ countryDropdownOpen }) =>
    !countryDropdownOpen && "transform: rotate(180deg);"}
`;

const ProcessOrderModal = ({
  account,
  addressLineOne,
  addressLineTwo,
  city,
  closeModal,
  country,
  deliveryAccount,
  deliveryAccountRequired,
  email,
  fullName,
  handleProcessOrderSubmit,
  phoneNumber,
  physicalAddressRequired,
  postalCode,
  province,
  setAddressLineOne,
  setAddressLineTwo,
  setCity,
  setCountry,
  setDeliveryAccount,
  setEmail,
  setFullName,
  setPhoneNumber,
  setPostalCode,
  setProvince,
}) => {
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countrySearch, setCountrySearch] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(COUNTRIES);
  const [promptAddressLineOne, setPromptAddressLineOne] = useState(false);
  const [promptCity, setPromptCity] = useState(false);
  const [promptCountry, setPromptCountry] = useState(false);
  const [promptDeliveryAccount, setPromptDeliveryAccount] = useState(false);
  const [promptEmail, setPromptEmail] = useState(false);
  const [promptFullName, setPromptFullName] = useState(false);
  const [promptPostalCode, setPromptPostalCode] = useState(false);
  const [promptProvince, setPromptProvince] = useState(false);
  const [saveRecipientInfo, setSaveRecipientInfo] = useState(true);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const targetIsModalChild = checkForParentElementById(
        event.target,
        "process-order-modal"
      );
      const targetIsCartModalChild = checkForParentElementById(
        event.target,
        "cart-modal"
      );
      const targetIsConfirmModalChild = checkForParentElementById(
        event.target,
        "confirm-order-modal"
      );
      const isCountryDropdownChild = checkForParentElementById(
        event.target,
        "country-dropdown"
      );
      const isCountryDropdownButtonChild = checkForParentElementById(
        event.target,
        "country-dropdown-button"
      );
      const shouldClose = !(
        targetIsModalChild ||
        targetIsCartModalChild ||
        targetIsConfirmModalChild ||
        isCountryDropdownButtonChild ||
        isCountryDropdownChild
      );
      if (shouldClose) {
        closeModal();
      }
      if (!isCountryDropdownButtonChild) {
        setCountryDropdownOpen(false);
      }
    },
    [closeModal, setCountryDropdownOpen]
  );

  const toggleCountryDropdown = useCallback(() => {
    setCountryDropdownOpen(!countryDropdownOpen);
  }, [countryDropdownOpen, setCountryDropdownOpen]);

  const handleSubmit = useCallback(() => {
    let _deliveryAccountValid = true;
    let checksum = "";
    try {
      checksum = toChecksumAddress(deliveryAccount);
    } catch {
      _deliveryAccountValid = false;
    }
    if (!isAddress(checksum)) _deliveryAccountValid = false;
    if (!_deliveryAccountValid && deliveryAccountRequired) {
      setPromptDeliveryAccount(true);
      return;
    }
    if (!addressLineOne.length) {
      setPromptAddressLineOne(true);
      return;
    }
    if (!city.length) {
      setPromptCity(true);
      return;
    }
    if (!country.length) {
      setPromptCountry(true);
      return;
    }
    if (!email.length || !validateEmail(email)) {
      setPromptEmail(true);
      return;
    }
    if (!fullName.length) {
      setPromptFullName(true);
      return;
    }
    if (!postalCode.length) {
      setPromptPostalCode(true);
      return;
    }
    if (!province.length) {
      setPromptProvince(true);
      return;
    }
    handleProcessOrderSubmit();
    if (saveRecipientInfo) {
      const localStorageRecipientInfoKey =
        getLocalStorageRecipientInfoKey(account);
      localStorage.setItem(
        localStorageRecipientInfoKey,
        JSON.stringify({
          addressLineOne,
          addressLineTwo,
          city,
          country,
          deliveryAccount,
          email,
          fullName,
          phoneNumber,
          postalCode,
          province,
        })
      );
    }
    closeModal();
  }, [
    account,
    addressLineOne,
    addressLineTwo,
    city,
    country,
    closeModal,
    deliveryAccount,
    deliveryAccountRequired,
    email,
    fullName,
    handleProcessOrderSubmit,
    phoneNumber,
    postalCode,
    province,
    saveRecipientInfo,
    setPromptDeliveryAccount,
    setPromptEmail,
    setPromptAddressLineOne,
  ]);

  const handleDeliveryAccountChange = useCallback(
    ({ target }) => {
      const { value } = target;
      let _deliveryAccountValid = true;
      let checksum = "";
      try {
        checksum = toChecksumAddress(value);
      } catch {
        _deliveryAccountValid = false;
      }
      if (!isAddress(checksum)) _deliveryAccountValid = false;
      if (!_deliveryAccountValid && deliveryAccountRequired) {
        setPromptDeliveryAccount(true);
      } else {
        setPromptDeliveryAccount(false);
      }
      setDeliveryAccount(value);
    },
    [deliveryAccountRequired, setDeliveryAccount, setPromptDeliveryAccount]
  );

  const handleAddressLineOneChange = useCallback(
    ({ target }) => {
      const { value } = target;
      setPromptAddressLineOne(!value.length);
      setAddressLineOne(value);
    },
    [setAddressLineOne, setPromptAddressLineOne]
  );

  const handleAddressLineTwoChange = useCallback(
    ({ target }) => {
      setAddressLineTwo(target.value);
    },
    [setAddressLineTwo]
  );

  const handleCityChange = useCallback(
    ({ target }) => {
      const { value } = target;
      setPromptCity(!value.length);
      setCity(value);
    },
    [setCity, setPromptCity]
  );

  const handleCountryChange = useCallback(
    (_country) => {
      setPromptCountry(!_country);
      setCountry(_country);
    },
    [setCountry, setPromptCountry]
  );

  const handleEmailChange = useCallback(
    ({ target }) => {
      const { value } = target;
      setPromptEmail(!value.length);
      setEmail(value);
    },
    [setEmail, setPromptEmail]
  );

  const handleFullNameChange = useCallback(
    ({ target }) => {
      const { value } = target;
      setPromptFullName(!value.length);
      setFullName(value);
    },
    [setFullName, setPromptFullName]
  );

  const handlePhoneNumberChange = useCallback(
    ({ target }) => {
      setPhoneNumber(target.value);
    },
    [setPhoneNumber]
  );

  const handlePostalCodeChange = useCallback(
    ({ target }) => {
      const { value } = target;
      setPromptPostalCode(!value.length);
      setPostalCode(value);
    },
    [setPostalCode, setPromptPostalCode]
  );

  const handleProvinceChange = useCallback(
    ({ target }) => {
      const { value } = target;
      setPromptProvince(!value.length);
      setProvince(value);
    },
    [setProvince, setPromptProvince]
  );

  const handleCountryClick = useCallback(
    (_country) => {
      handleCountryChange(_country);
      setCountryDropdownOpen(false);
    },
    [handleCountryChange, setCountryDropdownOpen]
  );

  const handleCountrySearchChange = useCallback(
    ({ target }) => {
      const { value = "" } = target;
      const lowerCaseValue = value.toLowerCase();
      const _filteredCountries = COUNTRIES.filter(([name, code]) => {
        return (
          name.toLowerCase().includes(lowerCaseValue) ||
          code.toLowerCase().includes(lowerCaseValue)
        );
      });
      setFilteredCountries(_filteredCountries);
      setCountrySearch(value);
    },
    [setCountrySearch, setFilteredCountries]
  );

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    const localStorageRecipientInfoKey =
      getLocalStorageRecipientInfoKey(account);
    const _savedRecipientInfo = localStorage.getItem(
      localStorageRecipientInfoKey
    );
    try {
      const savedRecipientInfo = JSON.parse(_savedRecipientInfo);
      const {
        addressLineOne: _addressLineOne,
        addressLineTwo: _addressLineTwo,
        city: _city,
        country: _country,
        deliveryAccount: _deliveryAccount,
        email: _email,
        fullName: _fullName,
        phoneNumber: _phoneNumber,
        postalCode: _postalCode,
        province: _province,
      } = savedRecipientInfo;
      if (_addressLineOne && !addressLineOne) {
        setAddressLineOne(_addressLineOne);
      }
      if (_addressLineTwo && !addressLineTwo) {
        setAddressLineTwo(_addressLineTwo);
      }
      if (_city && !city) {
        setCity(_city);
      }
      if (_country && !country) {
        setCountry(_country);
      }
      if (_deliveryAccount && !deliveryAccount) {
        setDeliveryAccount(_deliveryAccount);
      }
      if (_email && !email) {
        setEmail(_email);
      }
      if (_fullName && !fullName) {
        setFullName(_fullName);
      }
      if (_phoneNumber && !phoneNumber) {
        setPhoneNumber(_phoneNumber);
      }
      if (_postalCode && !postalCode) {
        setPostalCode(_postalCode);
      }
      if (_province && !province) {
        setProvince(_province);
      }
    } catch {}
  }, [account]);

  useEffect(() => {
    if (countryDropdownOpen) {
      setTimeout(() => {
        const countrySearchInput = document.querySelector(
          "#country-search-input"
        );
        if (countrySearchInput) countrySearchInput.focus();
      }, 0);
    }
  }, [countryDropdownOpen]);

  const moveSelectedCountryToTop = useCallback(() => {
    const index = COUNTRIES.findIndex(([_, code]) => code === country);
    if (index < 0) return;
    const _filteredContries = [
      COUNTRIES[index],
      ...COUNTRIES.slice(0, index),
      ...COUNTRIES.slice(index + 1, filteredCountries.length),
    ];
    setFilteredCountries(_filteredContries);
  }, [country, setFilteredCountries]);

  useEffect(() => {
    moveSelectedCountryToTop();
  }, [country]);

  useEffect(() => {
    moveSelectedCountryToTop();
  }, []);

  return (
    <OuterContainer id="process-order-modal">
      <Container>
        <CloseButtonContainer onClick={closeModal}>
          <CloseButton>×</CloseButton>
        </CloseButtonContainer>
        <Heading>Place Order</Heading>
        <InnerContainer>
          <InputsContainer>
            <div>
              <Label htmlFor="fullName" prompt={promptFullName}>
                * Full name
              </Label>
              <Input
                placeholder="Full name (required)"
                value={fullName}
                onChange={handleFullNameChange}
                prompt={promptFullName}
                name="fullName"
              />
            </div>
            <div>
              <Label htmlFor="email" prompt={promptEmail}>
                * Email
              </Label>
              <Input
                placeholder="Email (required)"
                value={email}
                onChange={handleEmailChange}
                type="email"
                prompt={promptEmail}
                name="email"
              />
            </div>
            {deliveryAccountRequired && (
              <div>
                <Label htmlFor="deliveryAccount" prompt={promptDeliveryAccount}>
                  * Ethereum address (delivery)
                </Label>
                <Input
                  placeholder="Ethereum address (required)"
                  value={deliveryAccount}
                  onChange={handleDeliveryAccountChange}
                  prompt={promptDeliveryAccount}
                  name="deliveryAccount"
                />
              </div>
            )}
            {physicalAddressRequired && (
              <div>
                <div>
                  <Label htmlFor="addressLineOne" prompt={promptAddressLineOne}>
                    * Delivery address (line 1)
                  </Label>
                  <Input
                    placeholder="Delivery address (line 1)"
                    value={addressLineOne}
                    onChange={handleAddressLineOneChange}
                    prompt={promptAddressLineOne}
                    name="addressLineOne"
                  />
                </div>
                <div>
                  <Label htmlFor="addressLineTwo">
                    Delivery address (line 2)
                  </Label>
                  <Input
                    placeholder="Delivery address (line 2) (optional)"
                    value={addressLineTwo}
                    onChange={handleAddressLineTwoChange}
                    prompt={false}
                    name="addressLineTwo"
                  />
                </div>
                <div>
                  <Label htmlFor="city" prompt={promptCity}>
                    * City
                  </Label>
                  <Input
                    placeholder="City"
                    value={city}
                    onChange={handleCityChange}
                    prompt={promptCity}
                    name="city"
                  />
                </div>
                <div>
                  <Label htmlFor="province" prompt={promptProvince}>
                    * State/province
                  </Label>
                  <Input
                    placeholder="State/province"
                    value={province}
                    onChange={handleProvinceChange}
                    prompt={promptProvince}
                    name="province"
                  />
                </div>
                <div>
                  <Label htmlFor="postalCode" prompt={promptPostalCode}>
                    * Zip/postal code
                  </Label>
                  <Input
                    placeholder="Zip/postal code"
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                    prompt={promptPostalCode}
                    name="postalCode"
                  />
                </div>
                <div>
                  <Label htmlFor="country" prompt={promptCountry}>
                    * Country
                  </Label>
                  {countryDropdownOpen ? (
                    <div style={{ display: "flex" }}>
                      <Input
                        placeholder={country}
                        value={countrySearch}
                        onChange={handleCountrySearchChange}
                        name="country"
                        id="country-search-input"
                      />
                      <Caret
                        countryDropdownOpen={countryDropdownOpen}
                        style={{ marginLeft: "-28px", marginTop: "13px" }}
                      >
                        ▲
                      </Caret>
                    </div>
                  ) : (
                    <CountryDropdownButton
                      onClick={toggleCountryDropdown}
                      id="country-dropdown-button"
                    >
                      <span>{country}</span>
                      <Caret countryDropdownOpen={countryDropdownOpen}>▲</Caret>
                    </CountryDropdownButton>
                  )}
                  {countryDropdownOpen && (
                    <CountryDropdown id="country-dropdown">
                      {filteredCountries.map(([name, code]) => (
                        <Option
                          selected={country === code}
                          onClick={() => handleCountryClick(code)}
                          key={code}
                        >
                          <div>{name}</div>
                          <div>{code}</div>
                        </Option>
                      ))}
                    </CountryDropdown>
                  )}
                </div>
              </div>
            )}
            <div>
              <Label htmlFor="phoneNumber" prompt={false}>
                Phone number
              </Label>
              <Input
                placeholder="Phone number (optional)"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                type="tel"
                prompt={false}
                name="phoneNumber"
              />
            </div>
          </InputsContainer>
        </InnerContainer>
        <ButtonsContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox onClick={() => setSaveRecipientInfo(!saveRecipientInfo)}>
              <div
                style={{ color: saveRecipientInfo ? "#e04778" : "transparent" }}
              >
                •
              </div>
            </Checkbox>
            <CheckboxLabel>
              Save your info in the browser for next time
            </CheckboxLabel>
          </div>
          <ClearButton onClick={closeModal}>Cancel</ClearButton>
          <SubmitButton
            onClick={handleSubmit}
            disabled={
              promptAddressLineOne ||
              promptCity ||
              promptCountry ||
              promptDeliveryAccount ||
              promptEmail ||
              promptFullName ||
              promptPostalCode ||
              promptProvince
            }
          >
            Submit
          </SubmitButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default ProcessOrderModal;
