import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ClearButton } from "./styles";
import { checkForParentElementById } from "./utils";

const OuterContainer = styled.div`
  position: fixed;
  top: 50px;
  padding: 4px;
  z-index: 3;
  width: calc(100% - 80px);
  left: 33px;
  border: 3px solid #e04778;
  border-radius: 20px;
  background-color: #fff;

  @media only screen and (min-width: 975px) {
    top: 140px;
    width: 600px;
    left: calc(50% - 300px);
  }
`;

const Container = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  width: calc(100% - 40px);

  @media only screen and (min-width: 975px) {
    padding: 40px;
    width: calc(100% - 80px);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 975px) {
    justify-content: end;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 40px;
  border-radius: 50px;
  height: 27px;
  width: 27px;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 216, 229);

    & div {
      color: #e04778;
    }
  }
`;

const CloseButton = styled.div`
  color: #fff;
  font-size: 26px;
  align-self: start;
  margin-top: -2.5px;
  margin-left: 6px;
`;

const Heading = styled.h1`
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 12px;
`;

const InnerContainer = styled.div`
  background-color: rgb(255, 216, 229);
  margin: 0 auto;
  border-radius: 12px;
  border: none;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 26px;
  color: #666;
  max-height: 320px;
  overflow-y: scroll;
  text-align: center;

  div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const MarketplaceSuccessModal = ({ closeModal, orderId }) => {
  const [copyOrderIdSuccess, setCopyOrderIdSuccess] = useState(false);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const targetIsModalChild = checkForParentElementById(
        event.target,
        "marketplace-success-modal"
      );
      if (!targetIsModalChild) {
        closeModal();
      }
    },
    [closeModal]
  );

  const copyOrderId = useCallback(async () => {
    setTimeout(() => {
      setCopyOrderIdSuccess(false);
    }, 3000);
    try {
      await navigator.clipboard.writeText(orderId);
      setCopyOrderIdSuccess(true);
    } catch {}
  }, [orderId, setCopyOrderIdSuccess]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <OuterContainer id="marketplace-success-modal">
      <Container>
        <CloseButtonContainer onClick={closeModal}>
          <CloseButton>×</CloseButton>
        </CloseButtonContainer>
        <Heading>Success!</Heading>
        <InnerContainer>
          <div>
            Your order has processed! Please save this Order ID in case you have
            questions about it later:
          </div>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
            onClick={copyOrderId}
          >
            <b>{orderId}</b>
            {copyOrderIdSuccess ? (
              <img src="/checkmark.svg" alt="copied to clipboard" />
            ) : (
              <img src="/clipboard-icon.svg" alt="clipboard" />
            )}
          </div>
        </InnerContainer>
        <ButtonsContainer>
          <ClearButton onClick={closeModal} noMargin>
            OK
          </ClearButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
};

export default MarketplaceSuccessModal;
