import { KIDS_OFFSET, PUPS_OFFSET } from "./config";
import styled from "styled-components";
import { Button, StagedButton } from "./styles";
import { getItems } from "./utils";

const Container = styled.div`
  display: inherit;
  overflow-y: scroll;
  max-height: 100%;

  @media only screen and (min-width: 975px) {
    display: none;
  }
`;

const ItemContainer = styled.div`
  border-radius: 6px;
  margin: 0 auto;
  max-width: 450px;
  width: 100%;
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
  }
`;

const ItemHeader = styled.h2`
  color: #e04778;
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 12px;
`;

const DaysElapsed = styled.h2`
  color: #e04778;
  font-size: 24px;
  margin-bottom: 16px;
  margin-top: 0;
`;

const OuterItemContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 72px;
`;

const MobileView = ({
  collection,
  daysStaked,
  disabledInteractions,
  filter,
  handleStageForDepositClick,
  handleStageForWithdrawalClick,
  handleUnstageForDepositClick,
  handleUnstageForWithdrawalClick,
  imgSrcs,
  kidsContractAddress,
  kidsDepositInProgress,
  kidsDeposits,
  kidsIds,
  kidsStagedForDeposit,
  kidsStagedForWithdrawal,
  kidsUnstaked,
  kidsWithdrawalInProgress,
  pupsContractAddress,
  pupsDeposits,
  pupsDepositInProgress,
  pupsIds,
  pupsStagedForDeposit,
  pupsStagedForWithdrawal,
  pupsUnstaked,
  pupsWithdrawalInProgress,
}) => {
  const items = getItems({
    filter,
    collection,
    kidsDeposits,
    kidsIds,
    kidsUnstaked,
    pupsDeposits,
    pupsIds,
    pupsUnstaked,
  });

  return (
    <Container>
      {items.map(({ id, collection }) => {
        const offset = collection === "bgk" ? KIDS_OFFSET : PUPS_OFFSET;
        const offsetId = (id + offset) % 10_000;
        const header = `${
          collection === "bgk" ? "Kid " : "Puppy "
        }#${offsetId}`;
        const collectionAddress =
          collection === "bgk" ? kidsContractAddress : pupsContractAddress;
        const imgSrc = imgSrcs?.[collection]?.[offsetId] || undefined;
        const isStaked =
          collection === "bgk"
            ? kidsDeposits.includes(id)
            : pupsDeposits.includes(id);
        const isStagedForDeposit =
          collection === "bgk"
            ? kidsStagedForDeposit.includes(id)
            : pupsStagedForDeposit.includes(id);
        const isStagedForWithdrawal =
          collection === "bgk"
            ? kidsStagedForWithdrawal.includes(id)
            : pupsStagedForWithdrawal.includes(id);
        const isDepositInProgress =
          collection === "bgk"
            ? kidsDepositInProgress.includes(id)
            : pupsDepositInProgress.includes(id);
        const isWithdrawalInProgress =
          collection === "bgk"
            ? kidsWithdrawalInProgress.includes(id)
            : pupsWithdrawalInProgress.includes(id);
        const stakedFor = daysStaked[collection]?.[id];
        return (
          <OuterItemContainer key={`${id}-${collection}`}>
            <ItemContainer>
              <ImageContainer>
                {imgSrc && <img alt="nft" src={imgSrc} />}
              </ImageContainer>
              <ItemHeader>{header}</ItemHeader>
              {stakedFor > -1 && (
                <DaysElapsed>At camp for {stakedFor} days</DaysElapsed>
              )}
              {isStaked && !isStagedForWithdrawal ? (
                <Button
                  title={disabledInteractions ? "Transaction in progress!" : ""}
                  disabled={disabledInteractions}
                  onClick={
                    disabledInteractions
                      ? () => {}
                      : () => {
                          handleStageForWithdrawalClick({
                            id,
                            collectionAddress,
                          });
                        }
                  }
                >
                  Bring Home
                </Button>
              ) : isStaked &&
                isStagedForWithdrawal &&
                !isWithdrawalInProgress &&
                !isWithdrawalInProgress ? (
                <StagedButton
                  title={disabledInteractions ? "Transaction in progress!" : ""}
                  disabled={disabledInteractions}
                  onClick={
                    disabledInteractions
                      ? () => {}
                      : () => {
                          handleUnstageForWithdrawalClick({
                            id,
                            collectionAddress,
                          });
                        }
                  }
                >
                  Ready for Home
                </StagedButton>
              ) : !isStaked && !isStagedForDeposit && !isDepositInProgress ? (
                <Button
                  title={disabledInteractions ? "Transaction in progress!" : ""}
                  disabled={disabledInteractions}
                  onClick={
                    disabledInteractions
                      ? () => {}
                      : () => {
                          handleStageForDepositClick({
                            id,
                            collectionAddress,
                          });
                        }
                  }
                >
                  Send to Camp
                </Button>
              ) : !isStaked && isStagedForDeposit && !isDepositInProgress ? (
                <StagedButton
                  title={disabledInteractions ? "Transaction in progress!" : ""}
                  disabled={disabledInteractions}
                  onClick={
                    disabledInteractions
                      ? () => {}
                      : () => {
                          handleUnstageForDepositClick({
                            id,
                            collectionAddress,
                          });
                        }
                  }
                >
                  Ready for Camp
                </StagedButton>
              ) : isDepositInProgress ? (
                <Button
                  title="Transaction in progress!"
                  onClick={() => {}}
                  disabled
                >
                  Headed to Camp
                </Button>
              ) : isWithdrawalInProgress ? (
                <Button
                  title="Transaction in progress!"
                  onClick={() => {}}
                  disabled
                >
                  Headed Home
                </Button>
              ) : null}
            </ItemContainer>
          </OuterItemContainer>
        );
      })}
    </Container>
  );
};

export default MobileView;
