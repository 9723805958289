const isGoerli = process.env.REACT_APP_ETHEREUM_ENVIRONMENT === "goerli";

const MARKETPLACE_TYPE_CONFIG = {
  intangibleItem: "Digital item",
  merch: "Merch",
  offChainRaffle: "Raffle (offchain)",
};

const KIDS_OFFSET = 2458;
const PUPS_OFFSET = 7837;

const BLOCKS_PER_DAY = 7200;

const GUM_COST_PER_MINT = 1;

const GOERLI_KIDS_CONTRACT_ADDRESS =
  "0x66B04973b83ea796960D6f8ea22856714e01765f";
const GOERLI_PUPS_CONTRACT_ADDRESS =
  "0x053A3213E75b78c0b80b2f88e243cf519e834c02";
const GOERLI_STAKING_CONTRACT_ADDRESS =
  "0xe5f1433b6eCc6bE74E413b54f4c1eA2671b1cA0F";
const GOERLI_GUM_CONTRACT_ADDRESS =
  "0x5f73faD9C2E732DbE049fc43aAd15b3fA8052827";
const GOERLI_VX_KIDS_CONTRACT_ADDRESS =
  "0x99d20A905da47ce219C2bA3fbb6908BA591ac51E";

const KIDS_CONTRACT_ADDRESS = "0xa5ae87B40076745895BB7387011ca8DE5fde37E0";
const PUPS_CONTRACT_ADDRESS = "0x86e9C5ad3D4b5519DA2D2C19F5c71bAa5Ef40933";
const STAKING_CONTRACT_ADDRESS = "0xf48415039913DBdF17e337e681de922A9cb04010";
const GUM_CONTRACT_ADDRESS = "0xeb856faBa11a7590Cb347816Db8F8C08D30FB0fA";
const VX_KIDS_CONTRACT_ADDRESS = "";

const GUM_SINK_ADDRESS = "0x000000000000000000000000000000000000dEaD";

const CONTRACT_ADDRESSES = {
  kidsContractAddress: isGoerli
    ? GOERLI_KIDS_CONTRACT_ADDRESS
    : KIDS_CONTRACT_ADDRESS,
  pupsContractAddress: isGoerli
    ? GOERLI_PUPS_CONTRACT_ADDRESS
    : PUPS_CONTRACT_ADDRESS,
  stakingContractAddress: isGoerli
    ? GOERLI_STAKING_CONTRACT_ADDRESS
    : STAKING_CONTRACT_ADDRESS,
  gumContractAddress: isGoerli
    ? GOERLI_GUM_CONTRACT_ADDRESS
    : GUM_CONTRACT_ADDRESS,
  vxKidsContractAddress: isGoerli
    ? GOERLI_VX_KIDS_CONTRACT_ADDRESS
    : VX_KIDS_CONTRACT_ADDRESS,
  gumSinkAddress: GUM_SINK_ADDRESS,
};

const API_URL = process.env.REACT_APP_API_URL || "https://bgkapi.link";
const INFURA_ID = "86e5e1141d3d4899ae86c76419995b59";

const DISCORD_URL = "https://discord.gg/duD3bn8X7v";

const COUNTRIES = [
  ["Afghanistan", "AF"],
  ["Åland Islands", "AX"],
  ["Albania", "AL"],
  ["Algeria", "DZ"],
  ["American Samoa", "AS"],
  ["Andorra", "AD"],
  ["Angola", "AO"],
  ["Anguilla", "AI"],
  ["Antarctica", "AQ"],
  ["Antigua and Barbuda", "AG"],
  ["Argentina", "AR"],
  ["Armenia", "AM"],
  ["Aruba", "AW"],
  ["Australia", "AU"],
  ["Austria", "AT"],
  ["Azerbaijan", "AZ"],
  ["The Bahamas", "BS"],
  ["Bahrain", "BH"],
  ["Bangladesh", "BD"],
  ["Barbados", "BB"],
  ["Belarus", "BY"],
  ["Belgium", "BE"],
  ["Belize", "BZ"],
  ["Benin", "BJ"],
  ["Bermuda", "BM"],
  ["Bhutan", "BT"],
  ["Bolivia", "BO"],
  ["Bonaire, Sint Eustatius and Saba", "BQ"],
  ["Bosnia and Herzegovina", "BA"],
  ["Botswana", "BW"],
  ["Bouvet Island", "BV"],
  ["Brazil", "BR"],
  ["British Indian Ocean Territory", "IO"],
  ["Brunei", "BN"],
  ["Bulgaria", "BG"],
  ["Burkina Faso", "BF"],
  ["Burundi", "BI"],
  ["Cape Verde", "CV"],
  ["Cambodia", "KH"],
  ["Cameroon", "CM"],
  ["Canada", "CA"],
  ["Cayman Islands", "KY"],
  ["Central African Republic", "CF"],
  ["Chad", "TD"],
  ["Chile", "CL"],
  ["China", "CN"],
  ["Christmas Island", "CX"],
  ["Cocos (Keeling) Islands", "CC"],
  ["Colombia", "CO"],
  ["Comoros", "KM"],
  ["Democratic Republic of the Congo", "CD"],
  ["Republic of the Congo", "CG"],
  ["Cook Islands", "CK"],
  ["Costa Rica", "CR"],
  ["Ivory Coast", "CI"],
  ["Croatia", "HR"],
  ["Cuba", "CU"],
  ["Curaçao", "CW"],
  ["Cyprus", "CY"],
  ["Czech Republic", "CZ"],
  ["Denmark", "DK"],
  ["Djibouti", "DJ"],
  ["Dominica", "DM"],
  ["Dominican Republic", "DO"],
  ["Ecuador", "EC"],
  ["Egypt", "EG"],
  ["El Salvador", "SV"],
  ["Equatorial Guinea", "GQ"],
  ["Eritrea", "ER"],
  ["Estonia", "EE"],
  ["Eswatini", "SZ"],
  ["Ethiopia", "ET"],
  ["Falkland Islands", "FK"],
  ["Faroe Islands", "FO"],
  ["Fiji", "FJ"],
  ["Finland", "FI"],
  ["France", "FR"],
  ["French Guiana", "GF"],
  ["French Polynesia", "PF"],
  ["French Southern and Antarctic Lands", "TF"],
  ["Gabon", "GA"],
  ["The Gambia", "GM"],
  ["Georgia", "GE"],
  ["Germany", "DE"],
  ["Ghana", "GH"],
  ["Gibraltar", "GI"],
  ["Greece", "GR"],
  ["Greenland", "GL"],
  ["Grenada", "GD"],
  ["Guadeloupe", "GP"],
  ["Guam", "GU"],
  ["Guatemala", "GT"],
  ["Bailiwick of Guernsey", "GG"],
  ["Guinea", "GN"],
  ["Guinea-Bissau", "GW"],
  ["Guyana", "GY"],
  ["Haiti", "HT"],
  ["Heard Island and McDonald Islands", "HM"],
  ["Holy See", "VA"],
  ["Honduras", "HN"],
  ["Hong Kong", "HK"],
  ["Hungary", "HU"],
  ["Iceland", "IS"],
  ["India", "IN"],
  ["Indonesia", "ID"],
  ["Iran", "IR"],
  ["Iraq", "IQ"],
  ["Republic of Ireland", "IE"],
  ["Isle of Man", "IM"],
  ["Israel", "IL"],
  ["Italy", "IT"],
  ["Jamaica", "JM"],
  ["Japan", "JP"],
  ["Jersey", "JE"],
  ["Jordan", "JO"],
  ["Kazakhstan", "KZ"],
  ["Kenya", "KE"],
  ["Kiribati", "KI"],
  ["North Korea", "KP"],
  ["South Korea", "KR"],
  ["Kuwait", "KW"],
  ["Kyrgyzstan", "KG"],
  ["Laos", "LA"],
  ["Latvia", "LV"],
  ["Lebanon", "LB"],
  ["Lesotho", "LS"],
  ["Liberia", "LR"],
  ["Libya", "LY"],
  ["Liechtenstein", "LI"],
  ["Lithuania", "LT"],
  ["Luxembourg", "LU"],
  ["Macau", "MO"],
  ["North Macedonia", "MK"],
  ["Madagascar", "MG"],
  ["Malawi", "MW"],
  ["Malaysia", "MY"],
  ["Maldives", "MV"],
  ["Mali", "ML"],
  ["Malta", "MT"],
  ["Marshall Islands", "MH"],
  ["Martinique", "MQ"],
  ["Mauritania", "MR"],
  ["Mauritius", "MU"],
  ["Mayotte", "YT"],
  ["Mexico", "MX"],
  ["Federated States of Micronesia", "FM"],
  ["Moldova", "MD"],
  ["Monaco", "MC"],
  ["Mongolia", "MN"],
  ["Montenegro", "ME"],
  ["Montserrat", "MS"],
  ["Morocco", "MA"],
  ["Mozambique", "MZ"],
  ["Myanmar", "MM"],
  ["Namibia", "NA"],
  ["Nauru", "NR"],
  ["Nepal", "NP"],
  ["Netherlands", "NL"],
  ["New Caledonia", "NC"],
  ["New Zealand", "NZ"],
  ["Nicaragua", "NI"],
  ["Niger", "NE"],
  ["Nigeria", "NG"],
  ["Niue", "NU"],
  ["Norfolk Island", "NF"],
  ["Northern Mariana Islands", "MP"],
  ["Norway", "NO"],
  ["Oman", "OM"],
  ["Pakistan", "PK"],
  ["Palau", "PW"],
  ["State of Palestine", "PS"],
  ["Panama", "PA"],
  ["Papua New Guinea", "PG"],
  ["Paraguay", "PY"],
  ["Peru", "PE"],
  ["Philippines", "PH"],
  ["Pitcairn Islands", "PN"],
  ["Poland", "PL"],
  ["Portugal", "PT"],
  ["Puerto Rico", "PR"],
  ["Qatar", "QA"],
  ["Réunion", "RE"],
  ["Romania", "RO"],
  ["Russia", "RU"],
  ["Rwanda", "RW"],
  ["Saint Barthélemy", "BL"],
  ["Tristan da Cunha", "SH"],
  ["Saint Kitts and Nevis", "KN"],
  ["Saint Lucia", "LC"],
  ["Collectivity of Saint Martin", "MF"],
  ["Saint Pierre and Miquelon", "PM"],
  ["Saint Vincent and the Grenadines", "VC"],
  ["Samoa", "WS"],
  ["San Marino", "SM"],
  ["São Tomé and Príncipe", "ST"],
  ["Saudi Arabia", "SA"],
  ["Senegal", "SN"],
  ["Serbia", "RS"],
  ["Seychelles", "SC"],
  ["Sierra Leone", "SL"],
  ["Singapore", "SG"],
  ["Sint Maarten", "SX"],
  ["Slovakia", "SK"],
  ["Slovenia", "SI"],
  ["Solomon Islands", "SB"],
  ["Somalia", "SO"],
  ["South Africa", "ZA"],
  ["South Georgia and the South Sandwich Islands", "GS"],
  ["South Sudan", "SS"],
  ["Spain", "ES"],
  ["Sri Lanka", "LK"],
  ["Sudan", "SD"],
  ["Suriname", "SR"],
  ["Jan Mayen", "SJ"],
  ["Sweden", "SE"],
  ["Switzerland", "CH"],
  ["Syria", "SY"],
  ["Taiwan", "TW"],
  ["Tajikistan", "TJ"],
  ["Tanzania", "TZ"],
  ["Thailand", "TH"],
  ["East Timor", "TL"],
  ["Togo", "TG"],
  ["Tokelau", "TK"],
  ["Tonga", "TO"],
  ["Trinidad and Tobago", "TT"],
  ["Tunisia", "TN"],
  ["Turkey", "TR"],
  ["Turkmenistan", "TM"],
  ["Turks and Caicos Islands", "TC"],
  ["Tuvalu", "TV"],
  ["Uganda", "UG"],
  ["Ukraine", "UA"],
  ["United Arab Emirates", "AE"],
  ["United Kingdom", "GB"],
  ["United States of America", "US"],
  ["United States Minor Outlying Islands", "UM"],
  ["Uruguay", "UY"],
  ["Uzbekistan", "UZ"],
  ["Vanuatu", "VU"],
  ["Venezuela", "VE"],
  ["Vietnam", "VN"],
  ["British Virgin Islands", "VG"],
  ["United States Virgin Islands", "VI"],
  ["Wallis and Futuna", "WF"],
  ["Western Sahara", "EH"],
  ["Yemen", "YE"],
  ["Zambia", "ZM"],
  ["Zimbabwe", "ZW"],
];

export {
  API_URL,
  BLOCKS_PER_DAY,
  CONTRACT_ADDRESSES,
  COUNTRIES,
  DISCORD_URL,
  GUM_COST_PER_MINT,
  INFURA_ID,
  KIDS_OFFSET,
  MARKETPLACE_TYPE_CONFIG,
  PUPS_OFFSET,
};
