import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 4px;
`;

const Arrow = styled.div`
  background-color: #f2a7bf;
  border: ${({ large }) => (large ? "2px" : "1px")} solid #e04778;
  width: ${({ large }) => (large ? "24px" : "18px")};
  height: ${({ large }) => (large ? "24px" : "18px")};
  transform: rotateY(0deg) rotate(45deg);
`;

const Tag = styled.div`
  background-color: #f2a7bf;
  border-top: ${({ large }) => (large ? "2px" : "1px")} solid #e04778;
  border-right: ${({ large }) => (large ? "2px" : "1px")} solid #e04778;
  border-bottom: ${({ large }) => (large ? "2px" : "1px")} solid #e04778;
  color: #fff;
  height: ${({ large }) => (large ? "36px" : "26px")};
  padding: 0 8px;
  margin-left: ${({ large }) => (large ? "-14px" : "-10px")};
  z-index: 1;
  display: flex;
  align-items: center;
  text-shadow: ${({ large }) => (large ? "2px 2px" : "1px 1px")} #e04778;
  font-size: ${({ large }) => (large ? "24px" : "16px")};
`;

const Dot = styled.div`
  border-radius: 100%;
  width: 3px;
  height: 3px;
  background-color: #fff;
  z-index: 2;
  position: absolute;
  left: 5px;
  top: ${({ large }) => (large ? "16px" : "11px")};
  border: ${({ large }) => (large ? "2px" : "1px")} solid #e04778;
`;

const PriceTag = ({ large = false, price }) => {
  return (
    <Container large={large}>
      <Dot large={large} />
      <Arrow large={large} />
      <Tag large={large}>
        <div>{price} $GUM</div>
      </Tag>
    </Container>
  );
};

export default PriceTag;
