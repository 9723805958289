import styled from "styled-components";
import { SubmitButton } from "./styles";

const Account = styled.a`
  font-size: 24px;
  text-align: left;
  text-shadow: 2px 2px #e04778;
  margin-left: 18px;
  color: #fff;
  text-decoration: none;
`;

const ClaimButton = styled(SubmitButton)``;

const Container = styled.div`
  border: 3px solid #e04778;
  border-radius: 20px;
  margin-bottom: 32px;
  padding: 4px;
  width: calc(100% - 8px);
`;

const HideWord = styled.span`
  display: inline;

  @media only screen and (min-width: 700px) {
    display: none;
  }

  @media only screen and (min-width: 800px) {
    display: inline;
  }
`;

const StatsDivider = styled.div`
  background-color: #fff;
  border-radius: 3px;
  width: 100%;
`;

const StatsContainer = styled.div`
  padding: 0 24px;
  ${({ gridColumn }) => `grid-column: ${gridColumn};`}
`;

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  grid-column: 1/-1;
  margin: 0 auto;

  @media only screen and (min-width: 750px) {
    grid-column: 4;
    margin: 0;
  }
`;

const StatsHeader = styled.h3`
  color: #e04778;
  font-size: 24px;
  margin: 0;
  text-align: left;
`;

const StatsValue = styled.div`
  align-items: center;
  display: flex;
  font-size: 42px;
  text-align: left;
  text-shadow: 2px 2px #e04778;

  img {
    margin-left: 8px;
    margin-top: 3px;
  }
`;

const UserStatsInner = styled.div`
  background-color: #f2a7bf;
  border-radius: 16px;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 24px;
  padding: 18px 24px;
  width: calc(100% - 48px);

  @media only screen and (min-width: 750px) {
    grid-template-columns: 1fr 2px 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const UserPanel = ({
  account,
  gumBalance,
  handleAvatarClick,
  handleClaimClick,
  rewards,
}) => {
  return (
    <Container>
      <UserStatsInner>
        <StatsContainer gridColumn={1}>
          <StatsHeader>
            <HideWord>Your </HideWord>Gum Balance
          </StatsHeader>
          <StatsValue>
            {gumBalance}
            <img src="/gum-icon.png" alt="" height="36" />
          </StatsValue>
        </StatsContainer>
        <StatsDivider />
        <StatsContainer gridColumn={3}>
          <StatsHeader>
            Available to Claim<span style={{ color: "#f2a7bf" }}>-</span>
          </StatsHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gridGap: "18px",
            }}
          >
            <StatsValue>{rewards}</StatsValue>
            {rewards > 0 && (
              <ClaimButton
                id="claim-button"
                onClick={handleClaimClick}
                width="120px"
              >
                Claim
              </ClaimButton>
            )}
          </div>
        </StatsContainer>
        <AccountContainer>
          <img
            src="/user-icon.png"
            alt="user avatar"
            width="80px"
            id="user-avatar"
            onClick={handleAvatarClick}
            style={{ cursor: "pointer" }}
          />
          <Account
            href={`https://etherscan.io/address/${account}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {account.slice(0, 5)}...
            {account.slice(account.length - 4)}
          </Account>
        </AccountContainer>
      </UserStatsInner>
    </Container>
  );
};

export default UserPanel;
