import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Container = styled.div`
  grid-column: 1/-1;
  height: 76px;
  grid-template-columns:
    calc(5% + 4px)
    102px
    1fr
    auto
    calc(5% + 4px);
  background-color: #fff;
  width: 100%;

  display: grid;

  @media only screen and (min-width: 975px) {
    display: none;
  }
`;

const Hamburger = styled.div`
  width: 46px;
  padding-top: 14px;
  cursor: pointer;

  div {
    width: 100%;
    height: 3px;
    background-color: #e04778;
    margin-bottom: 7px;
    border-radius: 3px;
  }
`;

const Logo = styled.img`
  height: 42px;
  margin: 4px 0;
`;

const Menu = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #eee;
  z-index: 4;

  @media only screen and (min-width: 975px) {
    display: none;
  }
`;

const Heading = styled.a`
  display: block;
  font-size: 18px;
  height: 18px;
  padding: 10px 0 14px;
  text-align: center;
  color: inherit;
  text-decoration: none;
  border-bottom: solid 1px #fff;

  &:hover {
    background-color: #f2a7bf;
    color: #fff;

    div {
      border-color: #fff;
    }
  }
`;

const HeadingInternal = styled(Link)`
  display: block;
  font-size: 18px;
  height: 18px;
  padding: 10px 0 14px;
  text-align: center;
  color: inherit;
  text-decoration: none;
  border-bottom: solid 1px #fff;

  &:hover {
    background-color: #f2a7bf;
    color: #fff;

    div {
      border-color: #fff;
    }
  }
`;

const TopBarMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { pathname } = useLocation();
  const atFAQ = pathname === "/faq";
  const atHowTo = pathname === "/how-to";

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen, setMenuOpen]);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const shouldClose = ![
        "hamburger",
        "hamburger-div-one",
        "hamburger-div-two",
        "hamburger-div-three",
        "menu",
        "menu-item",
      ].includes(event.target.id);
      if (shouldClose) {
        setMenuOpen(false);
      }
    },
    [setMenuOpen]
  );

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", handleClick);
    return () => {
      body.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <>
      <Container>
        <div />
        <a href="https://www.bubblegumkids.com/">
          <Logo src="/bgk-logo.png" alt="bubblegum kids logo" />
        </a>
        <div />
        <Hamburger onClick={toggleMenu} id="hamburger">
          <div id="hamburger-div-one" />
          <div id="hamburger-div-two" />
          <div id="hamburger-div-three" />
        </Hamburger>
      </Container>
      {menuOpen && (
        <Menu id="menu">
          <HeadingInternal to="/marketplace">Marketplace</HeadingInternal>
          {atFAQ || atHowTo ? (
            <HeadingInternal to="/stake" id="menu-item">
              Staking
            </HeadingInternal>
          ) : (
            <HeadingInternal to="/faq" id="menu-item">
              FAQ
            </HeadingInternal>
          )}
          <Heading
            href="https://www.bubblegumkids.com/"
            rel="noopener noreferrer"
            target="_blank"
            id="menu-item"
          >
            Collections
          </Heading>
          <Heading
            href="https://www.bubblegumkids.com/roadmap"
            rel="noopener noreferrer"
            target="_blank"
            id="menu-item"
          >
            Roadmap
          </Heading>
          <Heading
            href="https://www.bubblegumkids.com/about"
            rel="noopener noreferrer"
            target="_blank"
            id="menu-item"
          >
            About
          </Heading>
          <Heading
            href="https://twitter.com/bubblegumkids_"
            rel="noopener noreferrer"
            target="_blank"
            id="menu-item"
          >
            Follow Us
          </Heading>
          <Heading
            href="https://www.bubblegumkids.com/contact-us"
            rel="noopener noreferrer"
            target="_blank"
            id="menu-item"
          >
            Contact
          </Heading>
        </Menu>
      )}
    </>
  );
};

export default TopBarMobile;
