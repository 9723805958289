import styled from "styled-components";
import { useCallback, useMemo, useState } from "react";
import ConnectWalletModal from "./ConnectWalletModal";
import SuccessModal from "./SuccessModal";
import TopBar from "./TopBar";
import TopBarMobile from "./TopBarMobile";
import { Button } from "./styles";
import ErrorModal from "./ErrorModal";
import VxUserPanel from "./VxUserPanel";
import { CONTRACT_ADDRESSES, GUM_COST_PER_MINT, KIDS_OFFSET } from "./config";
import { useEffect } from "react";

const { gumContractAddress, vxKidsContractAddress } = CONTRACT_ADDRESSES;

/* TODO:
    - links to contracts
    - add tooltip that warns you can't select -- no more gum
    - follow up on not being able to mint for staked kids
*/

const Container = styled.div`
  grid-template-rows: 1fr auto;
  margin: 0 auto;
  padding-bottom: 52px;
  text-align: left;
  width: 90%;
`;

const Desciption = styled.div`
  color: #666;
  font-size: 18px;
  line-height: 133%;
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 24px;
  text-align: center;
`;

const Header = styled.h1`
  display: flex;
  margin: 0;
`;

const HeaderContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

const IdsToMintCount = styled.div`
  font-size: 22px;
  line-height: 115%;
  margin-right: 18px;

  ${({ disabledInteractions, isZero }) =>
    (isZero || disabledInteractions) && "color: #999;"}
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
  }
`;

const ItemCheckbox = styled.div`
  border: 3px solid
    ${({ disabledInteractions, isAtGumLimit, isSelected }) =>
      disabledInteractions || (isAtGumLimit && !isSelected)
        ? "#999"
        : "#e04778"};
  border-radius: 6px;
  height: 18px;
  margin-right: 8px;
  position: relative;
  width: 18px;
  ${({ disabledInteractions, isSelected }) =>
    isSelected &&
    `
    background-color: ${disabledInteractions ? "#999" : "#e04778"};
    &::after {
      color: #fff;
      content: "✓";
      font-size: 20px;
      left: 0;
      position: absolute;
      top: -2px;
    }
  `}
`;

const ItemContainer = styled.div`
  border-radius: 6px;
  cursor: ${({ showCursor }) => (showCursor ? "pointer" : "not-allowed")};
  margin: 0 auto;
  max-width: 450px;
  text-align: center;
  width: 100%;
`;

const ItemHeader = styled.h2`
  align-items: center;
  color: ${({ disabledInteractions, isAtGumLimit, isMinted, isSelected }) =>
    isMinted || disabledInteractions || (isAtGumLimit && !isSelected)
      ? "#999"
      : "#e04778"};
  display: flex;
  font-size: 24px;
  margin: 12px auto;
  width: fit-content;
`;

const ItemsContainer = styled.div`
  display: grid;
  overflow-y: scroll;
  max-height: 100%;

  @media only screen and (min-width: 975px) {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 30% 30% 30%;
  }

  @media only screen and (min-width: 1250px) {
    display: grid;
    grid-column-gap: 4%;
    grid-template-columns: 22% 22% 22% 22%;
  }
`;

const MintButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & div {
    width: 240px;
    text-align: center;
  }

  @media only screen and (min-width: 1100px) {
    justify-content: flex-end;
  }
`;

const MintedMessage = styled.div`
  color: #fff;
  font-size: 60px;
  opacity: 1;
  position: absolute;
  text-align: center;
  text-shadow: 2px 2px #e04778;
  top: 80px;
  width: 100%;
  z-index: 1;
`;

const NoKidsMessage = styled.div`
  font-size: 22px;
  padding: 24px 0;
  text-align: center;
`;

const OuterItemContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 72px;
`;

const SelectButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media only screen and (min-width: 1100px) {
    justify-content: flex-end;
  }
`;

const StagedChangesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  padding-bottom: 2px;
  row-gap: 12px;

  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

const StagedChangesCount = styled.div`
  font-size: 22px;
  line-height: 115%;
  margin-right: 18px;
`;

const WhereTheMagicHappens = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 28px;
  margin: 0 auto;
  max-width: 500px;

  @media only screen and (min-width: 1100px) {
    flex-direction: row;
    margin: 0;
    max-width: 100%;
  }
`;

const VxKidsMint = ({
  account,
  connectMetaMask,
  connectWalletConnect,
  connectCoinbaseWallet,
  gumBalance,
  gumContract,
  imgSrcs,
  kidsIds,
  vxKidsContract,
}) => {
  const [error, setError] = useState("");
  const [disabledInteractions, setDisabledInteractions] = useState(false);
  const [idsToMint, setIdsToMint] = useState([]);
  const [mintedKidsIds, setMintedKidsIds] = useState([]);
  const [moreTransactions, setMoreTransactions] = useState(false);
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showTransactionInProgress, setShowTransactionInProgress] =
    useState(false);
  const [transactionHash, setTransactionHash] = useState("");

  const beforeMint = useCallback(() => {
    setDisabledInteractions(true);
    setShowTransactionInProgress(true);
  }, [setDisabledInteractions, setShowTransactionInProgress]);

  const closeModal = useCallback(() => {
    setShowConnectWalletModal(false);
    setShowSuccessModal(false);
  }, [setShowConnectWalletModal, setShowSuccessModal]);

  const getIsKidMinted = useCallback(
    async (id) => {
      try {
        const owner = await vxKidsContract.methods.ownerOf(id).call();
        return owner.toLowerCase() === account.toLowerCase();
      } catch (_) {
        return false;
      }
    },
    [account, vxKidsContract]
  );

  const getMintedKids = useCallback(async () => {
    const _mintedKidsIds = [];
    for (const id of kidsIds) {
      const isMinted = await getIsKidMinted(id);
      if (isMinted) _mintedKidsIds.push(id);
    }
    setMintedKidsIds(_mintedKidsIds);
  }, [getIsKidMinted, kidsIds, setMintedKidsIds]);

  const beforeSuccessModal = useCallback(
    ({ _transactionHash, _moreTransactions }) => {
      setTransactionHash(_transactionHash);
      setMoreTransactions(_moreTransactions);
      if (!_moreTransactions) {
        setShowTransactionInProgress(false);
        setIdsToMint(false);
        getMintedKids();
      }
    },
    [
      getMintedKids,
      setIdsToMint,
      setTransactionHash,
      setMoreTransactions,
      setShowTransactionInProgress,
    ]
  );

  useEffect(() => {
    if (kidsIds.length) getMintedKids();
  }, [kidsIds, getMintedKids]);

  const handleConnectButtonClick = useCallback(() => {
    setShowConnectWalletModal(true);
  }, [setShowConnectWalletModal]);

  const isAtGumLimit = useMemo(() => {
    return (idsToMint.length + 1) * GUM_COST_PER_MINT > gumBalance;
  }, [gumBalance, idsToMint.length]);

  const _mint = useCallback(async () => {
    try {
      const res = await vxKidsContract.methods.mint(idsToMint).send({
        from: account,
        to: vxKidsContractAddress,
      });
      console.log({ res });
      const { transactionHash: _transactionHash } = res;
      beforeSuccessModal({ _transactionHash, _moreTransactions: false });
      setShowSuccessModal(true);
      setDisabledInteractions(false);
    } catch (err) {
      console.error(err);
      setError("We're sorry, something went wrong with your mint transaction.");
      setDisabledInteractions(false);
      setShowTransactionInProgress(false);
    }
  }, [
    account,
    beforeSuccessModal,
    idsToMint,
    setDisabledInteractions,
    setError,
    setShowSuccessModal,
    vxKidsContract,
  ]);

  const mint = useCallback(async () => {
    beforeMint();
    const amountToApprove = `${
      GUM_COST_PER_MINT * idsToMint.length
    }000000000000000000`;
    try {
      const _allowance = await gumContract.methods
        .allowance(account, vxKidsContractAddress)
        .call();
      if (Number(_allowance) < Number(amountToApprove)) {
        try {
          const approvalRes = await gumContract.methods
            .approve(vxKidsContractAddress, amountToApprove)
            .send({
              from: account,
              to: gumContractAddress,
            });
          console.log({ approvalRes });
          const { transactionHash: _transactionHash } = approvalRes;
          beforeSuccessModal({ _transactionHash, _moreTransactions: true });
          setShowSuccessModal(true);
          _mint();
        } catch (err) {
          console.error(err);
          setError(
            "We're sorry, something went wrong with your approval transaction."
          );
          setDisabledInteractions(false);
          setShowTransactionInProgress(false);
        }
      } else {
        _mint();
      }
    } catch (err) {
      console.error(err);
      setDisabledInteractions(false);
      setShowTransactionInProgress(false);
    }
  }, [
    _mint,
    account,
    beforeMint,
    beforeSuccessModal,
    gumContract,
    idsToMint,
    setError,
    setShowSuccessModal,
  ]);

  const addToIdToMint = useCallback(
    (idToAdd) => {
      if (isAtGumLimit) return;
      setIdsToMint([...idsToMint, idToAdd]);
    },
    [idsToMint, isAtGumLimit, setIdsToMint]
  );

  const removeFromIdsToMint = useCallback(
    (idToRemove) => {
      const newIdsToMint = idsToMint.filter((id) => {
        return id !== idToRemove;
      });
      setIdsToMint(newIdsToMint);
    },
    [idsToMint, setIdsToMint]
  );

  const unmintedKids = useMemo(() => {
    return kidsIds.filter((id) => !mintedKidsIds.includes(id));
  }, [kidsIds, mintedKidsIds]);

  const selectAll = useCallback(() => {
    const willExceedLimit = kidsIds.length * GUM_COST_PER_MINT > gumBalance;
    if (willExceedLimit) return;
    setIdsToMint(unmintedKids);
  }, [gumBalance, kidsIds, setIdsToMint, unmintedKids]);

  const unselectAll = useCallback(() => {
    setIdsToMint([]);
  }, [setIdsToMint]);

  const handleItemClick = useCallback(
    (id, isSelected, isMinted) => {
      if (isMinted) return;
      if (isSelected) {
        removeFromIdsToMint(id);
      } else {
        addToIdToMint(id);
      }
    },
    [addToIdToMint, removeFromIdsToMint]
  );

  return (
    <>
      {error && <ErrorModal error={error} setError={setError} />}
      {showConnectWalletModal && (
        <ConnectWalletModal
          closeModal={closeModal}
          connectMetaMask={connectMetaMask}
          connectWalletConnect={connectWalletConnect}
          connectCoinbaseWallet={connectCoinbaseWallet}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          closeModal={closeModal}
          transactionHash={transactionHash}
          moreTransactions={moreTransactions}
          setTransactionHash={setTransactionHash}
          setMoreTransactions={setMoreTransactions}
        />
      )}
      <TopBar />
      <TopBarMobile />
      <Container>
        <HeaderContainer>
          <Header>Mint your VX Kids!</Header>
          {showTransactionInProgress && (
            <StagedChangesContainer>
              <StagedChangesCount>
                Transaction in progress! Check your wallet
              </StagedChangesCount>
              <img src="/loading.gif" alt="loading" width="24px" />
            </StagedChangesContainer>
          )}
        </HeaderContainer>
        {!account && (
          <Button
            onClick={handleConnectButtonClick}
            id="connect-button"
            width="300px"
          >
            Connect
          </Button>
        )}
        {account && (
          <>
            <VxUserPanel
              account={account}
              gumBalance={gumBalance}
              idsToMint={idsToMint}
            />
            <Desciption>
              For every Kid you hold, you can mint a VX version that'll align
              your energies with those of the planets, ancestors and river
              sprits! Choose a few Kids—or all of them—and hit Mint to transcend
              the fetters of fear and ignorance!
            </Desciption>
            <WhereTheMagicHappens>
              <SelectButtonsContainer>
                <Button
                  onClick={unselectAll}
                  disabled={disabledInteractions || idsToMint.length === 0}
                >
                  Deselect All
                </Button>
                {kidsIds.length * GUM_COST_PER_MINT <= gumBalance && (
                  <Button
                    onClick={selectAll}
                    disabled={
                      disabledInteractions ||
                      idsToMint.length === unmintedKids.length
                    }
                  >
                    Select All
                  </Button>
                )}
              </SelectButtonsContainer>
              <MintButtonContainer>
                {vxKidsContract && (
                  <IdsToMintCount
                    isZero={idsToMint.length === 0}
                    disabledInteractions={disabledInteractions}
                  >
                    {idsToMint.length}{" "}
                    {idsToMint.length === 1 ? "Kid " : "Kids "}selected
                  </IdsToMintCount>
                )}
                {vxKidsContract && (
                  <Button
                    onClick={mint}
                    disabled={disabledInteractions || idsToMint.length === 0}
                  >
                    Mint
                  </Button>
                )}
              </MintButtonContainer>
            </WhereTheMagicHappens>
          </>
        )}
        {account && kidsIds.length === 0 ? (
          <NoKidsMessage>
            Looks like you don't have any Kids, but we can{" "}
            <a
              href="https://market.bubblegumkids.com/collections/0xa5ae87b40076745895bb7387011ca8de5fde37e0"
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: "#666" }}
            >
              fix that
            </a>{" "}
            :)
          </NoKidsMessage>
        ) : (
          <ItemsContainer>
            {kidsIds.map((id) => {
              const offsetId = (id + KIDS_OFFSET) % 10_000;
              const header = `Kid #${offsetId}`;
              const imgSrc = imgSrcs?.bgk?.[offsetId] || undefined;
              // TODO: type error here after minting
              const isSelected = idsToMint.includes(id);
              const isMinted = mintedKidsIds.includes(id);
              return (
                <OuterItemContainer key={id}>
                  <ItemContainer
                    onClick={() => handleItemClick(id, isSelected, isMinted)}
                    showCursor={
                      !isMinted &&
                      !disabledInteractions &&
                      (isSelected || !isAtGumLimit)
                    }
                  >
                    <div style={{ position: "relative" }}>
                      <ImageContainer>
                        {imgSrc && (
                          <img
                            alt="nft"
                            src={imgSrc}
                            style={{
                              opacity: isSelected ? 1 : isMinted ? 0.3 : 0.6,
                            }}
                          />
                        )}
                      </ImageContainer>
                      {isMinted && <MintedMessage>MINTED</MintedMessage>}
                    </div>
                    <ItemHeader
                      disabledInteractions={disabledInteractions}
                      isSelected={isSelected}
                      isAtGumLimit={isAtGumLimit}
                      isMinted={isMinted}
                    >
                      {!isMinted && (
                        <ItemCheckbox
                          disabledInteractions={disabledInteractions}
                          isAtGumLimit={isAtGumLimit}
                          isSelected={isSelected}
                        />
                      )}
                      {header}
                    </ItemHeader>
                  </ItemContainer>
                </OuterItemContainer>
              );
            })}
          </ItemsContainer>
        )}
      </Container>
    </>
  );
};

export default VxKidsMint;
